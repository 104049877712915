import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Button, notification, Popconfirm, Space } from 'antd';
import {
  getEventById,
  subscribeToEvent,
  updateSlot,
} from '../../methods/events';
import _ from 'lodash';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { PubSub } from 'aws-amplify';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import PageHead from '../../components/PageHead';
import Bugsnag from '@bugsnag/js';
import { updateEventSpeaker } from '../../methods/eventSpeakers';
import strings from '../../utils/strings';

import InternetClicker from '../../components/InternetClicker';

/**
 * Description
 */

const StageScene: React.FC = ({ match }): ReactElement => {
  const history = useHistory();
  const [event, setEvent] = useState({});
  const oldEventSpeakerData = useRef(null);
  const notificationsAlreadyShown = useRef([]);

  const [slot, setSlot] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    let eventSubscription;
    const fetchEvent = async () => {
      const userDetails = await Auth.currentAuthenticatedUser();
      setUserId(
        userDetails.signInUserSession.idToken.payload['cognito:username']
      );

      const targetEvent = await getEventById(match.params.eventId);

      const targetSlot = _.find(targetEvent.slots.items, (o) => {
        return (
          o.eventSpeaker?.speakerId ===
          userDetails.signInUserSession.idToken.payload['cognito:username']
        );
      });

      setEvent(targetEvent);

      const targetSpeaker = _.find(targetEvent.speakers.items, (o) => {
        return (
          o.speakerId ===
          userDetails.signInUserSession.idToken.payload['cognito:username']
        );
      });

      if (targetSpeaker && targetSpeaker.isFinished) {
        Bugsnag.leaveBreadcrumb('Speaker finished event');

        history.replace(`/stage/finished`);
      } else {
        if (
          userDetails.signInUserSession.idToken.payload['cognito:username'] &&
          targetSlot &&
          targetSlot.vMixId
        ) {
          setSlot(targetSlot);
        } else {
          history.replace(`/waiting/${match.params.eventId}`);
        }

        const onChange = (newEvent) => {
          setEvent(newEvent);
          if (
            newEvent &&
            (!newEvent.date ||
              moment().isAfter(moment(newEvent.date).subtract(2, 'hours')))
          ) {
            const targetSlot = _.find(newEvent.slots.items, (o) => {
              return (
                o.eventSpeaker?.speakerId ===
                userDetails.signInUserSession.idToken.payload[
                  'cognito:username'
                ]
              );
            });
            const targetSpeaker = _.find(newEvent.speakers.items, (o) => {
              return (
                o.speakerId ===
                userDetails.signInUserSession.idToken.payload[
                  'cognito:username'
                ]
              );
            });

            if (targetSpeaker && targetSpeaker.isFinished) {
              Bugsnag.leaveBreadcrumb('Speaker finished event');

              if (targetSpeaker.finishedBy === 'speaker') {
                history.replace(`/`);
              } else {
                history.replace(`/stage/finished`);
              }
            } else {
              if (
                userDetails.signInUserSession.idToken.payload[
                  'cognito:username'
                ] &&
                targetSlot &&
                targetSlot.vMixId
              ) {
                setSlot(targetSlot);
              } else {
                Bugsnag.leaveBreadcrumb('Back to waiting room');
                history.replace(`/waiting/${match.params.eventId}`);
              }
            }
          }
        };

        eventSubscription = subscribeToEvent(match.params.eventId, {
          onEventChange: async (newEvent) => {
            Bugsnag.leaveBreadcrumb('subscription:onEventChange', {
              data: JSON.stringify(newEvent, null, 2),
            });
            onChange(newEvent);
          },
          onSlotChanged: async (newSlot) => {
            Bugsnag.leaveBreadcrumb('subscription:onSlotChanged', {
              data: JSON.stringify(newSlot, null, 2),
            });
            const newEvent = await getEventById(match.params.eventId);

            onChange(newEvent);
          },
          onSlotDeleted: async (slotToDelete) => {
            Bugsnag.leaveBreadcrumb('subscription:onSlotDeleted', {
              data: JSON.stringify(slotToDelete, null, 2),
            });
            const newEvent = await getEventById(match.params.eventId);
            onChange(newEvent);
          },
          onEventSpeakerChanged: async (newEventSpeaker) => {
            Bugsnag.leaveBreadcrumb('subscription:onEventSpeakerChanged', {
              data: JSON.stringify(newEventSpeaker, null, 2),
            });
            const newEvent = await getEventById(match.params.eventId);

            onChange(newEvent);
          },
          onEventSpeakerDeleted: async (eventSpeakerToDelete) => {
            Bugsnag.leaveBreadcrumb('subscription:onEventSpeakerDeleted', {
              data: JSON.stringify(eventSpeakerToDelete, null, 2),
            });

            const newEvent = await getEventById(match.params.eventId);
            onChange(newEvent);
          },
          onError: () => {
            console.log('there was a sub error');
          },
        });
      }
    };

    fetchEvent();

    return () => {
      eventSubscription?.unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    if (event && event.speakers) {
      const eventSpeaker = _.find(event.speakers.items, {
        speakerId: userId,
      });

      if (eventSpeaker.role === 'moderator') {
        event.speakers.items.forEach((currentEventSpeaker) => {
          if (
            currentEventSpeaker.isHandRaised &&
            !notificationsAlreadyShown.current.includes(currentEventSpeaker.id)
          ) {
            notificationsAlreadyShown.current = [
              ...notificationsAlreadyShown.current,
              currentEventSpeaker.id,
            ];
            notification.open({
              key: currentEventSpeaker.id,
              duration: 0,
              onClose: () => {
                updateEventSpeaker({
                  id: currentEventSpeaker.id,
                  isHandRaised: false,
                  handLoweredBy: 'team',
                });
              },
              message: (
                <span>
                  <strong>
                    {currentEventSpeaker.speaker.firstName}{' '}
                    {currentEventSpeaker.speaker.lastName}
                  </strong>{' '}
                  raised their hand
                </span>
              ),
              icon: <span>👋</span>,
              closeIcon: <span>Lower</span>,
              placement: 'topLeft',
            });
          } else if (!currentEventSpeaker.isHandRaised) {
            notification.close(currentEventSpeaker.id);
            const newList = notificationsAlreadyShown.current.filter((o) => {
              return o !== currentEventSpeaker.id;
            });

            notificationsAlreadyShown.current = newList;
          }
        });
      } else {
        if (
          !eventSpeaker.isHandRaised &&
          eventSpeaker.handLoweredBy === 'team' &&
          oldEventSpeakerData.current?.isHandRaised
        ) {
          notification.open({
            message: (
              <span>
                <strong>A moderator</strong> has lowered your hand
              </span>
            ),
            placement: 'topLeft',
          });
        }
      }

      oldEventSpeakerData.current = eventSpeaker;
    }
  }, [event]);

  useEffect(() => {
    const action = async () => {
      const credentials = await Auth.currentCredentials();
      const userDetails = await Auth.currentAuthenticatedUser();

      const iot = new AWS.Iot({
        region: 'eu-west-2',
        credentials: Auth.essentialCredentials(credentials),
      });

      const policyName = 'AOTV';
      const target = credentials.identityId;

      const { policies } = await iot.listAttachedPolicies({ target }).promise();

      if (!policies.find((policy) => policy.policyName === policyName)) {
        await iot.attachPolicy({ policyName, target }).promise();
      }

      try {
        Bugsnag.leaveBreadcrumb('pubsub-subscription:refreshWindow');
        PubSub.subscribe(
          `${userDetails.signInUserSession.idToken.payload['cognito:username']}/refreshWindow`
        ).subscribe({
          next: (data) => {
            window.location.reload();
          },
          error: (error) => console.error(error),
          close: () => console.log('Done'),
        });
      } catch (e) {
        console.log('PUBLISH ERROR', e);
        Bugsnag.notify(e);
      }
    };

    action();
  }, []);

  const frames = {
    '1': 'green',
    '3': 'red',
    '678': 'yellow',
    a: 'blue',
    '1a': 'pink',
    '32': 'black',
  };

  return (
    <div
      style={{
        height: '100%',
        // justifyContent: 'center',
        // alignItems: 'center',
        // paddingLeft: '2%',
        // paddingRight: '2%',
        // paddingBottom: 130,
        backgroundColor: 'white',
      }}
    >
      <PageHead title={event.title} />
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          // paddingTop: '10%',
          // paddingBottom: '2.8%',
          // backgroundColor: slot?.vMixId ? frames[slot.vMixId] : 'white',
        }}
      >
        {slot?.eventSpeaker?.internetClickerEnabled &&
          slot?.eventSpeaker?.internetClickerId && (
            <InternetClicker
              code={slot?.eventSpeaker?.internetClickerId}
              showNotes={slot?.eventSpeaker?.showNotes}
              eventSpeakerId={slot?.eventSpeaker?.id}
              name={`${slot?.eventSpeaker?.speaker?.firstName}%20${slot?.eventSpeaker?.speaker?.lastName}`}
            />
          )}
        <iframe
          src={`https://advanced.vmixcall.com/call.htm?Key=${slot?.vMixId}&Name=${slot?.eventSpeaker?.speaker.firstName}%20${slot?.eventSpeaker?.speaker.lastName}`}
          frameBorder="0"
          allow="camera;microphone;fullscreen"
          allowFullScreen
          title="AOTV VMix"
          style={{
            width: '100%',
            // height: '100%',
            flex: 1,
            border: 'none',
            backgroundColor: 'transparent',
          }}
        ></iframe>
        <div
          style={{
            // position: 'absolute',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            padding: '0.5em',
            paddingRight: '1em',
            paddingLeft: '1em',
            height: 90,
            flexShrink: 0,
            flexGrow: 0,
            // borderRadius: 10,
            // alignContent: 'center',
          }}
        >
          <div style={{ width: '20%' }} />
          <Space align="start">
            {slot?.eventSpeaker?.role === 'panelist' &&
              !slot?.eventSpeaker?.isHandRaised && (
                <Button
                  // type='primary'
                  size="large"
                  shape="round"
                  onClick={async () => {
                    await updateEventSpeaker({
                      id: slot?.eventSpeaker.id,
                      isHandRaised: true,
                      handLoweredBy: null,
                    });
                  }}
                >
                  Raise Hand
                </Button>
              )}
            {slot?.eventSpeaker?.role === 'panelist' &&
              slot?.eventSpeaker?.isHandRaised && (
                <>
                  <div
                    style={{
                      position: 'relative',
                      height: '100%',
                      fontSize: 36,
                      // paddingLeft: 36,
                    }}
                  >
                    <span style={{ position: 'absolute', top: -8, left: -36 }}>
                      ✋
                    </span>
                  </div>
                  <Button
                    // type='primary'
                    size="large"
                    shape="round"
                    onClick={async () => {
                      await updateEventSpeaker({
                        id: slot?.eventSpeaker.id,
                        isHandRaised: false,
                        handLoweredBy: 'speaker',
                      });
                    }}
                  >
                    Lower Hand
                  </Button>
                </>
              )}
          </Space>
          <div
            style={{
              width: '20%',
              display: 'flex',
              justifyContent: 'flex-end',
              // alignItems: 'center',
            }}
          >
            <Popconfirm
              trigger="hover"
              title={strings.event.stage.leaveSessionPopUp.title}
              okText={strings.event.stage.leaveSessionPopUp.okText}
              cancelText={strings.event.stage.leaveSessionPopUp.cancelText}
              onConfirm={() => {
                updateSlot({
                  id: slot?.id,
                  eventSpeakerId: null,
                });
                updateEventSpeaker({
                  id: slot?.eventSpeakerId,
                  isFinished: true,
                  finishedBy: 'speaker',
                });
              }}
            >
              <Button size="large" shape="round" type="danger">
                Leave Session
              </Button>
            </Popconfirm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageScene;
