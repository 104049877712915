import React, { ReactElement, useState, useEffect } from 'react';

import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  message,
  Form,
  Input,
  Space,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Button,
  Card,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { API, Auth, graphqlOperation } from 'aws-amplify';
import PageHead from '../../components/PageHead';
import Bugsnag from '@bugsnag/js';
const { Title, Text } = Typography;
/**
 * Description
 */

const ForgotPasswordScene: React.FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { from } = location.state || {
    from: {
      pathname: '/',
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values.email = values.email.toLowerCase().trim();

      await Auth.forgotPassword(values.email);

      setLoading(false);
      message.success('Password Reset Link Sent');
    } catch (e) {
      setLoading(false);

      if (e.code === 'UserNotFoundException') {
        message.error('User not found');
      } else {
        message.error(e.message);
        Bugsnag.notify(e);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='container'>
      <PageHead title={'Forgot Password'} />

      <Row gutter={[32, 32]} justify='center' style={{ marginTop: 60 }}>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          {/* <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}>
            <img src='../img/aotv-logo.png' alt='AOTV Logo' width='200px' />
          </div> */}
          <Breadcrumb style={{ marginBottom: 8 }}>
            <Breadcrumb.Item>
              <ArrowLeftOutlined />
              <Link to={'/auth/login'}>
                <span>Back</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Reset Password</Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Reset Password
                  </Title>
                  <Text>
                    Enter the email used to register so we can send you a
                    password reset link.
                  </Text>
                </div>
                <Form
                  layout='vertical'
                  style={{ width: '75%' }}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    type='email'
                    label='Email'
                    name='email'
                    autoCapitalize='off'
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Please input your email!',
                        transform: (value) => value.toLowerCase().trim(),
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>

                  <Form.Item>
                    <Space size='middle' wrap>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        Request Reset Password Link
                      </Button>

                      {/* <Button
                        type='secondary'
                        htmlType='button'
                        onClick={() => {
                          history.push('/register');
                        }}
                      >
                        Register
                      </Button> */}
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPasswordScene;
