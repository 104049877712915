import React, { ReactElement, useEffect, useState } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
const { Text } = Typography;

interface Props {
  date: Date;
}

const LastSeenAt: React.FC<Props> = ({ date }): ReactElement => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setCounter(counter + 1);
    }, 10000);
  }, [counter]);


  if (!date) {
    return <Text type='secondary'>Never</Text>;
  }
  return <Text type='secondary'>{moment(date).fromNow()}</Text>;
};

export default LastSeenAt;
