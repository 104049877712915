import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { Typography, Button, PageHeader, notification, Space } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import './styles.less';
import { button } from '@aws-amplify/ui';
import HoldingMessageScene from '../HoldingMessageScene';
import { getEventById, subscribeToEvent } from '../../methods/events';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';

import AWS from 'aws-sdk';
import Amplify from '@aws-amplify/core';
import PageHead from '../../components/PageHead';
import Bugsnag from '@bugsnag/js';
import { updateEventSpeaker } from '../../methods/eventSpeakers';
import { visitLexicalEnvironment } from 'typescript';
require('moment-countdown');

const { Title, Text } = Typography;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const WaitingRoomScene: React.FC = ({ match }): ReactElement => {
  const history = useHistory();

  const [event, setEvent] = useState(null);
  const [user, setUser] = useState(null);
  const [countdown, setCountdown] = useState('');
  const countdownTimer = useRef();
  const oldEventSpeakerData = useRef(null);

  useEffect(() => {
    try {
      let eventSubscription = { unsubscribe: () => {} };
      const fetchEvent = async () => {
        try {
          const userDetails = await Auth.currentAuthenticatedUser();

          const targetEvent = await getEventById(match.params.eventId);
          setUser(userDetails);
          setEvent(targetEvent);

          const targetSpeaker = _.find(targetEvent.speakers.items, (o) => {
            return o.speakerId === userDetails.signInUserSession.idToken.payload['cognito:username'];
          });

          oldEventSpeakerData.current = targetSpeaker;

          if (
            targetEvent &&
            (!targetEvent.date ||
              moment().isAfter(moment(targetEvent.date).subtract(2, 'hours')))
          ) {
            const targetSlot = _.find(targetEvent.slots.items, (o) => {
              return o.eventSpeaker?.speakerId === userDetails.signInUserSession.idToken.payload['cognito:username'];
            });

            if (targetSpeaker && targetSpeaker.isFinished) {
              Bugsnag.leaveBreadcrumb('Speaker finished event');

              history.replace(`/stage/finished`);
            } else if (
              userDetails.signInUserSession.idToken.payload['cognito:username'] &&
              targetSlot &&
              targetSlot.vMixId
            ) {
              Bugsnag.leaveBreadcrumb('Speaker moved to stage');

              history.replace(`/stage/${match.params.eventId}`);
            }
          }

          const onChange = (newEvent) => {
            setEvent(newEvent);
            if (
              newEvent &&
              (!newEvent.date ||
                moment().isAfter(moment(newEvent.date).subtract(2, 'hours')))
            ) {
              const targetSlot = _.find(newEvent.slots.items, (o) => {
                return o.eventSpeaker?.speakerId === userDetails.signInUserSession.idToken.payload['cognito:username'];
              });

              const targetSpeaker = _.find(newEvent.speakers.items, (o) => {
                return o.speakerId === userDetails.signInUserSession.idToken.payload['cognito:username'];
              });

              if (targetSpeaker && targetSpeaker.isFinished) {
                Bugsnag.leaveBreadcrumb('Speaker finished event');

                history.replace(`/stage/finished`);
              } else if (
                userDetails.signInUserSession.idToken.payload['cognito:username'] &&
                targetSlot &&
                targetSlot.vMixId
              ) {
                Bugsnag.leaveBreadcrumb('Speaker moved to stage');

                history.replace(`/stage/${match.params.eventId}`);
              }
            }
          };

          eventSubscription = subscribeToEvent(match.params.eventId, {
            onEventChange: async (newEvent) => {
              Bugsnag.leaveBreadcrumb('subscription:onEventChange', {
                data: JSON.stringify(newEvent, null, 2),
              });
              onChange(newEvent);
            },
            onSlotChanged: async (newSlot) => {
              Bugsnag.leaveBreadcrumb('subscription:onSlotChanged', {
                data: JSON.stringify(newSlot, null, 2),
              });
              const newEvent = await getEventById(match.params.eventId);

              onChange(newEvent);
            },
            onSlotDeleted: async (slotToDelete) => {
              Bugsnag.leaveBreadcrumb('subscription:onSlotDeleted', {
                data: JSON.stringify(slotToDelete, null, 2),
              });
              const newEvent = await getEventById(match.params.eventId);
              onChange(newEvent);
            },
            onEventSpeakerChanged: async (newEventSpeaker) => {
              Bugsnag.leaveBreadcrumb('subscription:onEventSpeakerChanged', {
                data: JSON.stringify(newEventSpeaker, null, 2),
              });
              const newEvent = await getEventById(match.params.eventId);

              onChange(newEvent);
            },
            onEventSpeakerDeleted: async (eventSpeakerToDelete) => {
              Bugsnag.leaveBreadcrumb('subscription:onEventSpeakerDeleted', {
                data: JSON.stringify(eventSpeakerToDelete, null, 2),
              });

              const newEvent = await getEventById(match.params.eventId);
              onChange(newEvent);
            },
            onError: () => {
              console.log('there was a sub error');
            },
          });
        } catch (e) {
          console.log('e', e);
          Bugsnag.notify(e);
        }
      };

      fetchEvent();

      return () => {
        eventSubscription.unsubscribe();
      };
    } catch (e) {
      console.log('waiting room error ', e);
      Bugsnag.notify(e);
    }
  }, []);

  useEffect(() => {
    if (event) {
      const eventSpeaker = _.find(event.speakers.items, {
        speakerId: user.signInUserSession.idToken.payload['cognito:username'],
      });
      if (
        !eventSpeaker.isHandRaised &&
        eventSpeaker.handLoweredBy === 'team' &&
        oldEventSpeakerData.current?.isHandRaised
      ) {
        notification.open({
          message: (
            <span>
              <strong>A moderator</strong> has lowered your hand
            </span>
          ),
          placement: 'topLeft',
        });
      }

      oldEventSpeakerData.current = eventSpeaker;
    }
  }, [event]);

  let title = '';
  let message = '';
  let loading = true;
  let showEmbed = false;
  let eventSpeaker = null;

  if (event) {
    title = 'Waiting Room';
    showEmbed = !!event.embedCode;
    message =
      'Please stay on this page. The event Director will let you into the Green Room shortly.';
    loading = true;
    eventSpeaker = _.find(event.speakers.items, { speakerId: user.signInUserSession.idToken.payload['cognito:username'] });
  }
  if (event && event.isFinished) {
    title = 'Event Finished';
    message = 'This event has finished and is no longer available to Speakers.';
    loading = false;
  }

  if (
    event &&
    event.date &&
    moment().isBefore(moment(event.date).subtract(2, 'hours'))
  ) {
    if (!countdown) {
      setCountdown(moment(event.date).countdown().toString());
    }
    clearInterval(countdownTimer.current);
    countdownTimer.current = setInterval(function () {
      setCountdown(moment(event.date).countdown().toString());
    }, 1000);

    // title = countdown;
    title = `You're Early.`;
    message = `The ${event.title} waiting room opens ${moment(
      event.date
    ).calendar(null, {
      sameDay: '[at] LT',
      nextDay: '[Tomorrow] [at] LT',
      nextWeek: 'on DD/MM/YYYY  [at] LT',
      // lastDay: '[Yesterday]',
      lastWeek: 'on DD/MM/YYYY  [at] LT',
      sameElse: 'on DD/MM/YYYY  [at] LT',
    })}`;
    loading = false;
  }

  return (
    <>
      <PageHead title={event?.title || 'Waiting Room'} />
      <PageHeader title='Back to events' onBack={() => history.push('/')} />
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: event?.embedCode ? 'flex-start' : 'center',
          // marginTop: 30,
          marginBottom: 100,
        }}
      >
        {showEmbed && (
          <div
            style={{
              marginBottom: '3%',
              maxWidth: 900,
              width: '100%',
              textAlign: 'center',
            }}
            dangerouslySetInnerHTML={{ __html: event?.embedCode }}
          />
        )}
        <div style={{ textAlign: 'center' }}>
          <HoldingMessageScene
            shouldShowLogo={!loading}
            title={title}
            message={message}
            isLoading={event?.embedCode ? false : loading}
          />
          <div
            style={{
              marginTop: 20,
              height: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {eventSpeaker?.role === 'panelist' && !eventSpeaker?.isHandRaised && (
              <Button
                // type='primary'
                size='large'
                shape='round'
                onClick={() => {
                  updateEventSpeaker({
                    id: eventSpeaker.id,
                    isHandRaised: true,
                    handLoweredBy: null,
                  });
                }}
              >
                Raise Hand
              </Button>
            )}
            {eventSpeaker?.role === 'panelist' && eventSpeaker?.isHandRaised && (
              <Space>
                <div style={{ height: '100%', fontSize: 36 }}>
                  <span>✋</span>
                </div>
                <Button
                  // type='primary'
                  size='large'
                  shape='round'
                  onClick={() => {
                    updateEventSpeaker({
                      id: eventSpeaker.id,
                      isHandRaised: false,
                      handLoweredBy: 'speaker',
                    });
                  }}
                >
                  Lower Hand
                </Button>
              </Space>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingRoomScene;
