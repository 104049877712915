import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Tooltip } from 'antd';

import { DownOutlined } from '@ant-design/icons';

interface Props {
  // id: string;
  onRoleChanged: (roleId: string) => void;
  onClick: () => void
}

const Hand: React.FC<Props> = ({
  // id,
  onRoleChanged = () => {},
  onClick
}): ReactElement => {
  const [waving, setWaving] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setWaving(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!waving) {
      setTimeout(() => {
        setWaving(true);
        setTimeout(() => {
          setWaving(false);
        }, 3000);
      }, 7000);
    }
  }, [waving]);

  return (
    <Tooltip title='Lower Hand'>
      <div>
        <span
          className={`hand ${waving ? 'wave' : ''}`}
          onClick={onClick}
        >
          👋
        </span>
      </div>
    </Tooltip>
  );
};

export default Hand;
