/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:48bd0f1f-f181-43b3-9b0b-87ed04fbdf86",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_R4bJodUW1",
    "aws_user_pools_web_client_id": "7j1rt5re3lhkulblaif1qo7b8m",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 6,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://f5n5gm45avaehb65re5hth6gca.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AOTVRest",
            "endpoint": "https://9f1l5bd8w6.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
