import React, { ReactElement, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Form, Input, message, Card, Row, Button, Col, Typography } from 'antd';

import PageHead from '../../components/PageHead';
import { setNewPassword } from '../../methods/speakers';

import Auth from '@aws-amplify/auth';
import passwordStrengthRule from '../../utils/passwordStrengthRule';
import Bugsnag from '@bugsnag/js';

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const SetTeamPasswordScene: React.FC = ({ user }): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        values.password // the new password
      );

      const userItem = await Auth.signIn({
        username: user.challengeParam.userAttributes.email,
        password: values.password,
      });

      localStorage.setItem(
        'aotv:userToken',
        userItem.signInUserSession.accessToken.jwtToken
      );

      if (
        userItem.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('team')
      ) {
        localStorage.setItem('aotv:isTeamMember', 'true');
      }

      if (
        userItem.signInUserSession.accessToken.payload[
          'cognito:groups'
        ].includes('admins')
      ) {
        localStorage.setItem('aotv:isAdmin', 'true');
      }

      setLoading(false);
      history.push('/');
    } catch (e) {
      setLoading(false);
      message.error(e.message);
      Bugsnag.notify(e);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='container'>
      <PageHead title={'Set Password'} />
      <Row justify='center'>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}>
            <img
              src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
              alt='AOTV Logo'
              width='200px'
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card>
            <div style={{ marginBottom: 30 }}>
              <Title level={3} style={{ marginBottom: 0 }}>
                Create a new password
              </Title>
              <Text type='secondary'>
                Please choose a unique password specifically for AOTV.
              </Text>
            </div>
            <Form
              {...layout}
              form={form}
              name='basic'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label='Password'
                name='password'
                rules={[passwordStrengthRule]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>

              <Form.Item
                label='Confirm Password'
                name='confirmPassword'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={loading}
                  title='Create Account'
                >
                  Set Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SetTeamPasswordScene;
