
import * as types from '../actions/actionTypes';

const initialState = {
  connected: [],
};

const reducer =  (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CONNECTED_SPEAKERS:
      const newConnected = [...state.connected, ...action.payload.connected].filter((id) => {
        return !action.payload.disconected?.includes(id)
      });
      return {
        ...state,
        connected: newConnected,
      };

    default:
      return state;
  }
};


export default reducer
