import API from '@aws-amplify/api';

import * as mutations from '../graphql/mutations';
import {listTeamMembers, getTeamMember} from '../graphql/queries'
import { v4 as uuidv4 } from 'uuid';

export const getTeamMemberById = async (memberId) => {
  const {
    data: { getTeamMember: response },
  } = await API.graphql({
    query: getTeamMember,
    variables: {
      id: memberId,
    },
  });

  return response;
};

export const getTeamMembers = async () => {
  const {
    data: { listTeamMembers: response },
  } = await API.graphql({
    query: listTeamMembers,
  });

  return response;
};

export const addTeamMember = async ({ member }) => {

  const response = await API.post('AOTVRest', `/team`, {
    body: {...member, type:'TeamMember'},
  });

  if(response.err) {
    throw new Error(response.err)
  } else {
    return response.Username;
  }

  // await API.graphql({
  //   query: mutations.createInvite,
  //   variables: {
  //     input: {
  //       id: uuidv4(),
  //       userId: response.Username,
  //       createdAt: new Date().toISOString(),
  //     },
  //   },
  // });


};

export const updateTeamMember = async ({ member }) => {
  const {
    data: { updateTeamMember: response },
  } = await API.graphql({
    query: mutations.updateTeamMember,
    variables: {
      input: member,
    },
  });

  await API.put('AOTVRest', `/team`, {
    body: member,
  });

  

  return response;
};

export const removeTeamMember = async (member) => {
  const {
    data: { deleteTeamMember: response },
  } = await API.graphql({
    query: mutations.deleteTeamMember,
    variables: {
      input: { id: member.id },
    },
  });

  await API.del('AOTVRest', `/team`, {
    body: member,
  });

  return response;
};

export const removeAccount = async () => {
  await API.del('AOTVRest', `/account`);
}