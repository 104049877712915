export const updatedSlotCustomSub = /* GraphQL */ `
  subscription UpdatedSlotCustomSub($eventId: ID!) {
    updatedSlot(eventId: $eventId) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        isHandRaised
        handLoweredBy
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          needsToSetPassword
          createdAt
          updatedAt
        }
        isFinished
        createdAt
        updatedAt
      }
      vMixId
      eventId
      createdAt
      updatedAt
    }
  }
`;

export const deletedSlotCustomSub = /* GraphQL */ `
  subscription DeletedSlotCustomSub($eventId: ID!) {
    deletedSlot(eventId: $eventId) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        isHandRaised
        handLoweredBy
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          needsToSetPassword
          createdAt
          updatedAt
        }
        isFinished
        createdAt
        updatedAt
      }
      vMixId
      eventId
      createdAt
      updatedAt
    }
  }
`;

export const createSlotCustomMut = /* GraphQL */ `
  mutation CreateSlotCustomMut(
    $input: CreateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    createSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        isHandRaised
        handLoweredBy
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          needsToSetPassword
          createdAt
          updatedAt
        }
        isFinished
        createdAt
        updatedAt
      }
      vMixId
      eventId
      createdAt
      updatedAt
    }
  }
`;

export const updateSlotCustomMut = /* GraphQL */ `
  mutation UpdateSlotCustomMut(
    $input: UpdateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    updateSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        isHandRaised
        handLoweredBy
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          needsToSetPassword
          createdAt
          updatedAt
        }
        isFinished
        createdAt
        updatedAt
      }
      vMixId
      eventId
      createdAt
      updatedAt
    }
  }
`;

export const deleteSlotCustomMut = /* GraphQL */ `
  mutation DeleteSlotCustomMut(
    $input: DeleteSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    deleteSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        isHandRaised
        handLoweredBy
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          needsToSetPassword
          createdAt
          updatedAt
        }
        isFinished
        createdAt
        updatedAt
      }
      vMixId
      eventId
      createdAt
      updatedAt
    }
  }
`;

export const updatedEventSpeakerCustomSub = /* GraphQL */ `
  subscription UpdatedEventSpeakerCustomSub($eventId: ID!) {
    updatedEventSpeaker(eventId: $eventId) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      isHandRaised
      handLoweredBy
      eventId
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        needsToSetPassword
        createdAt
        updatedAt
      }
      isFinished
      createdAt
      updatedAt
    }
  }
`;

export const deletedEventSpeakerCustomSub = /* GraphQL */ `
  subscription DeletedEventSpeakerCustomSub($eventId: ID!) {
    deletedEventSpeaker(eventId: $eventId) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      isHandRaised
      handLoweredBy
      eventId
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        needsToSetPassword
        createdAt
        updatedAt
      }
      isFinished
      createdAt
      updatedAt
    }
  }
`;

export const createEventSpeakerCustomMut = /* GraphQL */ `
  mutation CreateEventSpeakerCustomMut(
    $input: CreateEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    createEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      isHandRaised
      handLoweredBy
      eventId
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        needsToSetPassword
        createdAt
        updatedAt
      }
      isFinished
      createdAt
      updatedAt
    }
  }
`;

export const updateEventSpeakerCustomMut = /* GraphQL */ `
  mutation UpdateEventSpeakerCustomMut(
    $input: UpdateEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    updateEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      isHandRaised
      handLoweredBy
      eventId
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        needsToSetPassword
        createdAt
        updatedAt
      }
      isFinished
      createdAt
      updatedAt
    }
  }
`;

export const deleteEventSpeakerCustomMut = /* GraphQL */ `
  mutation DeleteEventSpeakerCustomMut(
    $input: DeleteEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    deleteEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      isHandRaised
      handLoweredBy
      eventId
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        needsToSetPassword
        createdAt
        updatedAt
      }
      isFinished
      createdAt
      updatedAt
    }
  }
`;

export const updatedClientCustomSub = /* GraphQL */ `
  subscription UpdatedClientCustomSub {
    updatedClient {
      id
      owner
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      windowVisible
      location
      eventId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const deletedClientCustomSub = /* GraphQL */ `
  subscription DeletedClientCustomSub {
    deletedClient {
      id
      owner
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      windowVisible
      location
      eventId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const createClientCustomMut = /* GraphQL */ `
  mutation CreateClientCustomMut(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      owner
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      windowVisible
      location
      eventId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateClientCustomMut = /* GraphQL */ `
  mutation UpdateClientCustomMut(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      owner
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      windowVisible
      location
      eventId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const deleteClientCustomMut = /* GraphQL */ `
  mutation DeleteClientCustomMut(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      owner
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      windowVisible
      location
      eventId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;



export const getCustomEvent = /* GraphQL */ `
query GetCustomEvent($id: ID!) {
  getEvent(id: $id) {
    id
    title
    date
    endDate
    client
    embedCode
    isFinished
    slots {
      items {
        id
        eventSpeakerId
        event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                createdAt
                updatedAt
                deletedAt
              }
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
        }
        createdAt
        vMixId
        eventId
      }
      nextToken
    }
    speakers {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              speakerId
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                nextToken
              }
              speakers {
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    createdAt
    updatedAt
    deletedAt
  }
}
`;


export const createEventCustomMut = /* GraphQL */ `
  mutation CreateEventCustomMut(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
    title
    date
    endDate
    client
    embedCode
    isFinished
    slots {
      items {
        id
        eventSpeakerId
        event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                createdAt
                updatedAt
                deletedAt
              }
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
        }
        createdAt
        vMixId
        eventId
      }
      nextToken
    }
    speakers {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              speakerId
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                nextToken
              }
              speakers {
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    createdAt
    updatedAt
    deletedAt

    }

  }
`;
export const updateEventCustomMut = /* GraphQL */ `
  mutation UpdateEventCustomMut(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
    title
    date
    endDate
    client
    embedCode
    isFinished
    slots {
      items {
        id
        eventSpeakerId
        event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                createdAt
                updatedAt
                deletedAt
              }
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
        }
        createdAt
        vMixId
        eventId
      }
      nextToken
    }
    speakers {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              speakerId
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                nextToken
              }
              speakers {
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    createdAt
    updatedAt
    deletedAt
    }
  }
`;

export const deleteEventCustomMut = /* GraphQL */ `
  mutation DeleteEventCustomMut(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
    title
    date
    endDate
    client
    embedCode
    isFinished
    slots {
      items {
        id
        eventSpeakerId
        event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                createdAt
                updatedAt
                deletedAt
              }
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
        }
        createdAt
        vMixId
        eventId
      }
      nextToken
    }
    speakers {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              speakerId
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                nextToken
              }
              speakers {
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
    createdAt
    updatedAt
    deletedAt
    }

  }
  `;



export const fetchEvents = /* GraphQL */ `
  query FetchEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        date
        endDate
        isFinished
        client
        speakers {
          items {
            id
            client {
              items {
                location
                deletedAt
                createdAt
              }
            }
          }
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;

export const fetchSpeakers = /* GraphQL */ `
  query FetchSpeakers(
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        username
        speakerEvents {
          items {
            id
            event {
              id
              title
              isFinished  
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            createdAt
            updatedAt
          }
          
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;