import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as mutations from '../graphql/mutations';
import { getInvite } from '../graphql/queries';


export const inviteSpeakerToEvent = async (speaker, event) => {
  const inviteId = uuidv4();

  const {
    data: { createInvite: response },
  } = await API.graphql({
    query: mutations.createInvite,
    variables: {
      input: {
        id: inviteId,
        userId: speaker.id,
        eventId: event.id,
        createdAt: new Date().toISOString(),
      },
    },
  });

  const date =  moment(event.date).format('dddd, MMMM Do YYYY');
  const time = moment(event.date).format('h:mm a');

    API.post('AOTVRest', `/invite/send`, {
    body: {
      inviteId,
      speaker: {
        email: speaker.email,
        firstName: speaker.firstName,
      },
      event: {
        title: event.title,
        dateString: event.date ? ` on ${date} at ${time}` : ''
      },
    },
  });

  // sendMail({
  //   to: speaker.email,
  //   template: 'invite',
  //   data: {
  //     firstName: speaker.firstName,
  //     eventName: event.title,
  //     link: `https://staging.d29k340wa6mc9s.amplifyapp.com/invite/${inviteId}`,
  //     date: moment(event.date).format('dddd, MMMM Do YYYY'),
  //     time: moment(event.date).format('h:mm a'),
  //   },
  // });
};

export const getInviteById = async (inviteId, type) => {
  const response = await API.get('AOTVRest', `/invite`, {
    queryStringParameters: {
      id: inviteId,
      type,
    },
  });

  // const {
  //   data: { getInvite: response },
  // } = await API.graphql({
  //   query: getInvite,
  //   authMode: session.authenticated ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
  //   variables: {
  //     id: inviteId,
  //   },
  // });

  return response;
};
