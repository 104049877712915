//TODO: Vmix description
const strings = {
  validation: {
    vMix: {
      alreadyExists: {
        title: 'vMixID already exists',
        description:
          'This vMixId already exists in this event. Clicking "OK" will assign this speaker to that slot and remove this one',
      },
      alreadyInUse: {
        title: 'vMixID already in use',
        description: '',
      },
      conflict: {
        sameEventEmptySlot:
          'This vMixId is already being used. This will remove the slot with vMixId %s.',
        sameEventFilledSlot: 'This vMixId is already being used by %s.',

        differentEventEmptySlot:
          'This vMixId is already being used in %s. This will remove the slot with vMixId %s.',
        differentEventFilledSlot:
          'This vMixId is already being used in %s by %s. This will remove the slot with vMixId %s and move the speaker back to the waiting room.',
      },
    },
  },
  event: {
    empty: 'No Speakers or Slots',
    noSlots: 'No Slots',
    endEvent: {
      buttonLabel: 'End Event',
      popUp: {
        title: 'Are you sure?',
        okText: 'Yes',
        cancelText: 'No',
        confirm: {
          title: 'Are you sure?',
          description: 'Speakers will not be able to join this event.',
          okText: 'Yes',
          cancelText: 'No',
        },
      },
    },
    eventFinished: {
      title: 'Event Finished',
      description:
        'This event has finished and is no longer available to Speakers.',
      openEvent: {
        buttonLabel: 'Open Event',
        popUp: {
          title: 'Are you sure?',
          okText: 'Yes',
          cancelText: 'No',
          confirm: {
            title: 'Are you sure?',
            description: 'Speakers will not be able to join this event.',
            okText: 'Yes',
            cancelText: 'No',
          },
        },
      },
    },
    addCallSlot: {
      buttonLabel: 'Add Call Slot',
      popUp: {
        title: 'vMix ID?',
        buttonLabel: 'Add',
      },
    },
    stage: {
      leaveSessionPopUp: {
        title: 'Leave Session?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    rejoin: {
      rejoinPopUp: {
        title: 'Rejoin Session?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    waitingRoom: {
      title: 'Waiting Room',
      empty: 'Waiting Room Empty',
      platformPopUp: {
        title: 'Platform Information',
        empty: 'Not Available',
      },
      allocatePopUp: {
        title: 'vMix Call',
        selectLabel: 'Slot',
        buttonLabel: 'Allocate',
        sectionEmptyTitle: 'Empty Slots',
        sectionInUseTitle: 'In Use Slots',
        addSlotLink: 'Add Slot',
      },
      removePopUp: {
        title: 'Remove Speaker from Waiting Room?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    finishedSpeakers: {
      title: 'Finished Speakers',
      movePopUp: {
        title: 'Move Speaker to Waiting Room?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    emptySlot: {
      selectLabel: 'Speaker',
      buttonLabel: 'Allocate',
      removePopUp: {
        title: 'Remove Call Slot?',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    slot: {
      alreadyAllocated: {
        title: 'Slot already allocated',
        description:
          'Are you sure you want to move speaker to this slot, it will move the current allocated speaker to the waiting room',
        okText: 'Yes',
        cancelText: 'No',
      },
    },
    slotCard: {
      idPopUp: {
        title: 'vMix ID',
        buttonLabel: 'Save',
      },
      moveToWaitingPopUp: {
        option: 'Move Speaker to Waiting Room',
        title: 'Move Speaker to Waiting Room?',
        okText: 'Yes',
        cancelText: 'No',
      },
      removePopUp: {
        option: 'Remove Speaker from Event',
        title: 'Remove Speaker from Event?',
        okText: 'Yes',
        cancelText: 'No',
      },
      internetClickerPopUp: {
        title: 'Internet Clicker',
        buttonLabel: 'Save',
      },
      refreshPopUp: {
        title: 'Refresh Speakers Browser?',
        okText: 'Yes',
        cancelText: 'No',
      },
      platformPopUp: {
        title: 'Platform Information',
        empty: 'Not Available',
      },
      movePopUp: {
        title: 'Move Speaker / vMix ID',
        okText: 'Yes',
        cancelText: 'No',
        buttonLabel: 'Move',
      },
    },
  },
  events: {
    pageTitle: 'Events',
    addEvent: 'Add Event',
    edit: 'Edit',
    remove: 'Remove',
    emptyDate: 'Not Set',
    emptyClient: '-',
    columns: {
      date: 'Date',
      event: 'Event',
      client: 'Client',
      speakers: 'Speakers',
      waiting: 'Waiting',
      stage: 'Stage',
    },
    removeEventConfirm: {
      title: 'Remove Event?',
      okText: 'Yes',
      cancelText: 'No',
      successMessage: 'Event Removed',
    },
  },
  auth: {
    logout: {
      popUp: {
        confirm: {
          title: 'Are you sure?',
          okText: 'Yes',
          cancelText: 'No',
        },
      },
    },
  },
};
export default strings;
