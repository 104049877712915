import API from '@aws-amplify/api';

import Auth from '@aws-amplify/auth';

import platform from 'platform';
import { getClient } from '../graphql/queries';

import shared from '../config/shared';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { createClientCustomMut, deleteClientCustomMut, updateClientCustomMut } from '../graphql/custom';

export const upsertClient = async (location?: String) => {
  const userDetails = await Auth.currentAuthenticatedUser();
  let mutationResponse = null;

  const {
    data: { getClient: response },
  } = await API.graphql({
    query: getClient,
    variables: {
      id: shared.clientId,
    },
  });
  Bugsnag.leaveBreadcrumb('Found existing client', {
    MetaData: {
      clientId: shared.clientId,
      response: JSON.stringify(response, null, 2),
    },
  });
  let eventId = null;
  if (location) {
    const segs = location.split('/');
    if (['waiting', 'stage'].includes(segs[1])) {
      eventId = segs[2];
    }
  }
  let methodString = 'createClient';
  let method = createClientCustomMut;

  if (response) {
    Bugsnag.leaveBreadcrumb('Change to "Update client"', {
      MetaData: { clientId: shared.clientId },
    });

    methodString = 'updateClient';
    method = updateClientCustomMut;
  }

  const input = {
    id: shared.clientId,
    owner: userDetails.signInUserSession.idToken.payload['cognito:username'],
    location,
    eventId,
    windowVisible: !document.hidden,
    platform: {
      name: platform.name,
      version: platform.version,
      product: platform.product,
      manufacturer: platform.manufacturer,
      layout: platform.layout,
      os: platform.os,
      description: platform.description,
    },
  };
  Bugsnag.leaveBreadcrumb('Upsert client"', {
    MetaData: {
      clientId: shared.clientId,
      method: methodString,
      input: JSON.stringify(input),
    },
  });

  async function handleUpsertErrors(upsertErrors) {
    if (
      upsertErrors &&
      upsertErrors[0].errorType === 'DynamoDB:ConditionalCheckFailedException'
    ) {
      methodString = 'updateClient';
      method = updateClientCustomMut;

      const { data: updatedData, updateErrors } = await API.graphql({
        query: method,
        variables: {
          input,
        },
      });

      if (!updatedData[methodString]) {
        const userDetails = await Auth.currentAuthenticatedUser();
        const payload = {
          blocks: [
            {
              type: 'section',
              text: {
                type: 'plain_text',
                text: 'Error upserting client (updatedData)',
              },
            },
            {
              type: 'divider',
            },
            {
              type: 'context',
              elements: [
                {
                  type: 'mrkdwn',
                  text: `*ClientId:* ${shared.clientId}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*UserId:* ${userDetails.signInUserSession.idToken.payload['cognito:username']}`,
                },
              ],
            },
          ],
        };
        const options = {
          method: 'post',
          baseURL: `https://hooks.slack.com/services/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`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          data: payload,
        };
        // await axios.request(options);

        Bugsnag.notify('no data on client upsert');
      }
    } else {
      if (!data[methodString]) {
        const userDetails = await Auth.currentAuthenticatedUser();
        const payload = {
          blocks: [
            {
              type: 'section',
              text: {
                type: 'plain_text',
                text: 'Error upserting client (no data response)',
              },
            },
            {
              type: 'divider',
            },
            {
              type: 'context',
              elements: [
                {
                  type: 'mrkdwn',
                  text: `*ClientId:* ${shared.clientId}`,
                },
                {
                  type: 'mrkdwn',
                  text: `*UserId:* ${userDetails.signInUserSession.idToken.payload['cognito:username']}`,
                },
              ],
            },
          ],
        };
        const options = {
          method: 'post',
          baseURL: `https://hooks.slack.com/services/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`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          data: payload,
        };
        // await axios.request(options);

        Bugsnag.notify('no data on client upsert');
      }
    }
  }

  try {
    const { data, errors } = await API.graphql({
      query: method,
      variables: {
        input,
      },
    });

    if (errors?.length > 0) {
      handleUpsertErrors(errors);
    }
    return response;
  } catch (e) {
    Bugsnag.notify('client upsert catch', (event) => {
      event.addMetadata('Meta', {
        e: JSON.stringify(e),
      });
    });
    if (e.errors && e.errors.length > 0) {
      handleUpsertErrors(e.errors);
    }
    return response;
  }
};

export const removeClient = async () => {
  Bugsnag.leaveBreadcrumb('remove client"', {
    MetaData: {
      clientId: shared.clientId,
    },
  });
  const { data } = await API.graphql({
    query: updateClientCustomMut,
    variables: {
      input: {
        id: shared.clientId,
        deletedAt: new Date().toISOString()
      },
    },
  });

  return data;
};


