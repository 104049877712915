import API from '@aws-amplify/api';
import {
  getSpeaker,
  listSpeakers,
  eventsSpeakerBySpeakerId,
  slotByEventSpeakerId,
} from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { getEvents, updateEvent, updateSlot } from '../methods/events';
import { Auth } from 'aws-amplify';
import Bugsnag from '@bugsnag/js';
import {
  createEventSpeakerCustomMut,
  deleteEventSpeakerCustomMut,
  fetchSpeakers,
  updateEventSpeakerCustomMut,
} from '../graphql/custom';

export const getSpeakerById = async (speakerId) => {
  try {
    const {
      data: { getSpeaker: response },
    } = await API.graphql({
      query: getSpeaker,
      variables: {
        id: speakerId,
      },
    });

    return response;
  } catch (e) {
    console.log('respon err,', e);
    Bugsnag.notify(e);
  }
};

export const getSpeakers = async () => {

  let speakersToReturn = [];
  const getSpeakerBatch = async (nextToken) => {

  
  const {
    data: { listSpeakers: response },
  } = await API.graphql({
    query: fetchSpeakers,
    variables: {
      nextToken
    }
  });

  speakersToReturn = [...speakersToReturn, ...response.items];

  if(response.nextToken) {
    await getSpeakerBatch(response.nextToken);

  }



}


  await getSpeakerBatch()




  return {items: speakersToReturn};
};

export const addSpeaker = async ({ speaker, events }) => {

  const response = await API.post('AOTVRest', `/speaker`, {
    body: { ...speaker, keepData: '0', type: 'Speaker' },
  });

  if(response.err) {
    throw new Error(response.err)
  } else {


  if (events) {
    const addedEvents = events.map(async (eventSpeaker) => {
      try {
        await API.graphql({
          query: createEventSpeakerCustomMut,
          variables: {
            input: {
              id: eventSpeaker.id,
              note: eventSpeaker.note,
              internetClickerEnabled: eventSpeaker.internetClickerEnabled,
              internetClickerId: eventSpeaker.internetClickerId,
              showNotes: eventSpeaker.showNotes,
              role: eventSpeaker.role,
              eventId: eventSpeaker.eventId,
              speakerId: response.Username,
            },
          },
        });
      } catch (e) {
        console.log('add event for speaker eror', e);
        Bugsnag.notify(e);
      }
    });

    await Promise.all(addedEvents);
  }

  return response.Username;
}


};

export const updateSpeaker = async ({ speaker, events }) => {
 

  const response = await API.put('AOTVRest', `/speaker`, {
    body: speaker,
  });

  if(response.err) {
    throw new Error(response.err)
  } else {

  const {
    data: { updateSpeaker: updateSpeakerResponse },
  } = await API.graphql({
    query: mutations.updateSpeaker,
    variables: {
      input: speaker,
    },
  });

  if (events.add) {
    const addedEvents = events.add.map(async (eventSpeaker) => {
      await API.graphql({
        query: createEventSpeakerCustomMut,
        variables: {
          input: {
            id: eventSpeaker.id,
            note: eventSpeaker.note,
            internetClickerEnabled: eventSpeaker.internetClickerEnabled,
            internetClickerId: eventSpeaker.internetClickerId,
            showNotes: eventSpeaker.showNotes,
            role: eventSpeaker.role,
            eventId: eventSpeaker.eventId,
            speakerId: speaker.id,
          },
        },
      });
    });

    await Promise.all(addedEvents);
  }

  if (events.update) {
    const updatedEvents = events.update.map(async (eventSpeaker) => {
      await API.graphql({
        query: updateEventSpeakerCustomMut,
        variables: {
          input: {
            id: eventSpeaker.id,
            note: eventSpeaker.note,
            internetClickerEnabled: eventSpeaker.internetClickerEnabled,
            internetClickerId: eventSpeaker.internetClickerId,
            showNotes: eventSpeaker.showNotes,
            role: eventSpeaker.role,
            eventId: eventSpeaker.eventId,
            speakerId: speaker.id,
          },
        },
      });
    });

    await Promise.all(updatedEvents);
  }

  if (events.remove) {
    const removedEvents = events.remove.map(async (eventSpeaker) => {
      await API.graphql({
        query: deleteEventSpeakerCustomMut,
        variables: {
          input: {
            id: eventSpeaker.id,
          },
        },
      });
    });

    await Promise.all(removedEvents);
  }

  return updateSpeakerResponse;
}
};

export const removeSpeaker = async (speaker) => {
  const {
    data: { deleteSpeaker: response },
  } = await API.graphql({
    query: mutations.deleteSpeaker,
    variables: {
      input: { id: speaker.id },
    },
  });

  const {
    data: { eventsSpeakerBySpeakerId: eventSpeakers },
  } = await API.graphql({
    query: eventsSpeakerBySpeakerId,
    variables: {
      speakerId: speaker.id,
    },
  });

  const slotsAndEventSpeakers = eventSpeakers.items.map(async ({ id }) => {
    const {
      data: { slotByEventSpeakerId: eventSlots },
    } = await API.graphql({
      query: slotByEventSpeakerId,
      variables: {
        eventSpeakerId: id,
      },
    });

    const removedFromSlots = eventSlots.items.map(async (slot) => {
      await updateSlot({ id: slot.id, eventSpeakerId: null });
    });

    await Promise.all(removedFromSlots);

    await API.graphql({
      query: deleteEventSpeakerCustomMut,
      variables: {
        input: {
          id,
        },
      },
    });
  });

  await Promise.all(slotsAndEventSpeakers);

  await API.del('AOTVRest', `/speaker`, {
    body: speaker,
  });

  return response;
};

export const removeAccount = async () => {
  // const userDetails = await Auth.currentAuthenticatedUser();

  // const {
  //   data: { deleteSpeaker: response },
  // } = await API.graphql({
  //   query: mutations.deleteSpeaker,
  //   variables: {
  //     input: { id: userDetails.attributes.sub },
  //   },
  // });

  // const {
  //   data: { eventsSpeakerBySpeakerId: eventSpeakers },
  // } = await API.graphql({
  //   query: eventsSpeakerBySpeakerId,
  //   variables: {
  //     speakerId: userDetails.attributes.sub,
  //   },
  // });

  // const slotsAndEventSpeakers = eventSpeakers.items.map(async ({ id }) => {
  //   const {
  //     data: { slotByEventSpeakerId: eventSlots },
  //   } = await API.graphql({
  //     query: slotByEventSpeakerId,
  //     variables: {
  //       eventSpeakerId: id,
  //     },
  //   });

  //   const removedFromSlots = eventSlots.items.map(async (slot) => {
  //     await updateSlot({ id: slot.id, eventSpeakerId: null });
  //   });

  //   await Promise.all(removedFromSlots);

  //   await API.graphql({
  //     query: mutations.deleteEventSpeaker,
  //     variables: {
  //       input: {
  //         id,
  //       },
  //     },
  //   });
  // });

  // await Promise.all(slotsAndEventSpeakers);
  const userDetails = await Auth.currentAuthenticatedUser();
  await API.del('AOTVRest', `/account`, {
    body: {
      username:
        userDetails.signInUserSession.idToken.payload['cognito:username'],
    },
  });

  // return response;
};

export const completeAccount = async (userId, password, type, keepData) => {
  const response = await API.post('AOTVRest', `/invite/complete-account`, {
    body: {
      userId,
      password,
      type,
      keepData
    },
  });

  return response;
};
