import React, { ReactElement, useRef, useState, useEffect, useContext } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  Dropdown,
  Select,
  Space,
  Button,
  Typography,
  Popconfirm,
  PageHeader,
  Spin,
  message,
  Menu,
  Tooltip,
  Popover,
  Modal,
} from 'antd';

import moment from 'moment';
import {
  CopyOutlined,
  CloseCircleOutlined,
  MailOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';

import { getEvents, updateEvent } from '../../methods/events';
import { inviteSpeakerToEvent } from '../../methods/invite';
import { addSpeaker, updateSpeaker, getSpeakerById } from '../../methods/speakers';

import './styles.less';
import PageHead from '../../components/PageHead';
import generator from 'generate-password';

import _ from 'lodash';
import Bugsnag from '@bugsnag/js';
import RoleSelector from '../../components/RoleSelector';
import { API, Auth } from 'aws-amplify';
import passwordStrengthRule from '../../utils/passwordStrengthRule';
import usernameOrEmail from '../../utils/usernameOrEmail';
const { Option } = Select;
const { Text, Link } = Typography;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const EditableContext = React.createContext(null);

const ManuallySetPasswordContent = ({ userId, email, onPasswordSet }) => {
  const [submittingPasswordForm, setSubmittingPasswordForm] = useState(false);
  const [passwordForm] = Form.useForm();
  var initialPassword = generator.generate({
    length: 6,
    numbers: true,
    strict: true,
  });
  return (
    <Form
      id="passwordForm"
      form={passwordForm}
      initialValues={{ password: initialPassword }}
      onFinish={async values => {
        const hasError = true;

        try {
          await passwordStrengthRule({}).validator(null, values.password);

          setSubmittingPasswordForm(true);

          await API.put('AOTVRest', `/speaker/password`, {
            body: {
              id: userId,
              email,
              password: values.password,
            },
          });

          passwordForm.setFieldsValue({
            password: '',
          });
          setSubmittingPasswordForm(false);
          onPasswordSet(values.password);
        } catch (errors) {
          onPasswordSet();

          passwordForm.setFieldsValue({
            password: '',
          });
          console.log('errors', errors);

          if (errors.length > 0) {
            Modal.error({
              title: errors.shift(),
              content: errors.map(error => (
                <Text>
                  {error}
                  <br />
                </Text>
              )),
            });
          } else {
            Modal.error({
              title: 'Error',
              content: <Text>{errors}</Text>,
            });
          }
        }
      }}
    >
      <div>
        <Space align="start">
          <Form.Item
            name={'password'}
            rules={[{ required: true, message: 'Required' }]}
            style={{ marginBottom: 0 }}
          >
            <Input
              disabled={submittingPasswordForm}
              suffix={
                <Space>
                  <Tooltip title="Generate Password">
                    <a
                      onClick={() => {
                        var password = generator.generate({
                          length: 6,
                          numbers: true,
                          strict: true,
                        });
                        passwordForm.setFieldsValue({
                          password,
                        });
                        // setPasswordValue(password);
                      }}
                    >
                      <RedoOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Copy to clipboard">
                    <a
                      onClick={() => {
                        if (passwordForm.getFieldValue('password')) {
                          navigator.clipboard.writeText(
                            passwordForm.getFieldValue('password')
                          );
                          message.info(`Password copied to clipboard`);
                        }
                      }}
                    >
                      <CopyOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </a>
                  </Tooltip>
                </Space>
              }
            />
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={submittingPasswordForm}
            form="passwordForm"
          >
            Save
          </Button>
        </Space>
      </div>
    </Form>
  );
};

const SpeakersFormScene: React.FC<Props> = ({ match }: Props): ReactElement => {
  const isNew = match.params.speakerId === 'new';

  const [loading, setLoading] = useState(!isNew);
  const [submitting, setSubmitting] = useState({
    done: false,
    another: false,
  });

  const targetButton = useRef('done');

  const [speakerList, setSpeakerList] = useState([]);
  const [newEvents, setNewEvents] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [passwordPopupVisible, setPasswordPopupVisible] = useState(false);

  const history = useHistory();

  const [form] = Form.useForm();

  const EditableRow = ({ index, ...props }) => {
    return (
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);

    const form = useContext(EditableContext);
    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();

        const targetSpeakerIndex = _.findIndex(form?.getFieldValue('events'), {
          id: record.id,
        });
        const newSpeakerItems = [...form?.getFieldValue('events')];

        newSpeakerItems[targetSpeakerIndex][dataIndex] = values[dataIndex];
        newSpeakerItems[targetSpeakerIndex].toUpdate = true;

        form.setFieldsValue({
          events: newSpeakerItems,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
        Bugsnag.notify(errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            onChange={e => {
              if (dataIndex === 'internetClickerId') {
                form.setFieldsValue({
                  [dataIndex]: e.target.value.replace(/\D+/g, ''),
                });
              }
            }}
          />
        </Form.Item>
      ) : (
        <div className="editable-cell-value-wrap" onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  useEffect(() => {
    async function fetchSpeaker() {
      let speakerItem;
      const events = await getEvents();
      if (!isNew) {
        speakerItem = await getSpeakerById(match.params.speakerId);
        if (speakerItem) {
          setSpeaker(speakerItem);

          form.setFieldsValue(speakerItem);

          form.setFieldsValue({ events: speakerItem.speakerEvents.items });
        }
        setLoading(false);
      } else {
        form.setFieldsValue({ events: [] });
      }

      setEventList(events.items);
    }

    fetchSpeaker();
  }, []);

  const speakersEvents = form?.getFieldValue('events') || [];

  const children = eventList.reduce((list, event) => {
    if (!_.find(speakersEvents, { eventId: event.id }) && !event.deletedAt) {
      list.push(
        <Option key={event.id} value={event.id} label={event.title}>
          {event.title}
        </Option>
      );
    }
    return list;
  }, []);

  const columns = [
    {
      title: 'Event',
      dataIndex: 'title',
      key: 'title',
      render: (text: string, record: any) => {
        return (
          <>
            <Text>{record.title}</Text>
          </>
        );
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',

      render: (text: string, record: any) => (
        <>
          <RoleSelector
            defaultValue={record.role}
            onRoleChanged={role => {
              const targetIndex = _.findIndex(form?.getFieldValue('events'), {
                id: record.id,
              });

              const newEvents = [...form?.getFieldValue('events')];
              newEvents[targetIndex].role = role;
              newEvents[targetIndex].toUpdate = true;

              form.setFieldsValue({ events: newEvents });
            }}
          />
        </>
      ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'note',
        title: 'Note',
      }),
    },
    {
      title: 'Clicker ID',
      dataIndex: 'internetClickerId',
      key: 'internetClickerId',
      width: 152,
      onCell: record => ({
        record,
        editable: true,
        dataIndex: 'internetClickerId',
        title: 'Clicker ID',
      }),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 235,
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'right' }}>
          <Space size="middle">
            {!isNew && speaker.email && (
              <Popconfirm
                title={`Send Email Invite?`}
                okText="Yes"
                cancelText="No"
                onConfirm={async () => {
                  try {
                    await saveSpeaker();
                    await inviteSpeakerToEvent(speaker, record.event);
                    message.success(`Invite sent to ${speaker.email}`);
                  } catch (e) {
                    console.log('inviteSpeakerToEvent', e);
                    Bugsnag.notify(e);
                  }
                }}
              >
                <a>
                  <Space>
                    <MailOutlined />
                    Send Invite
                  </Space>
                </a>
              </Popconfirm>
            )}
            <Popconfirm
              title={`Remove Event from Speaker?`}
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                const updatedSpeakers = [];
                form?.getFieldValue('events').forEach(eventSpeaker => {
                  if (eventSpeaker.id === record.id) {
                    if (!eventSpeaker.isNew) {
                      updatedSpeakers.push({ ...eventSpeaker, toRemove: true });
                    }
                  } else {
                    updatedSpeakers.push(eventSpeaker);
                  }
                });

                form?.setFieldsValue({ events: updatedSpeakers });

                setEventList([...eventList]);
              }}
            >
              <Link>
                <Space>
                  <CloseCircleOutlined />
                  Remove
                </Space>
              </Link>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  function handleChange(value) {
    const newValues = value.map(id => {
      let item = _.find(eventList, { id });
      return item;
    });

    setNewEvents(newValues);
  }

  const onSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const saveSpeaker = async () => {
    const values = await form.validateFields();

    let eventsToAdd = [];
    let eventsToUpdate = [];
    let eventsToRemove = [];

    form.getFieldValue('events').forEach(eventSpeaker => {
      if (eventSpeaker.isNew) {
        eventsToAdd.push(eventSpeaker);
      }
      if (eventSpeaker.toUpdate) {
        eventsToUpdate.push(eventSpeaker);
      }
      if (eventSpeaker.toRemove) {
        eventsToRemove.push(eventSpeaker);
      }
    });

    await updateSpeaker({
      speaker: {
        id: match.params.speakerId,
        ...values,
        email: values.email ? values.email.toLowerCase().trim() : null,
        username: values.username ? values.username.toLowerCase().trim() : null

      },
      events: {
        add: eventsToAdd,
        update: eventsToUpdate,
        remove: eventsToRemove,
      },
    });

    const newValues = form
      .getFieldValue('events')
      .filter(o => !o.toRemove)
      .map(item => {
        delete item.isNew;
        delete item.toUpdate;
        return item;
      });

    form.setFieldsValue({ events: newValues });
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Spin spinning={loading}>
      <PageHeader
        ghost={false}
        style={{ maxWidth: 1400, margin: 'auto' }}
        title={isNew ? 'Add a Speaker' : 'Update Speaker'}
        onBack={() => history.goBack()}
        extra={
          <>
            <Button
              htmlType={'submit'}
              loading={submitting.another}
              form="form"
              onClick={() => {
              
                  targetButton.current = 'another';
               
              }}
            >
              Save &amp; Add Another Speaker
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting.done}
              onClick={() => {
                targetButton.current = 'done';
              }}
              form="form"
            >
              Save Speaker
            </Button>
          </>
        }
      >
        <PageHead title={isNew ? 'Add a Speaker' : 'Update Speaker'} />

        <Form
          id="form"
          form={form}
          layout="vertical"
          onFinish={async values => {
            setSubmitting({
              done: false,
              another: false,
              [targetButton.current]: true,
            });
            if (isNew) {
              try {
                delete values.password;
                const id = await addSpeaker({
                  speaker: {
                    ...values,
                    email: values.email ? values.email.toLowerCase().trim() : null,
                    username: values.username ? values.username.toLowerCase().trim() : null


                  },
                  events: form.getFieldValue('events'),
                });

                setSpeaker({
                  id,
                  ...values,
                  email: values.email ? values.email.toLowerCase().trim() : null,
                  username: values.username ? values.username.toLowerCase().trim() : null

                });
                setSubmitting({
                  done: false,
                  another: false,
                });
                message.success('Speaker Created');

                if (targetButton.current === 'another') {
                  form.resetFields();
                } else {
                  history.replace(`/speakers/${id}`);
                }
              } catch (e) {
                console.log('udpate event erororor', e);
                setSubmitting({
                  done: false,
                  another: false,
                });
                message.error(e.message || 'Error Creating Speaker');
              }
            } else {
              try {
                delete values.password;
                await saveSpeaker();
                setSpeaker({
                  id: match.params.speakerId,
                  ...values,
                  email: values.email ? values.email.toLowerCase().trim() : null,
                  username: values.username ? values.username.toLowerCase().trim() : null

                });
                setSubmitting({
                  done: false,
                  another: false,
                });
                message.success('Speaker Updated');


                if (targetButton.current === 'another') {
                  history.push(`/speakers/new`);
                } 

              } catch (e) {
                setSubmitting({
                  done: false,
                  another: false,
                });
                message.error(e.message || 'Error Updating Speaker');
                console.log('udpate speaker erororor', e);
                Bugsnag.notify(e);
              }
            }
          }}
        >
          <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
            <Col xs={24} sm={24} md={12} lg={6} xl={4}>
              <Form.Item
                name="firstName"
                label="First Name"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  autoFocus={isNew}
                  disabled={submitting.done || submitting.another}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={4}>
              <Form.Item
                name="lastName"
                label="Last Name"
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  disabled={submitting.done || submitting.another}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item
                name="email"
                label="Email"
                style={{ marginBottom: 0 }}
                rules={[
                  usernameOrEmail
                ]}
                dependencies={['username']}
              >
                <Input
                  style={{ width: '100%' }}
                  disabled={submitting.done || submitting.another}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6} xl={5}>
              <Form.Item
                name="username"
                label="Username"
                style={{ marginBottom: 0 }}
                rules={[usernameOrEmail]}
                dependencies={['email']}
                // rules={[{ required: true, message: 'Required' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  disabled={submitting.done || submitting.another}
                />
              </Form.Item>
            </Col>
            {!isNew && (
              <Col xs={24} sm={24} md={12} lg={12} xl={5}>
                <Form.Item name="password" label="Password" style={{ marginBottom: 0 }}>
                  <Space wrap>
                    <Popover
                      content={() => (
                        <ManuallySetPasswordContent
                          userId={speaker?.id}
                          email={speaker?.email}
                          onPasswordSet={password => {
                            if (password) {
                              navigator.clipboard.writeText(password);

                              message.info(`Password copied to clipboard`);
                              message.success(`New password set`);
                            }
                            setPasswordPopupVisible(false);
                          }}
                        />
                      )}
                      visible={passwordPopupVisible}
                      onVisibleChange={setPasswordPopupVisible}
                      title="New Password"
                      trigger="click"
                      destroyTooltipOnHide
                      placement="bottom"
                    >
                      <Button>Manually Set</Button>
                    </Popover>
                    {speaker?.email && (
                      <Popconfirm
                        title="Send reset link to Speaker?"
                        onConfirm={async () => {
                          try {
                            setResetPasswordLoading(true);
                            await Auth.forgotPassword(speaker.email);

                            setResetPasswordLoading(false);
                            message.success('Password Reset Link Sent');
                          } catch (e) {
                            let error = e.message;

                            if (
                              e.message ===
                              'User password cannot be reset in the current state.'
                            ) {
                              error = "User's email is not verified.";
                            }
                            Modal.error({ title: 'Error', content: error });
                          }
                        }}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button loading={resetPasswordLoading}>Reset</Button>
                      </Popconfirm>
                    )}
                  </Space>
                </Form.Item>
              </Col>
            )}
          </Row>
          {!isNew && (
            <Row>
              <Col md={24} lg={24}>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.events !== currentValues.events
                    }
                  >
                    {({ getFieldValue }) => {
                      const dataSource = getFieldValue('events')
                        ?.filter(o => !o.toRemove && !o.event?.deletedAt)
                        .map(eventSpeaker => ({
                          id: eventSpeaker.id,
                          key: eventSpeaker.id,
                          title: eventSpeaker.event.title,
                          note: eventSpeaker.note,
                          internetClickerId: eventSpeaker.internetClickerId,
                          role: eventSpeaker.role,
                          isNew: eventSpeaker.isNew,
                          event: eventSpeaker.event,
                        }));

                      const components = {
                        body: {
                          row: EditableRow,
                          cell: EditableCell,
                        },
                      };

                      return (
                        <Table
                          loading={
                            submitting.done || submitting.another
                              ? { spinning: true, indicator: null }
                              : false
                          }
                          rowClassName={item => {
                            return item.isNew ? 'isNew' : '';
                          }}
                          scroll={{ x: 'max-content' }}
                          dataSource={dataSource}
                          columns={columns}
                          pagination={false}
                          components={components}
                          rowSelection={rowSelection}
                        />
                      );
                    }}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        // flex: 1,
                        display: 'flex',
                        marginRight: 16,
                        flexWrap: 'wrap',
                      }}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.events !== currentValues.events
                        }
                      >
                        {({ getFieldValue }) => (
                          <>
                            <Select
                              mode="multiple"
                              value={newEvents.map(o => o.id)}
                              dropdownMatchSelectWidth={false}
                              allowClear
                              style={{
                                flex: 1,
                                marginRight: 16,
                                minWidth: 300,
                                marginBottom: 16,
                              }}
                              placeholder="Select Events"
                              onChange={handleChange}
                              optionLabelProp="label"
                              optionFilterProp="label"
                            >
                              {children}
                            </Select>
                            <Button
                              disabled={submitting.done || submitting.another}
                              onClick={() => {
                                form.setFieldsValue({
                                  events: _.uniqBy(
                                    [
                                      ...form.getFieldValue('events'),
                                      ...newEvents.map(event => ({
                                        id: uuidv4(),
                                        eventId: event.id,
                                        speakerId: match.params.speakerId,
                                        isNew: true,
                                        event,
                                      })),
                                    ],
                                    o => o.id
                                  ),
                                });

                                setNewEvents([]);
                              }}
                            >
                              Add Events
                            </Button>
                          </>
                        )}
                      </Form.Item>
                    </div>
                    <Space wrap>
                      {!isNew && speaker?.email && (
                        <Popconfirm
                          title={`Send Email Invites?`}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={async () => {
                            await saveSpeaker();

                            const allSent = selectedRowKeys.map(async eventSpeakerId => {
                              const targetEvent = _.find(form?.getFieldValue('events'), {
                                id: eventSpeakerId,
                              });
                              if (targetEvent) {
                                await inviteSpeakerToEvent(speaker, targetEvent.event);
                              }
                            });
                            await Promise.all(allSent);
                            message.success(
                              `${selectedRowKeys.length} Invite${
                                selectedRowKeys.length > 1 ? 's' : ''
                              } Sent`
                            );
                            setSelectedRowKeys([]);
                          }}
                        >
                          <Button type="primary" disabled={!hasSelected}>
                            Send Invites
                          </Button>
                        </Popconfirm>
                      )}
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                      </span>
                      <Popconfirm
                        title={`Remove Events from Speaker?`}
                        okText="Yes"
                        disabled={!hasSelected}
                        cancelText="No"
                        onConfirm={() => {
                          const updatedSpeakers = [];
                          form?.getFieldValue('events').forEach(eventSpeaker => {
                            if (selectedRowKeys.includes(eventSpeaker.id)) {
                              if (!eventSpeaker.isNew) {
                                updatedSpeakers.push({
                                  ...eventSpeaker,
                                  toRemove: true,
                                });
                              }
                            } else {
                              updatedSpeakers.push(eventSpeaker);
                            }
                          });

                          form?.setFieldsValue({ events: updatedSpeakers });

                          setSelectedRowKeys([]);
                        }}
                      >
                        <Button danger disabled={!hasSelected}>
                          Remove Events
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </PageHeader>
    </Spin>
  );
};

SpeakersFormScene.defaultProps = defaultProps;
export default SpeakersFormScene;
