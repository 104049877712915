import React, { ReactElement, useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Space,
  Select,
  Table,
  Modal,
  Typography,
  Button,
  Popconfirm,
  Tag,
  Spin,
  message,
} from 'antd';

import { Link, useHistory } from 'react-router-dom';

import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import './styles.less';
import { getTeamMembers, removeTeamMember } from '../../methods/team';

import PageHead from '../../components/PageHead';
const { Title } = Typography;
const { Option } = Select;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const TeamScene: React.FC<Props> = ({}: Props): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [teamMembers, setTeamMembers] = useState({ items: [], nextToken: null });
  const [removedTeamMembers, setRemovedTeamMembers] = useState([]);

  const history = useHistory();

  useEffect(() => {
    async function fetchTeamMembers() {
      const data = await getTeamMembers();

      setTeamMembers(data);
      setLoading(false);
    }
    fetchTeamMembers();
  }, []);

 

  const dataSource = teamMembers.items
    .filter((o) => !removedTeamMembers.includes(o.id))
    .map((member) => ({
      key: member.id,
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
      email: member.email,
    }))
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

 

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 235,
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'right' }}>
          <Space size='middle'>
            <Link to={`/team/${record.id}`}>
              <Space>
                <EditOutlined />
                Edit
              </Space>
            </Link>
            <Popconfirm
              title={`Delete Team Member?`}
              okText='Yes'
              onConfirm={() => {
                setRemovedTeamMembers([...removedTeamMembers, record.id]);
                removeTeamMember(record);
                message.success('Team Member Removed');
              }}
              cancelText='No'
            >
              <Link>
                <Space>
                  <DeleteOutlined />
                  Remove
                </Space>
              </Link>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHead title={'Team'} />

      <Row style={{ marginBottom: 8 }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title level={4}>Team</Title>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: 'right' }}
        >
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              history.push('/team/new');
            }}
          >
            Add Team Member
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 'max-content' }}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  );
};

TeamScene.defaultProps = defaultProps;
export default TeamScene;
