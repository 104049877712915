/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSpeaker = /* GraphQL */ `
  mutation CreateSpeaker(
    $input: CreateSpeakerInput!
    $condition: ModelSpeakerConditionInput
  ) {
    createSpeaker(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const updateSpeaker = /* GraphQL */ `
  mutation UpdateSpeaker(
    $input: UpdateSpeakerInput!
    $condition: ModelSpeakerConditionInput
  ) {
    updateSpeaker(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpeaker = /* GraphQL */ `
  mutation DeleteSpeaker(
    $input: DeleteSpeakerInput!
    $condition: ModelSpeakerConditionInput
  ) {
    deleteSpeaker(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVerificationToken = /* GraphQL */ `
  mutation CreateVerificationToken(
    $input: CreateVerificationTokenInput!
    $condition: ModelVerificationTokenConditionInput
  ) {
    createVerificationToken(input: $input, condition: $condition) {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateVerificationToken = /* GraphQL */ `
  mutation UpdateVerificationToken(
    $input: UpdateVerificationTokenInput!
    $condition: ModelVerificationTokenConditionInput
  ) {
    updateVerificationToken(input: $input, condition: $condition) {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteVerificationToken = /* GraphQL */ `
  mutation DeleteVerificationToken(
    $input: DeleteVerificationTokenInput!
    $condition: ModelVerificationTokenConditionInput
  ) {
    deleteVerificationToken(input: $input, condition: $condition) {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const createSlot = /* GraphQL */ `
  mutation CreateSlot(
    $input: CreateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    createSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSlot = /* GraphQL */ `
  mutation UpdateSlot(
    $input: UpdateSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    updateSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSlot = /* GraphQL */ `
  mutation DeleteSlot(
    $input: DeleteSlotInput!
    $condition: ModelSlotConditionInput
  ) {
    deleteSlot(input: $input, condition: $condition) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEventSpeaker = /* GraphQL */ `
  mutation CreateEventSpeaker(
    $input: CreateEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    createEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateEventSpeaker = /* GraphQL */ `
  mutation UpdateEventSpeaker(
    $input: UpdateEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    updateEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteEventSpeaker = /* GraphQL */ `
  mutation DeleteEventSpeaker(
    $input: DeleteEventSpeakerInput!
    $condition: ModelEventSpeakerConditionInput
  ) {
    deleteEventSpeaker(input: $input, condition: $condition) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createInvite = /* GraphQL */ `
  mutation CreateInvite(
    $input: CreateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    createInvite(input: $input, condition: $condition) {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const updateInvite = /* GraphQL */ `
  mutation UpdateInvite(
    $input: UpdateInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    updateInvite(input: $input, condition: $condition) {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvite = /* GraphQL */ `
  mutation DeleteInvite(
    $input: DeleteInviteInput!
    $condition: ModelInviteConditionInput
  ) {
    deleteInvite(input: $input, condition: $condition) {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
