import React, { ReactElement, useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Button, Spin, Popconfirm } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import './styles.less';
import { Auth } from 'aws-amplify';
import moment from 'moment';

import { getEventsBySpeakerId } from '../../methods/events';
import { getSpeakerById } from '../../methods/speakers';
import PageHead from '../../components/PageHead';
import strings from '../../utils/strings';
import { updateEventSpeaker } from '../../methods/eventSpeakers';

const { Title, Text } = Typography;
/**
 * Description
 */

type Props = {
  name: string;
};

const defaultProps = {};

// const events = [
//   { id: '1', title: 'Pawnee Planning Commitee', time: 'Today 14:00' },
//   { id: '2', title: 'WWDC', time: '17th May 09:30' },
// ];
const SpeakerChooseEventScene: React.FC<Props> = ({
  name,
}: Props): ReactElement => {
  const history = useHistory();


  const [loading, setLoading] = useState(true);
  const [speaker, setSpeaker] = useState(null);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      const userDetails = await Auth.currentAuthenticatedUser();
      const speakerItem = await getSpeakerById(userDetails.signInUserSession.idToken.payload['cognito:username']);

      setSpeaker(speakerItem);
      const { items } = await getEventsBySpeakerId(userDetails.signInUserSession.idToken.payload['cognito:username']);
      setEvents(items);

      setLoading(false);
    }

    fetchEvents();
  }, []);

  return (
    <div id='choose-scene'>
      <Spin spinning={loading} className='choose-spinner'>
        <div style={{ minHeight: 400 }}>
          <PageHead title={'Choose Event'} />

          {!loading && (
            <div>
              <div
                style={{ marginBottom: 30, marginTop: 60, textAlign: 'center' }}
              >
                <Title level={3} style={{ fontSize: '1.8em', marginBottom: 0 }}>
                  Hi {speaker?.firstName}
                </Title>
                <Text style={{ fontSize: '1.6em' }}>
                  {events.length > 0
                    ? 'Please choose your event.'
                    : "Looks like you aren't a part of any events right now."}
                </Text>
              </div>
              {events.length > 0 && (
                <Row justify='center' gutter={[16, 16]}>
                  {events.map((event) => (
                    <Col>
                      <Card
                        bodyStyle={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 320,
                          maxWidth: '100%',
                          minHeight: 200,
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Title level={4} style={{ marginBottom: 0 }}>
                            {event.event.title}
                          </Title>
                          {event.event.date && (
                            <Text
                              type='secondary'
                              style={{ fontSize: '1.4em', display: 'block' }}
                            >
                              {moment(event.event.date).calendar()}
                            </Text>
                          )}
                        </div>
                        {!event.isFinished && (
                          <Link to={`/waiting/${event.event.id}`}>
                            <Button type='primary' block>
                              Join Waiting Room
                            </Button>
                          </Link>
                        )}
                        {event.isFinished && event.finishedBy === 'speaker' && (
                          <Popconfirm
                            title={strings.event.rejoin.rejoinPopUp.title}
                            okText={
                              strings.event.rejoin.rejoinPopUp.okText
                            }
                            cancelText={
                              strings.event.rejoin.rejoinPopUp.cancelText
                            }
                            onConfirm={async () => {
                              await updateEventSpeaker({
                                id: event?.id,
                                isFinished: false,
                                finishedBy: null
                              });
                              history.push(`/waiting/${event.event.id}`);
                            }}
                          >
                            <Button type='primary' block>
                              Join Waiting Room
                            </Button>
                          </Popconfirm>
                        )}
                      </Card>
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

SpeakerChooseEventScene.defaultProps = defaultProps;
export default SpeakerChooseEventScene;
