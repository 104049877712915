import React, { ReactElement, useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Popover,
  Avatar,
  Space,
  Table,
  Typography,
  Popconfirm,
  Button,
  Select,
  Input,
  Spin,
  Form,
  PageHeader,
  Layout,
  Divider,
  Empty,
  Modal,
  message,
} from 'antd';
import util from 'util';
import {
  NumberOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  CloseOutlined,
  LoginOutlined,
  ExclamationCircleOutlined,
  RollbackOutlined,
  PoweroffOutlined,
  VideoCameraAddOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

import _ from 'lodash';
import strings from '../../utils/strings';

import SlotCard from '../../components/SlotCard';
import SlotCardEmpty from '../../components/SlotCardEmpty';
import Hand from '../../components/Hand';
import {
  getEventById,
  subscribeToEvent,
  updateEvent,
  addSlotToEvent,
  updateSlot,
  removeSlot,
  getSlotsWithVmixId,
  endEvent,
} from '../../methods/events';

import Amplify, { Auth, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import moment from 'moment';
import AWS from 'aws-sdk';
import { useHistory } from 'react-router-dom';
import { Event } from '../../API';
import { updateEventSpeaker } from '../../methods/eventSpeakers';
import PageHead from '../../components/PageHead';
import HoldingMessageScene from '../HoldingMessageScene';
import RoleSelector from '../../components/RoleSelector';
import LastSeenAt from '../../components/LastSeenAt';
const { confirm } = Modal;

const { Content } = Layout;
var uuid = require('uuid').v4;

const { Option, OptGroup } = Select;
const { Text } = Typography;

type Props = {};

const defaultProps = {};

const colors = {
  online: '#87d068',
  offline: '#f6323a',
  windowHidden: 'orange',
};

const statusColor = {
  offline: colors.offline,
  online: colors.online,
  hidden: colors.windowHidden,
};
const vmixStatusColor = {
  offline: 'error',
  online: 'success',
  hidden: colors.windowHidden,
};

const EventScene: React.FC<Props> = ({ match }: Props): ReactElement => {
  const history = useHistory();
  const [addCallSlotForm] = Form.useForm();
  const [waitingRoomForm] = Form.useForm();

  const [event, setEvent] = useState<Event>(null);
  const [eventSlots, setEventSlots] = useState({ items: [] });
  const [eventSpeakers, setEventSpeakers] = useState({ items: [] });
  const [eventClients, setEventClients] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [addCallSlotVisible, setAddCallSlotVisible] = useState(false);
  const slotChangeHolder = useRef(null);
  const speakerChangeHolder = useRef(null);
  const clientChangeHolder = useRef(null);

  const slotChange = (newSlot) => {
    // nothing changed here

    const newSlots = eventSlots.items.filter(({ id }) => newSlot.id !== id);
    if (!newSlot.deletedAt) {
      newSlots.push(newSlot);
    }

    setEventSlots({
      items: newSlots.sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      }),
    });
  };

  slotChangeHolder.current = slotChange; // happens on each render

  const speakerChange = (newEventSpeaker) => {
    // nothing changed here
    const newSpeakers = eventSpeakers.items.filter(
      ({ id }) => newEventSpeaker.id !== id
    );
    if (!newEventSpeaker.deletedAt) {
      newSpeakers.push(newEventSpeaker);
    }
    setEventSpeakers({ items: newSpeakers });
  };

  speakerChangeHolder.current = speakerChange; // happens on each render

  const clientChange = (newClient) => {
    // nothing changed here
    const newClients = { ...eventClients };
    newClients[newClient.owner] = newClient;
    setEventClients(newClients);
  };

  clientChangeHolder.current = clientChange; // happens on each render

  useEffect(() => {
    if (error) {
      Modal.warning({
        title: 'Connection lost',
        content: 'You have been disconnected from the server',
        okText: 'Reload Browser',
        closable: false,
        onOk: () => {
          window.location.reload();
        },
      });
    }
  }, [error]);
  useEffect(() => {
    async function fetchEvent() {
      const targetEvent = await getEventById(match.params.eventId);

      setEventSlots({
        items: targetEvent.slots.items.sort((a, b) => {
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        }),
      });
      setEventSpeakers(targetEvent.speakers);
      setEvent(targetEvent);

      let speakerClients = {};
      targetEvent.speakers.items.forEach((speakerItem) => {
        const orderedClients = speakerItem?.client?.items.sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });

        if (orderedClients[0]) {
          speakerClients[speakerItem.speaker.id] = orderedClients[0];
        }
      });

      setEventClients(speakerClients);

      setLoading(false);
    }

    fetchEvent();

    const eventSubscription = subscribeToEvent(match.params.eventId, {
      onEventChange: async (newEvent) => {
        setEvent(newEvent);
      },
      onSlotChanged: async (newSlot) => {
        slotChangeHolder.current(newSlot);
      },
      onSlotDeleted: async (slotToDelete) => {
        slotChangeHolder.current({
          ...slotToDelete,
          deletedAt: new Date().toISOString(),
        });
      },
      onEventSpeakerChanged: async (newEventSpeaker) => {
        speakerChangeHolder.current(newEventSpeaker);
      },
      onEventSpeakerDeleted: async (eventSpeakerToDelete) => {
        speakerChangeHolder.current({
          ...eventSpeakerToDelete,
          deletedAt: new Date().toISOString(),
        });
      },
      onClientChanged: async (newClient) => {
        clientChangeHolder.current(newClient);
      },
      onError: (err) => {
        console.log('err', err);
        if (
          err.error?.errors?.length > 0 &&
          err.error?.errors[0].message === 'Connection closed'
        ) {
          setError('Connection Closed');
        }
      },
    });

    const setupSockets = async () => {
      const credentials = await Auth.currentCredentials();

      const iot = new AWS.Iot({
        region: 'eu-west-2',
        credentials: Auth.essentialCredentials(credentials),
      });

      const policyName = 'AOTV';
      const target = credentials.identityId;

      const { policies } = await iot.listAttachedPolicies({ target }).promise();

      if (!policies.find((policy) => policy.policyName === policyName)) {
        await iot.attachPolicy({ policyName, target }).promise();
      }
    };

    setupSockets();

    return () => {
      eventSubscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <>
        <PageHead title={'Event'} />
        <Spin style={{ marginTop: 60 }} />
      </>
    );
  }

  const addSlotWithVmixId = async ({
    vMixId,
    reset,
    eventSpeakerId,
    slotIdToUpdate,
    fromWaitingRoom,
  }) => {
    let slots = { items: [] };
    if (vMixId) {
      slots = await getSlotsWithVmixId(vMixId);
    }

    if (slots.items.length > 0) {
      const slot = slots.items[0];

      if (slot.event.id === match.params.eventId && !slot.eventSpeaker) {
        if (eventSpeakerId) {
          if (fromWaitingRoom) {
            await updateSlot({ id: slot.id, vMixId, eventSpeakerId });

            reset && reset();
          } else {
            confirm({
              title: strings.validation.vMix.alreadyExists.title,
              icon: <ExclamationCircleOutlined />,
              okType: 'danger',
              content: strings.validation.vMix.alreadyExists.description,
              onOk: async () => {
                await updateSlot({ id: slot.id, eventSpeakerId });

                if (slotIdToUpdate) {
                  await updateSlot({
                    id: slotIdToUpdate,
                    eventSpeakerId: null,
                    vMixId: null,
                  });
                }
              },
              onCancel() {},
            });
          }
        } else {
          Modal.error({
            title: strings.validation.vMix.alreadyExists.title,
          });
        }
      } else {
        let conflictString;
        if (slot.event.id === match.params.eventId) {
          // Same Event, With Speaker
          if (slot.eventSpeaker) {
            conflictString = util.format(
              strings.validation.vMix.conflict.sameEventFilledSlot,
              `${slot.eventSpeaker.speaker.firstName} ${slot.eventSpeaker.speaker.lastName}`
            );
          }
          // Same Event, No Speaker
          else {
            conflictString = util.format(
              strings.validation.vMix.conflict.sameEventEmptySlot,
              vMixId
            );
          }
        } else {
          // Different Event, With Speaker
          if (slot.eventSpeaker) {
            conflictString = util.format(
              strings.validation.vMix.conflict.differentEventFilledSlot,
              slot.event.title,
              `${slot.eventSpeaker.speaker.firstName} ${slot.eventSpeaker.speaker.lastName}`,
              vMixId
            );
          }
          // Different Event, No Speaker
          else {
            conflictString = util.format(
              strings.validation.vMix.conflict.differentEventEmptySlot,
              slot.event.title,
              vMixId
            );
          }
        }

        confirm({
          title: strings.validation.vMix.alreadyInUse.title,
          icon: <ExclamationCircleOutlined />,
          okType: 'danger',
          content: conflictString,

          // `This vMixId is already being used${
          //   slot.event.id === match.params.eventId
          //     ? ''
          //     : ` in "${slot.event.title}"
          //               `
          // }${
          //   slot.eventSpeaker
          //     ? ` by "${slot.eventSpeaker.speaker.firstName} ${slot.eventSpeaker.speaker.lastName}".`
          //     : '.'
          // }\n\n\nThis will remove the slot with vMixId "${vMixId}"${
          //   slot.eventSpeaker
          //     ? ' and move the speaker back to the waiting room'
          //     : ''
          // }`
          onOk: async () => {
            if (slot.event.id === match.params.eventId) {
              if (slotIdToUpdate) {
                await updateSlot({
                  id: slotIdToUpdate,
                  eventSpeakerId: null,
                  vMixId: null,
                });
              }
              await updateSlot({ id: slot.id, eventSpeakerId });
            } else {
              await removeSlot(slot.id);
              if (slotIdToUpdate) {
                await updateSlot({ id: slotIdToUpdate, eventSpeakerId });
              } else {
                await addSlotToEvent({
                  id: uuid(),
                  vMixId,
                  eventId: event.id,
                  eventSpeakerId,
                });
              }
            }
            reset && reset();
          },
          onCancel() {
            reset && reset();
          },
        });
      }
    } else {
      if (slotIdToUpdate) {
        await updateSlot({ id: slotIdToUpdate, vMixId });
      } else {
        await addSlotToEvent({
          id: uuid(),
          vMixId,
          eventId: event.id,
          eventSpeakerId,
        });
      }

      reset && reset();
    }
  };

  const slotsWithNoSpeaker = eventSlots?.items?.filter(
    ({ eventSpeakerId }) => !eventSpeakerId
  );
  const slotsWithSpeaker = eventSlots?.items?.filter(
    ({ eventSpeakerId }) => eventSpeakerId
  );

  const speakersNotAssignedToCall = eventSpeakers?.items?.filter(
    (eventSpeaker) => {
      let assignedToCall = false;
      eventSlots?.items?.forEach((slot) => {
        if (slot?.eventSpeaker?.id === eventSpeaker?.id) {
          assignedToCall = true;
        }
      });

      return !assignedToCall && !eventSpeaker?.isFinished;
    }
  );

  const finishedSpeakers = eventSpeakers?.items
    ?.filter((eventSpeaker) => {
      return eventSpeaker.isFinished;
    })
    .map((eventSpeaker) => {
      return {
        key: eventSpeaker?.id,
        name: `${eventSpeaker?.speaker?.firstName} ${eventSpeaker?.speaker?.lastName}`,
        email: eventSpeaker?.speaker?.email,
        note: eventSpeaker?.note,
        actions: '',
        eventSpeaker,
      };
    })
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const speakersInWaitingRoom = speakersNotAssignedToCall
    ?.map((eventSpeaker) => {
      let status = 'offline';

      const speakersClient = eventClients[eventSpeaker.speaker.id];
      if (speakersClient && !speakersClient.deletedAt) {
        if (speakersClient.location === `/waiting/${match.params.eventId}`) {
          status = speakersClient.windowVisible ? 'online' : 'hidden';
        }
      }

      return {
        key: eventSpeaker?.id,
        status,
        name: `${eventSpeaker?.speaker?.firstName} ${eventSpeaker?.speaker?.lastName}`,
        email: eventSpeaker?.speaker?.email,
        note: eventSpeaker?.note,
        actions: '',
        platform: speakersClient?.platform,
        lastSeenAt: speakersClient?.updatedAt,
        eventSpeaker,
      };
    })
    .sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const columns = [
    {
      title: strings.event.waitingRoom.title,
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <Space align="center" size="middle">
            <Avatar
              size={16}
              style={{
                backgroundColor: statusColor[record.status],
              }}
            />
            <div>
              <Text style={{ display: 'block' }}>{record.name}</Text>
              <RoleSelector
                defaultValue={record.eventSpeaker.role || 'speaker'}
                onRoleChanged={(role) => {
                  updateEventSpeaker({
                    id: record?.eventSpeaker.id,
                    role,
                  });
                }}
              />
              {record.note && (
                <Text style={{ display: 'block' }} type="secondary">
                  {record.note}
                </Text>
              )}
            </div>
          </Space>
        );
      },
    },

    {
      title: '',
      dataIndex: 'browserInfo',
      key: 'browserInfo',
      width: 5,
      render: (text: string, record: any) => {
        return (
          <Popover
            placement="top"
            content={
              <div>
                {record.platform && <Text>{record.platform.description}</Text>}
                {record.status !== 'online' && (
                  <>
                    <br />
                    <Text type="secondary">
                      Last Seen: <LastSeenAt date={record.lastSeenAt} />
                    </Text>
                  </>
                )}
              </div>
            }
            title={strings.event.waitingRoom.platformPopUp.title}
            trigger="click"
            // visible={this.state.visible}
            // onVisibleChange={this.handleVisibleChange}
          >
            <InfoCircleOutlined key="info" style={{ color: '#999' }} />
          </Popover>
        );
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      // width: 100,
      render: (text: string, record: any) => {
        const [slotSearchValue, setSlotSearchValue] = useState(null);
        const [isAssignedToCallVisible, setIsAssignedToCallVisible] =
          useState(false);
        return (
          <Space
            size="middle"
            align="center"
            style={{ float: 'right', height: 40 }}
          >
            {record.eventSpeaker.isHandRaised && (
              <Hand
                onClick={() => {
                  updateEventSpeaker({
                    id: record?.eventSpeaker?.id,
                    isHandRaised: false,
                    handLoweredBy: 'team',
                  });
                }}
              />
            )}

            <Popover
              visible={isAssignedToCallVisible}
              onVisibleChange={setIsAssignedToCallVisible}
              content={
                <Form
                  form={waitingRoomForm}
                  onFinish={(values) => {
                    setIsAssignedToCallVisible(false);
                    const key = values[record.key + 'vMixId']
                      ? record.key + 'vMixId'
                      : record.key + 'new-vMixId';

                    const slotAlreadyTaken = _.find(eventSlots?.items, {
                      id: values[key],
                    });

                    if (slotAlreadyTaken.eventSpeakerId) {
                      Modal.confirm({
                        title: strings.event.slot.alreadyAllocated.title,
                        content:
                          strings.event.slot.alreadyAllocated.description,
                        okText: strings.event.slot.alreadyAllocated.okText,
                        cancelText:
                          strings.event.slot.alreadyAllocated.cancelText,
                        onOk: () => {
                          updateSlot({
                            id: values[key],
                            eventSpeakerId: record.eventSpeaker.id,
                          });
                          setTimeout(() => {
                            waitingRoomForm.setFieldsValue({
                              [key]: '',
                            });
                          }, 500);
                        },
                      });
                    } else {
                      updateSlot({
                        id: values[key],
                        eventSpeakerId: record.eventSpeaker.id,
                      });

                      setTimeout(() => {
                        waitingRoomForm.setFieldsValue({
                          [key]: '',
                        });
                      }, 500);
                    }
                  }}
                >
                  <Space style={{ width: '100%' }}>
                    <Form.Item name={record.key + 'vMixId'} noStyle>
                      <Select
                        showSearch
                        placeholder={
                          strings.event.waitingRoom.allocatePopUp.selectLabel
                        }
                        searchValue={slotSearchValue}
                        optionFilterProp="children"
                        style={{ width: 120 }}
                        dropdownMatchSelectWidth={false}
                        onSearch={(text) => {
                          setSlotSearchValue(text.replace(/\D+/g, ''));
                        }}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                padding: 8,
                              }}
                            >
                              <a
                                style={{
                                  flex: 'none',
                                  padding: '8px',
                                  display: 'block',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  waitingRoomForm.submit();
                                }}
                              >
                                <PlusOutlined />{' '}
                                {
                                  strings.event.waitingRoom.allocatePopUp
                                    .addSlotLink
                                }
                              </a>
                            </div>
                          </div>
                        )}
                      >
                        <OptGroup
                          label={
                            strings.event.waitingRoom.allocatePopUp
                              .sectionEmptyTitle
                          }
                        >
                          {eventSlots?.items.reduce((list, slot, i) => {
                            if (!slot.eventSpeakerId) {
                              list.push(
                                <Option key={slot.id} value={slot.id}>
                                  {i + 1}
                                </Option>
                              );
                            }

                            return list;
                          }, [])}
                        </OptGroup>

                        <OptGroup
                          label={
                            strings.event.waitingRoom.allocatePopUp
                              .sectionInUseTitle
                          }
                        >
                          {eventSlots?.items.reduce((list, slot, i) => {
                            if (slot.eventSpeakerId) {
                              list.push(
                                <Option key={slot.id} value={slot.id}>
                                  {i + 1}
                                </Option>
                              );
                            }

                            return list;
                          }, [])}
                        </OptGroup>
                      </Select>
                    </Form.Item>
                    <Button htmlType="submit" type="primary">
                      {strings.event.waitingRoom.allocatePopUp.buttonLabel}
                    </Button>
                  </Space>
                </Form>
              }
              title={strings.event.waitingRoom.allocatePopUp.title}
              trigger="click"
            >
              <Button
                shape="circle"
                icon={<LoginOutlined style={{ color: '#999' }} />}
              />
            </Popover>

            <Popconfirm
              title={strings.event.waitingRoom.removePopUp.title}
              okText={strings.event.waitingRoom.removePopUp.okText}
              cancelText={strings.event.waitingRoom.removePopUp.cancelText}
              onConfirm={() => {
                updateEventSpeaker({
                  id: record?.eventSpeaker?.id,
                  isFinished: true,
                  finishedBy: 'team',
                });
              }}
            >
              <Button
                shape="circle"
                icon={
                  <CloseOutlined
                    style={{
                      color: '#999',
                    }}
                  />
                }
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const finishedColumns = [
    {
      title: strings.event.finishedSpeakers.title,
      dataIndex: 'name',
      key: 'name',
      width: '100%',
      render: (text: string, record: any) => (
        <Space align="center" size="middle">
          <div>
            <Text style={{ display: 'block' }}>{record.name}</Text>
            {record.note && (
              <Text style={{ display: 'inline-block' }} type="secondary">
                {record.note}
              </Text>
            )}
          </div>
        </Space>
      ),
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any) => {
        return (
          <Popconfirm
            title={strings.event.finishedSpeakers.movePopUp.title}
            okText={strings.event.finishedSpeakers.movePopUp.okText}
            cancelText={strings.event.finishedSpeakers.movePopUp.cancelText}
            onConfirm={() => {
              updateEventSpeaker({
                id: record?.eventSpeaker?.id,
                isFinished: false,
              });
            }}
          >
            <Button
              shape="circle"
              icon={
                <RollbackOutlined
                  style={{
                    color: '#999',
                  }}
                />
              }
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <>
      <PageHead title={event.title} />
      <Content className="contentLayout">
        <PageHeader
          title={event.title}
          subTitle={
            event.date &&
            moment(event.date).calendar(null, {
              nextWeek: 'DD/MM/YYYY  [at] LT',
              lastWeek: 'DD/MM/YYYY  [at] LT',
              sameElse: 'DD/MM/YYYY  [at] LT',
            })
          }
          onBack={() => history.goBack()}
          extra={
            <>
              {!event.isFinished && (
                <Popconfirm
                  title={strings.event.endEvent.popUp.title}
                  okText={strings.event.endEvent.popUp.okText}
                  cancelText={strings.event.endEvent.popUp.cancelText}
                  onConfirm={() => {
                    Modal.confirm({
                      title: strings.event.endEvent.popUp.confirm.title,
                      content: strings.event.endEvent.popUp.confirm.description,
                      okText: strings.event.endEvent.popUp.confirm.okText,
                      cancelText:
                        strings.event.endEvent.popUp.confirm.cancelText,
                      onOk: () => {
                        endEvent(match.params.eventId);
                      },
                    });
                  }}
                >
                  <Button type="danger" icon={<PoweroffOutlined />}>
                    {strings.event.endEvent.buttonLabel}
                  </Button>
                </Popconfirm>
              )}

              {!event.isFinished && (
                <Button
                  type="primary"
                  icon={<VideoCameraAddOutlined />}
                  onClick={async () => {
                    await addSlotToEvent({
                      id: uuid(),
                      eventId: event.id,
                    });
                    message.success(
                      `Added slot ${eventSlots?.items.length + 1}`
                    );
                  }}
                >
                  {strings.event.addCallSlot.buttonLabel}
                </Button>
              )}

              {event.isFinished && (
                <Popconfirm
                  title={strings.event.eventFinished.openEvent.popUp.title}
                  okText={strings.event.eventFinished.openEvent.popUp.okText}
                  cancelText={
                    strings.event.eventFinished.openEvent.popUp.cancelText
                  }
                  onConfirm={async () => {
                    await updateEvent({
                      event: {
                        id: match.params.eventId,
                        isFinished: false,
                      },
                    });
                  }}
                >
                  <Button type="primary" icon={<PlayCircleOutlined />}>
                    {strings.event.eventFinished.openEvent.buttonLabel}
                  </Button>
                </Popconfirm>
              )}
            </>
          }
        >
          {event.isFinished && (
            <div
              style={{
                display: 'flex',
                height: '80%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 128,
              }}
            >
              <HoldingMessageScene
                shouldShowLogo={false}
                isLoading={false}
                title={strings.event.eventFinished.title}
                message={strings.event.eventFinished.description}
              />
            </div>
          )}
          {!event.isFinished &&
          speakersInWaitingRoom.length === 0 &&
          finishedSpeakers.length === 0 &&
          slotsWithSpeaker.length === 0 &&
          slotsWithNoSpeaker.length === 0 ? (
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Empty
                imageStyle={{ marginTop: 100 }}
                description={strings.event.empty}
              />
            </div>
          ) : (
            !event.isFinished && (
              <Row gutter={[16, 16]} className="eventScene">
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 12, order: 1 }}
                  lg={{ span: 10, order: 1 }}
                  xl={{ span: 8, order: 1 }}
                  xxl={{ span: 6, order: 1 }}
                  style={{ paddingBottom: 60 }}
                >
                  <Table
                    dataSource={speakersInWaitingRoom}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: strings.event.waitingRoom.empty }}
                  />

                  {finishedSpeakers.length > 0 && (
                    <Table
                      dataSource={finishedSpeakers}
                      columns={finishedColumns}
                      pagination={false}
                    />
                  )}
                </Col>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 12, order: 2 }}
                  lg={{ span: 14, order: 2 }}
                  xl={{ span: 16, order: 2 }}
                  xxl={{ span: 18, order: 2 }}
                >
                  <Row gutter={[16, 16]}>
                    {slotsWithSpeaker.length === 0 &&
                      slotsWithNoSpeaker.length === 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 100,
                            paddingBottom: 100,
                          }}
                        >
                          <Empty description={strings.event.noSlots} />
                        </div>
                      )}
                    {eventSlots?.items.map((call, i) => {
                      let status = 'offline';

                      const speakersClient =
                        eventClients[call?.eventSpeaker?.speaker?.id];
                      if (speakersClient && !speakersClient.deletedAt) {
                        if (
                          speakersClient.location ===
                          `/stage/${match.params.eventId}`
                        ) {
                          status = speakersClient.windowVisible
                            ? 'online'
                            : 'hidden';
                        }
                      }

                      if (call.eventSpeakerId) {
                        const cardSpeaker = _.find(eventSpeakers.items, {
                          id: call.eventSpeakerId,
                        });
                        return (
                          <Col
                            xs={24}
                            sm={12}
                            md={24}
                            lg={12}
                            xl={8}
                            xxl={6}
                            key={call.id}
                          >
                            <SlotCard
                              slotId={i + 1}
                              id={call?.id}
                              slots={eventSlots?.items}
                              call={{
                                ...call,
                                name: `${cardSpeaker?.speaker?.firstName} ${cardSpeaker?.speaker?.lastName}`,
                                platform: speakersClient?.platform,
                                lastSeenAt: speakersClient?.updatedAt,
                                note: cardSpeaker?.note,
                                internetClickerEnabled:
                                  cardSpeaker?.internetClickerEnabled,
                                internetClickerId:
                                  cardSpeaker?.internetClickerId,
                                showNotes: cardSpeaker?.showNotes,
                                role: cardSpeaker?.role,
                                status,
                                isHandRaised: cardSpeaker?.isHandRaised,
                              }}
                              onSlotIdChanged={async (slotId) => {
                                const slotAlreadyTaken = _.find(
                                  eventSlots?.items,
                                  {
                                    id: slotId,
                                  }
                                );

                                if (slotAlreadyTaken.eventSpeakerId) {
                                  Modal.confirm({
                                    title:
                                      strings.event.slot.alreadyAllocated.title,
                                    content:
                                      strings.event.slot.alreadyAllocated
                                        .description,
                                    okText:
                                      strings.event.slot.alreadyAllocated
                                        .okText,
                                    cancelText:
                                      strings.event.slot.alreadyAllocated
                                        .cancelText,
                                    onOk: async () => {
                                      await updateSlot({
                                        id: slotId,
                                        eventSpeakerId: call.eventSpeakerId,
                                        vMixId: call.vMixId,
                                      });
                                      updateSlot({
                                        id: call.id,
                                        eventSpeakerId: null,
                                        vMixId: null,
                                      });
                                    },
                                  });
                                } else {
                                  await updateSlot({
                                    id: slotId,
                                    eventSpeakerId: call.eventSpeakerId,
                                    vMixId: call.vMixId,
                                  });
                                  updateSlot({
                                    id: call.id,
                                    eventSpeakerId: null,
                                    vMixId: null,
                                  });
                                }
                              }}
                              onVMixIdChanged={(vMixId) => {
                                addSlotWithVmixId({
                                  vMixId,
                                  eventSpeakerId: call.eventSpeaker.id,
                                  slotIdToUpdate: call.id,
                                });
                              }}
                              onMoveSpeakerToWaitingRoom={() => {
                                updateSlot({
                                  id: call.id,
                                  eventSpeakerId: null,
                                });
                              }}
                              onRemoveSpeakerFromEvent={() => {
                                updateSlot({
                                  id: call?.id,
                                  eventSpeakerId: null,
                                });
                                updateEventSpeaker({
                                  id: call?.eventSpeakerId,
                                  isFinished: true,
                                  finishedBy: 'team',
                                });
                              }}
                              onInternetClickerChanged={({
                                internetClickerEnabled,
                                internetClickerId,
                              }) => {
                                updateEventSpeaker({
                                  id: call?.eventSpeakerId,
                                  internetClickerEnabled,
                                  internetClickerId,
                                });
                              }}
                              onRoleChanged={(role) => {
                                updateEventSpeaker({
                                  id: call?.eventSpeakerId,
                                  role,
                                });
                              }}
                              onHandLowered={() => {
                                updateEventSpeaker({
                                  id: call?.eventSpeakerId,
                                  isHandRaised: false,
                                  handLoweredBy: 'team',
                                });
                              }}
                              onRefresh={async () => {
                                const credentials =
                                  await Auth.currentCredentials();

                                const iot = new AWS.Iot({
                                  region: 'eu-west-2',
                                  credentials:
                                    Auth.essentialCredentials(credentials),
                                });

                                const policyName = 'AOTV';
                                const target = credentials.identityId;

                                const { policies } = await iot
                                  .listAttachedPolicies({ target })
                                  .promise();

                                if (
                                  !policies.find(
                                    (policy) => policy.policyName === policyName
                                  )
                                ) {
                                  await iot
                                    .attachPolicy({ policyName, target })
                                    .promise();
                                }
                                const targetIndex = _.findIndex(
                                  eventSlots?.items,
                                  {
                                    id: call.id,
                                  }
                                );

                                PubSub.publish(
                                  `${eventSlots?.items[targetIndex]?.eventSpeaker?.speaker?.id}/refreshWindow`,
                                  {
                                    event: 'refresh',
                                  }
                                );
                              }}
                            />
                          </Col>
                        );
                      }

                      return (
                        <Col
                          xs={24}
                          sm={12}
                          md={24}
                          lg={12}
                          xl={8}
                          xxl={6}
                          key={call.id}
                        >
                          <SlotCardEmpty
                            id={call.id}
                            slotId={i + 1}
                            vMixId={call.vMixId}
                            slots={eventSlots?.items}
                            speakers={speakersNotAssignedToCall}
                            onVMixIdChanged={(vMixId) => {
                              addSlotWithVmixId({
                                vMixId,
                                eventSpeakerId: call?.eventSpeaker?.id,
                                slotIdToUpdate: call.id,
                              });
                            }}
                            onSpeakerChanged={(eventSpeakerId) => {
                              updateSlot({ id: call.id, eventSpeakerId });
                            }}
                            onRemoveCall={async () => {
                              await removeSlot(call.id);
                              message.success('Removed slot');
                            }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            )
          )}
        </PageHeader>
      </Content>
    </>
  );
};

EventScene.defaultProps = defaultProps;
export default EventScene;
