import React, {
  ReactElement,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  DatePicker,
  Select,
  Space,
  Button,
  Typography,
  Popconfirm,
  PageHeader,
  Dropdown,
  message,
  Popover,
  Spin,
  Menu,
} from 'antd';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-tomorrow';

import moment from 'moment';
import { CloseCircleOutlined, MailOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { addEvent, updateEvent, getEventById } from '../../methods/events';
import { getSpeakers } from '../../methods/speakers';
import { inviteSpeakerToEvent } from '../../methods/invite';

import './styles.less';
import PageHead from '../../components/PageHead';
import Bugsnag from '@bugsnag/js';
import RoleSelector from '../../components/RoleSelector';
import Modal from 'antd/lib/modal/Modal';
const { Option } = Select;
const { Text, Link } = Typography;
const { TextArea } = Input;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const EditableContext = React.createContext(null);

const EventsFormScene: React.FC<Props> = ({ match }: Props): ReactElement => {
  const isNew = match.params.eventId === 'new';

  const [loading, setLoading] = useState(!isNew);
  const [submitting, setSubmitting] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);
  const [newSpeakers, setNewSpeakers] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const [event, setEvent] = useState(null);

  const history = useHistory();

  const [form] = Form.useForm();

  const EditableRow = ({ index, ...props }) => {
    return (
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    );
  };

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    submitting,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);

    const [value, setValue] = useState(null);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const [speakerDataForm] = Form.useForm();

    useEffect(() => {
      if (editing) {
        inputRef.current.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    };

    const save = async () => {
      try {
        const values = await form.validateFields();
        toggleEdit();

        const targetSpeakerIndex = _.findIndex(
          form?.getFieldValue('speakers'),
          { id: record.id }
        );
        const newSpeakerItems = [...form?.getFieldValue('speakers')];

        newSpeakerItems[targetSpeakerIndex][dataIndex] = values[dataIndex];
        newSpeakerItems[targetSpeakerIndex].toUpdate = true;

        form.setFieldsValue({
          speakers: newSpeakerItems,
        });
      } catch (errInfo) {
        console.log('Save failed:', errInfo);
        Bugsnag.notify(errInfo);
      }
    };

    let childNode = children;

    if (editable) {
      childNode = editing ? (
        <Form.Item noStyle name={dataIndex} style={{ width: '100%' }}>
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            disabled={submitting}
            onChange={(e) => {
              if (dataIndex === 'internetClickerId') {
                form.setFieldsValue({
                  [dataIndex]: e.target.value.replace(/\D+/g, ''),
                });
              }
            }}
          />
        </Form.Item>
      ) : (
        <div className='editable-cell-value-wrap' onClick={toggleEdit}>
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };

  useEffect(() => {
    async function fetchEvent() {
      if (!isNew) {
        const targetEvent = await getEventById(match.params.eventId);
        form.setFieldsValue({
          ...targetEvent,
          speakers: targetEvent.speakers.items || [],
          date: targetEvent.date ? moment(targetEvent.date) : null,
          endDate: targetEvent.endDate ? moment(targetEvent.endDate) : null,
        });

        setEvent(targetEvent);
        setLoading(false);
      } else {
        form.setFieldsValue({ speakers: [] });
      }
      const speakers = await getSpeakers();
      setSpeakerList(speakers.items);
    }

    fetchEvent();
  }, []);

  // const children = [];
  // for (let i = 10; i < 36; i++) {
  //   children.push(
  //     <Option key={i.toString(36) + i}>
  //       tester tester {i.toString(36) + i}
  //     </Option>
  //   );
  // }

  const eventSpeakers = form?.getFieldValue('speakers') || [];

  const children = speakerList.reduce((list, speaker) => {
    const targetSpeaker = _.find(eventSpeakers, { speakerId: speaker.id });
    if (!targetSpeaker || targetSpeaker.toRemove ) {
      list.push(
        <Option
          key={speaker.id}
          value={speaker.id}
          label={`${speaker.firstName} ${speaker.lastName}`}
        >
          {speaker.firstName} {speaker.lastName}{' '}
          <span style={{ color: '#aaa' }}>
            - {speaker.username || speaker.email}
          </span>
        </Option>
      );
    }
    return list;
  }, []);

  const columns = [
    {
      title: 'Speaker',
      dataIndex: 'speaker',
      key: 'speaker',

      render: (text: string, record: any) => (
        <>
          <Text>
            {record.firstName} {record.lastName}
          </Text>
          <br />
          <Text type='secondary'>{record.email}</Text>
        </>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',

      render: (text: string, record: any) => (
        <>
          <RoleSelector
            defaultValue={record.role || 'speaker'}
            onRoleChanged={(role) => {
              const targetIndex = _.findIndex(form?.getFieldValue('speakers'), {
                id: record.id,
              });
              const newSpeakers = [...form?.getFieldValue('speakers')];
              newSpeakers[targetIndex].role = role;
              newSpeakers[targetIndex].toUpdate = true;
              form.setFieldsValue({ speakers: newSpeakers });
            }}
          />
        </>
      ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'note',
        title: 'Note',
      }),
    },
    {
      title: 'Clicker ID',
      dataIndex: 'internetClickerId',
      key: 'internetClickerId',
      width: 100,
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'internetClickerId',
        title: 'Clicker ID',
      }),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 235,
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'right' }}>
          <Space size='middle'>
            {!isNew && record.email && (
              <Popconfirm
                title={`Send Email Invite?`}
                okText='Yes'
                cancelText='No'
                onConfirm={async () => {
                  try {
                    await saveEvent();

                    await inviteSpeakerToEvent(record.speaker.speaker, event);
                    message.success(`Invite sent to ${record.email}`);
                  } catch (e) {
                    console.log('inviteSpeakerToEvent', e);
                    Bugsnag.notify(e);
                  }
                }}
              >
                <a>
                  <Space>
                    <MailOutlined />
                    Send Invite
                  </Space>
                </a>
              </Popconfirm>
            )}
            <Popconfirm
              title={`Remove Speaker from Event?`}
              okText='Yes'
              cancelText='No'
              onConfirm={() => {
                const updatedSpeakers = [];
                form?.getFieldValue('speakers').forEach((eventSpeaker) => {
                  if (eventSpeaker.id === record.id) {
                    if (!eventSpeaker.isNew) {
                      updatedSpeakers.push({ ...eventSpeaker, toRemove: true });
                    }
                  } else {
                    updatedSpeakers.push(eventSpeaker);
                  }
                });

                form?.setFieldsValue({ speakers: updatedSpeakers });

                setSpeakerList([...speakerList]);
              }}
            >
              <Link>
                <Space>
                  <CloseCircleOutlined />
                  Remove
                </Space>
              </Link>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  function handleChange(value) {
    const newValues = value.map((id) => {
      let item = _.find(speakerList, { id });
      return item;
    });

    setNewSpeakers(newValues);
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const saveEvent = async () => {
    const values = await form.validateFields();
    let speakersToAdd = [];
    let speakersToUpdate = [];
    let speakersToRemove = [];

    form.getFieldValue('speakers').forEach((speaker) => {
      if (speaker.isNew) {
        speakersToAdd.push(speaker);
      }
      if (speaker.toUpdate) {
        speakersToUpdate.push(speaker);
      }
      if (speaker.toRemove) {
        speakersToRemove.push(speaker);
      }
    });
    if (values.date) {
      values.date = new Date(values.date).toISOString();
    }

    await updateEvent({
      event: {
        id: match.params.eventId,
        ...values,
      },
      speakers: {
        add: speakersToAdd,
        update: speakersToUpdate,
        remove: speakersToRemove,
      },
    });

    const newValues = form
      .getFieldValue('speakers')
      .filter((o) => !o.toRemove)
      .map((item) => {
        delete item.isNew;
        delete item.toUpdate;
        return item;
      });

    form.setFieldsValue({ speakers: newValues });
  };

  const hasSelected = selectedRowKeys.length > 0;
  const waitingRoomEmbedContent = (
    <div>
      {/* <TextArea rows={8} bordered={false} /> */}
      <AceEditor
        placeholder='Paste Embed Code Here'
        mode='html'
        theme='tomorrow'
        name='embedCode'
        height='200px'
        // onLoad={this.onLoad}
        onChange={(embedCode) => {
          form.setFieldsValue({ embedCode });
          setEvent({ ...event, embedCode });
        }}
        showPrintMargin={false}
        showGutter={true}
        wrapEnabled={true}
        highlightActiveLine={true}
        value={event?.embedCode}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
        }}
      />
    </div>
  );
  return (
    <Spin spinning={loading}>
      <PageHeader
        ghost={false}
        style={{ maxWidth: 1400, margin: 'auto' }}
        title={isNew ? 'Add Event' : 'Update Event'}
        onBack={() => history.goBack()}
        extra={
          <Button
            type='primary'
            htmlType='submit'
            loading={submitting}
            disabled={submitting}
            form='form'
          >
            {isNew ? 'Add Event' : 'Update Event'}
          </Button>
        }
      >
        <PageHead title={isNew ? 'Add Event' : 'Update Event'} />
        <Form
          id='form'
          form={form}
          layout='vertical'
          onFinish={async (values) => {
            setSubmitting(true);
            if (isNew) {
              try {
                if (values.date) {
                  values.date = new Date(values.date).toISOString();
                }
                if (values.endDate) {
                  values.endDate = new Date(values.endDate).toISOString();
                }
                const id = uuidv4();
                await addEvent({
                  event: {
                    id,
                    ...values,
                  },
                  speakers: form.getFieldValue('speakers'),
                });
                history.replace(`/events/${id}`);
                message.success('Event Created');
              } catch (e) {
                console.log('add event erororor', e);
              }
            } else {
              try {
                await saveEvent();
                message.success('Event Updated');
              } catch (e) {
                console.log('udpate event erororor', e);
                Bugsnag.notify(e);
              }
            }
            setSubmitting(false);
          }}
        >
          <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
            <Col xs={24} sm={24} md={4} lg={4}>
              <Form.Item
                label='Start Date'
                name='date'
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: 'Required' }]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format='DD-MM-YYYY HH:mm'
                  showToday={false}
                  showNow={false}
                  style={{ width: '100%' }}
                  disabled={submitting}
                  onChange={(newDate) => {
                    if (
                      !form.getFieldValue('endDate') ||
                      newDate?.isSameOrAfter(form.getFieldValue('endDate'))
                    ) {
                      form.setFieldsValue({
                        endDate: newDate?.add(2, 'hours'),
                      });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4}>
              <Form.Item
                label='End Date'
                name='endDate'
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: 'Required' }]}
              >
                <DatePicker
                  showTime={{ format: 'HH:mm' }}
                  format='DD-MM-YYYY HH:mm'
                  showToday={false}
                  showNow={false}
                  style={{ width: '100%' }}
                  disabled={submitting}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item
                label='Event Name'
                name='title'
                style={{ marginBottom: 0 }}
                rules={[{ required: true, message: 'Required' }]}
              >
                <Input style={{ width: '100%' }} disabled={submitting} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <Form.Item
                label='Client'
                name='client'
                style={{ marginBottom: 0 }}
              >
                <Input style={{ width: '100%' }} disabled={submitting} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4}>
              <Form.Item
                label='Waiting Room'
                name='embedCode'
                style={{ marginBottom: 0 }}
              >
                <Popover
                  content={waitingRoomEmbedContent}
                  title='Embed Code'
                  trigger='click'
                  placement='bottom'
                >
                  {event?.embedCode ? (
                    <Button>Edit Embed Code</Button>
                  ) : (
                    <Button type='dashed'>No Embed Code</Button>
                  )}
                </Popover>
                {event?.embedCode && (
                  <Button
                    disabled={!event?.embedCode}
                    onClick={() => setIsPreviewModalVisible(true)}
                  >
                    Preview Embed
                  </Button>
                )}
                <Modal
                  title='Embed Preview'
                  visible={isPreviewModalVisible}
                  onOk={() => {
                    setIsPreviewModalVisible(false);
                  }}
                  onCancel={() => {
                    setIsPreviewModalVisible(false);
                  }}
                  footer={null}
                  width={610}
                >
                  <div dangerouslySetInnerHTML={{ __html: event?.embedCode }} />
                </Modal>
              </Form.Item>
            </Col>
          </Row>
          {!isNew && (
            <Row>
              <Col md={24} lg={24}>
                <Space
                  direction='vertical'
                  size='middle'
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.speakers !== currentValues.speakers
                    }
                  >
                    {({ getFieldValue }) => {
                      const dataSource = getFieldValue('speakers')
                        ?.filter((o) => !o.toRemove)
                        .map((speaker) => ({
                          id: speaker.id,
                          key: speaker.id,
                          speaker: speaker,
                          firstName: speaker.speaker.firstName,
                          lastName: speaker.speaker.lastName,
                          name: `${speaker.speaker.firstName} ${speaker.speaker.lastName}`,
                          email: speaker.speaker.email,
                          note: speaker.note,
                          internetClickerId: speaker.internetClickerId,
                          role: speaker.role,
                          isNew: speaker.isNew,
                          submitting,
                        }))
                        .sort(function (a, b) {
                          if (a.name < b.name) {
                            return -1;
                          }
                          if (a.name > b.name) {
                            return 1;
                          }
                          return 0;
                        });

                      const components = {
                        body: {
                          row: EditableRow,
                          cell: EditableCell,
                        },
                      };

                      return (
                        <Table
                          loading={
                            submitting
                              ? { spinning: true, indicator: null }
                              : false
                          }
                          rowClassName={(item) => {
                            return item.isNew ? 'isNew' : '';
                          }}
                          scroll={{ x: 'max-content' }}
                          dataSource={dataSource}
                          columns={columns}
                          pagination={false}
                          components={components}
                          rowSelection={rowSelection}
                        />
                      );
                    }}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div
                      style={{
                        // flex: 1,
                        display: 'flex',
                        marginRight: 16,
                        flexWrap: 'wrap',
                      }}
                    >
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.speakers !== currentValues.speakers
                        }
                      >
                        {({ getFieldValue }) => (
                          <>
                            <Select
                              mode='multiple'
                              value={newSpeakers.map((o) => o.id)}
                              dropdownMatchSelectWidth={false}
                              allowClear
                              style={{
                                flex: 1,
                                marginRight: 16,
                                minWidth: 300,
                                marginBottom: 16,
                              }}
                              placeholder='Select Speakers'
                              onChange={handleChange}
                              optionLabelProp='label'
                              optionFilterProp='label'
                              disabled={submitting}
                            >
                              {children}
                            </Select>
                            <Button
                              disabled={submitting}
                              onClick={() => {
                                form.setFieldsValue({
                                  speakers: _.uniqBy(
                                    [
                                      ...form.getFieldValue('speakers'),
                                      ...newSpeakers.map((speaker) => ({
                                        id: uuidv4(),
                                        speakerId: speaker.id,
                                        eventId: match.params.eventId,
                                        isNew: true,
                                        speaker,
                                      })),
                                    ],
                                    (o) => o.id
                                  ),
                                });

                                setNewSpeakers([]);
                              }}
                            >
                              Add Speakers
                            </Button>
                          </>
                        )}
                      </Form.Item>
                    </div>
                    <Space wrap>
                      {!isNew && (
                        <Popconfirm
                          title={`Send Email Invites?`}
                          okText='Yes'
                          cancelText='No'
                          onConfirm={async () => {
                            await saveEvent();
                            let usersWithNoEmail = false;
                            const allSent = selectedRowKeys.map(
                              async (eventSpeakerId) => {
                                const targetSpeaker = _.find(
                                  form?.getFieldValue('speakers'),
                                  { id: eventSpeakerId }
                                );

                                if (targetSpeaker) {
                                  await inviteSpeakerToEvent(
                                    targetSpeaker.speaker,
                                    event
                                  );

                                  usersWithNoEmail =
                                    !targetSpeaker.speaker.email;
                                }
                              }
                            );
                            await Promise.all(allSent);
                            let successMessage = `${
                              selectedRowKeys.length
                            } Invite${
                              selectedRowKeys.length > 1 ? 's' : ''
                            } Sent`;

                            if (usersWithNoEmail) {
                              successMessage = `Invite${
                                selectedRowKeys.length > 1 ? 's' : ''
                              } Sent to Speakers that have email addresses`;
                            }
                            message.success(successMessage);
                            setSelectedRowKeys([]);
                          }}
                        >
                          <Button type='primary' disabled={!hasSelected}>
                            Send Invites
                          </Button>
                        </Popconfirm>
                      )}
                      <span style={{ marginLeft: 8 }}>
                        {hasSelected
                          ? `Selected ${selectedRowKeys.length} items`
                          : ''}
                      </span>
                      <Popconfirm
                        title={`Remove Speakers from Event?`}
                        okText='Yes'
                        disabled={!hasSelected}
                        cancelText='No'
                        onConfirm={() => {
                          const updatedSpeakers = [];
                          form
                            ?.getFieldValue('speakers')
                            .forEach((eventSpeaker) => {
                              if (selectedRowKeys.includes(eventSpeaker.id)) {
                                if (!eventSpeaker.isNew) {
                                  updatedSpeakers.push({
                                    ...eventSpeaker,
                                    toRemove: true,
                                  });
                                }
                              } else {
                                updatedSpeakers.push(eventSpeaker);
                              }
                            });

                          form?.setFieldsValue({ speakers: updatedSpeakers });

                          setSelectedRowKeys([]);
                        }}
                      >
                        <Button danger disabled={!hasSelected}>
                          Remove Speakers
                        </Button>
                      </Popconfirm>
                    </Space>
                  </div>
                </Space>
              </Col>
            </Row>
          )}
        </Form>
      </PageHeader>
    </Spin>
  );
};

EventsFormScene.defaultProps = defaultProps;
export default EventsFormScene;
