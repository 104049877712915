import React, { ReactElement, useState, useEffect } from 'react';
import {
  Row,
  Col,
  Space,
  Table,
  Typography,
  Button,
  Popconfirm,
  message,
} from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { getEvents, removeEvent } from '../../methods/events';
import {
  onCreateClient,
  onDeleteClient,
  onUpdateClient,
  updatedClient,
} from '../../graphql/subscriptions';
import API from '@aws-amplify/api';
import PageHead from '../../components/PageHead';
import Auth from '@aws-amplify/auth';
import strings from '../../utils/strings';
import _ from 'lodash';
const { Title, Link: LinkComp, Text } = Typography;
/**
 * Description
 */

type Props = {};

const defaultProps = {};

const EventsScene: React.FC<Props> = ({}: Props): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState({ items: [], nextToken: null });
  const [removedEvents, setRemovedEvents] = useState([]);

  // listProfiles

  useEffect(() => {
    async function fetchEvents() {
      const creds = await Auth.currentCredentials();

      const data = await getEvents();

      setEvents(data);
      setLoading(false);
    }
    fetchEvents();
    const clientSubscription = API.graphql({
      query: updatedClient,
    }).subscribe({
      next: async ({ provider, value }) => {
        const data = await getEvents();
        setEvents(data);
      },
      error: (error) => console.warn(error),
    });

    return () => {
      clientSubscription.unsubscribe();
    };
  }, []);

  const dataSource = events?.items
    ?.filter((o) => !removedEvents.includes(o.id))
    .map((event) => ({
      key: event.id,
      date:
        event.date &&
        moment(event.date).calendar(null, {
          // sameDay: '[Today]',
          // nextDay: '[Tomorrow]',
          nextWeek: 'DD/MM/YYYY  [at] LT',
          // lastDay: '[Yesterday]',
          lastWeek: 'DD/MM/YYYY  [at] LT',
          sameElse: 'DD/MM/YYYY  [at] LT',
        }),
      event: event.title,
      client: event.client,
      numberOfSpeakers: event?.speakers?.items?.length ?? 0,
      numberOfWaitingRoom: event?.speakers?.items?.filter((o) => {
        if (o?.client?.items?.length > 0) {
          const orderedClients = o?.client?.items.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });

          let targetClient = _.find(
            orderedClients,
            (o) => o.location === `/waiting/${event.id}`
          );

          return targetClient && !targetClient.deletedAt;
        }

        return false;
      }).length,
      numberOfStage: event?.speakers?.items?.filter((o) => {
        if (o?.client?.items?.length > 0) {
          const orderedClients = o?.client?.items.sort((a, b) => {
            return (
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            );
          });

          let targetClient = _.find(
            orderedClients,
            (o) => o.location === `/stage/${event.id}`
          );

          return targetClient && !targetClient.deletedAt;
        }

        return false;
      }).length,
      id: event.id,
      sort: event.date ? new Date(event.date).getTime() : -1,
    }))
    .sort((a, b) => a.sort - b.sort);

  const columns = [
    {
      title: strings.events.columns.date,
      dataIndex: 'date',
      key: 'date',
      render: (text: string) =>
        text ? (
          <Text>{text}</Text>
        ) : (
          <Text type='secondary'>{strings.events.emptyDate}</Text>
        ),
    },
    {
      title: strings.events.columns.event,
      dataIndex: 'event',
      key: 'event',
      render: (text: string, record: any) => (
        <Link to={`/event/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: strings.events.columns.client,
      dataIndex: 'client',
      key: 'client',
      width: 200,
      render: (text: string) =>
        text ? (
          <Text>{text}</Text>
        ) : (
          <Text type='secondary'>{strings.events.emptyClient}</Text>
        ),
    },
    {
      title: strings.events.columns.speakers,
      dataIndex: 'numberOfSpeakers',
      key: 'numberOfSpeakers',
      width: 120,
    },
    {
      title: strings.events.columns.waiting,
      dataIndex: 'numberOfWaitingRoom',
      key: 'numberOfWaitingRoom',
      width: 120,
    },
    {
      title: strings.events.columns.stage,
      dataIndex: 'numberOfStage',
      key: 'numberOfStage',
      width: 120,
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 235,
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'right' }}>
          <Space size='middle'>
            <Link to={`/events/${record.id}`}>
              <LinkComp>
                <Space>
                  <EditOutlined />
                  {strings.events.edit}
                </Space>
              </LinkComp>
            </Link>
            <Popconfirm
              title={strings.events.removeEventConfirm.title}
              okText={strings.events.removeEventConfirm.okText}
              cancelText={strings.events.removeEventConfirm.cancelText}
              onConfirm={() => {
                setRemovedEvents([...removedEvents, record.id]);
                removeEvent(record);
                message.success(
                  strings.events.removeEventConfirm.successMessage
                );
              }}
            >
              <LinkComp>
                <Space>
                  <DeleteOutlined />
                  {strings.events.remove}
                </Space>
              </LinkComp>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];
  return (
    <>
      <PageHead title='Events' />
      <Row style={{ marginBottom: 8 }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title level={4}>{strings.events.pageTitle}</Title>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: 'right' }}
        >
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => {
              history.push('/events/new');
            }}
          >
            {strings.events.addEvent}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </>
  );
};

EventsScene.defaultProps = defaultProps;
export default EventsScene;
