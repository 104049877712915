import React, { ReactElement, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import HoldingMessageScene from '../HoldingMessageScene';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import Auth from '@aws-amplify/auth';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import AWS from 'aws-sdk';
import {  removeClient } from '../../methods/clients';
import Amplify from '@aws-amplify/core';
import { getInviteById } from '../../methods/invite';
import PageHead from '../../components/PageHead';
import { getSpeakerById } from '../../methods/speakers';
import SetPasswordScene from '../SetPasswordScene';

const { confirm } = Modal;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const InviteScene: React.FC = ({ match }): ReactElement => {
  let type = 'Speaker';

  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState(null);
  const [invite, setInvite] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      const session = await Auth.currentCredentials();

      let inviteItem;

      if (session.authenticated) {
        // check if speaker id in event

        const userDetails = await Auth.currentAuthenticatedUser();

        const { invite: inviteItemById } = await getInviteById(
          match.params.inviteId,
          type
        );

        inviteItem = inviteItemById;

        if (userDetails.signInUserSession.idToken.payload['cognito:username'] !== inviteItem.userId) {
          // logged in as different user

          confirm({
            title: 'Different User',
            icon: <ExclamationCircleOutlined />,
            okType: 'danger',
            content: `This will log you out of the current user and take you to to login page`,
            onOk: async () => {
              await removeClient();

              localStorage.removeItem('aotv:isTeamMember');
              localStorage.removeItem('aotv:isAdmin');

              await Auth.signOut();
              history.replace('/auth/login', { from: location.pathname });
            },
            onCancel() {
              window.close();
            },
          });
        } else {
          history.replace(`/waiting/${inviteItem.eventId}`);
        }
      } else {
        const { invite: inviteItem, user: userItem } = await getInviteById(
          match.params.inviteId,
          type
        );

        if (userItem.needsToSetPassword) {
          // set up password

          setUser(userItem);
          setInvite(inviteItem);
          setIsModalVisible(true);
        } else {
          //go to login
          history.replace('/auth/login', { from: location.pathname });
        }
      }
    };

    fetchEvent();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        height: '80%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PageHead title={'Processing Invite'} />
      <HoldingMessageScene
        title='Processing Invite'
        message='Please stay on this page.'
        isLoading
      />
      {isModalVisible && (
        <Modal visible closable={false} footer={null} width={700}>
          <SetPasswordScene
            user={user}
            type={type}
            onSetPassword={async (password) => {
              setIsModalVisible(false);

              const loggedInUser = await Auth.signIn({
                username: user.id,
                password,
              });

              localStorage.setItem(
                'aotv:userToken',
                loggedInUser.signInUserSession.accessToken.jwtToken
              );

              history.replace(`/waiting/${invite.eventId}`);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default InviteScene;
