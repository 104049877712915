import React, { ReactElement, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import {
  Form,
  Input,
  message,
  Card,
  Row,
  Button,
  Col,
  Typography,
  Checkbox,
  Modal,
} from 'antd';

import PageHead from '../../components/PageHead';
import { completeAccount } from '../../methods/speakers';

import Auth from '@aws-amplify/auth';
import passwordStrengthRule from '../../utils/passwordStrengthRule';

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const SetPasswordScene: React.FC = ({
  user,
  type,
  onSetPassword,
}): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const answeredGDPR = useRef(false);
  const [isDataModalVisible, setIsDataModalVisible] = useState(false);

  const showRemoveDataModal = () => {
    setIsDataModalVisible(true);
  };

  const hideRemoveDataModal = () => {
    setIsDataModalVisible(false);
  };

  const handleDataOk = () => {
    hideRemoveDataModal();
    form.submit();
  };
  const onFinish = async (values) => {
    setLoading(true);
    if (!answeredGDPR.current && !values.keepData) {
      showRemoveDataModal();
      answeredGDPR.current = true;
    } else {
      try {
        await completeAccount(
          user.id,
          values.password,
          type,
          values.keepData ? 1 : 0
        );

        setLoading(false);
        onSetPassword(values.password);
      } catch (e) {
        setLoading(false);
        message.error(e.message);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <PageHead title={'Set Password'} />
      <Row justify='center'>
        <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}>
          <img
            src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
            alt='AOTV Logo'
            width='200px'
          />
        </div>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ marginBottom: 32, textAlign: 'center' }}>
            {type === 'Speaker' && (
              <Text style={{ fontWeight: 700, fontSize: '1.4em' }}>
                Hi, {user.firstName} {user.lastName} <br />({user.email})
              </Text>
            )}
            {type === 'Speaker' && (
              <>
                <br />
                <br />
                <Text style={{ fontWeight: 400, fontSize: '1.4em' }}>
                  Almost there, just need to set a password.
                </Text>
              </>
            )}
          </div>
          <Card>
            <div style={{ marginBottom: 30 }}>
              <Title level={3} style={{ marginBottom: 0 }}>
                Set a Password
              </Title>
              <Text type='secondary'>
                Please choose a unique password specifically for AOTV.
              </Text>
            </div>
            <Form
              {...layout}
              form={form}
              name='basic'
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label='Password'
                name='password'
                rules={[passwordStrengthRule]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>

              <Form.Item
                label='Confirm Password'
                name='confirmPassword'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The two passwords that you entered do not match!'
                      );
                    },
                  }),
                ]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>
              <Form.Item
                {...tailLayout}
                name='keepData'
                valuePropName='checked'
              >
                <Checkbox disabled={loading}>
                  Keep my account for future events
                </Checkbox>
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={loading}
                  title='Create Account'
                >
                  Set Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        title='Are you sure?'
        visible={isDataModalVisible}
        onOk={handleDataOk}
        onCancel={() => {
          answeredGDPR.current = false;
          hideRemoveDataModal();
          setLoading(false);
        }}
        okText='Yes, remove my data after my event(s)'
        okButtonProps={{ type: 'primary', danger: true }}
      >
        <Text>
          If you choose not to keep your account for future events we will
          automatically remove it 7 days after your latest event.
        </Text>
      </Modal>
    </div>
  );
};

export default SetPasswordScene;
