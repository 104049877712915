import API from '@aws-amplify/api';
import {
  getEvent as getEventQuery,
  listEvents,
  slotByVmixId,
  eventsSpeakerBySpeakerId,
} from '../graphql/queries';

import { v4 as uuidv4 } from 'uuid';

import * as mutations from '../graphql/mutations';
import { updatedEvent, updatedClient } from '../graphql/subscriptions';
import _ from 'lodash';
import { updateEventSpeaker } from './eventSpeakers';
import Bugsnag from '@bugsnag/js';
import {
  createEventSpeakerCustomMut,
  createSlotCustomMut,
  deletedClientCustomSub,
  deletedEventSpeakerCustomSub,
  deletedSlotCustomSub,
  deleteEventSpeakerCustomMut,
  deleteSlotCustomMut,
  getCustomEvent,
  updatedClientCustomSub,
  updatedEventSpeakerCustomSub,
  updatedSlotCustomSub,
  updateEventSpeakerCustomMut,
  updateSlotCustomMut,
  updateEventCustomMut,
  createEventCustomMut,
  fetchEvents,
} from '../graphql/custom';

export const getEvents = async () => {
  const {
    data: { listEvents: response },
  } = await API.graphql({
    query: fetchEvents,
  });
  return {
    items: response.items.filter((o) => !o.deletedAt),
    nextToken: response.nextToken,
  };
};


export const getEventById = async (eventId) => {
  const {
    data: { getEvent: response },
  } = await API.graphql({
    query: getCustomEvent,
    variables: {
      id: eventId,
    },
  });

  return response;
};





export const getEventsBySpeakerId = async (speakerId) => {
  const {
    data: { eventsSpeakerBySpeakerId: response },
  } = await API.graphql({
    query: eventsSpeakerBySpeakerId,
    variables: {
      speakerId,
    },
  });
  return {
    items: response.items.filter(
      (o) =>
        !o.deletedAt &&
        (!o.isFinished || o.finishedBy === 'speaker') &&
        !o.event.deletedAt &&
        !o.event.isFinished
    ),
    nextToken: response.nextToken,
  };
};

export const subscribeToEvent = (
  eventId,
  {
    onEventChange,
    onSlotChanged,
    onSlotDeleted,
    onEventSpeakerChanged,
    onEventSpeakerDeleted,
    onClientChanged,
    onClientDeleted,
    onError,
  }
) => {
  Bugsnag.leaveBreadcrumb('subscribeToEvent');
  let eventSubscription;
  let slotSubscription;
  let slotDeletedSubscription;
  let eventSpeakerSubscription;
  let eventSpeakerDeletedSubscription;
  let clientSubscription;
  let clientDeletedSubscription;

  if (onEventChange) {
    eventSubscription = API.graphql({
      query: updatedEvent,
      variables: {
        id: eventId,
      },
    }).subscribe({
      next: ({ provider, value }) => onEventChange(value.data.updatedEvent),
      error: onError,
    });
  }

  if (onSlotChanged) {
    slotSubscription = API.graphql({
      query: updatedSlotCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) => onSlotChanged(value.data.updatedSlot),
      error: (error) => console.warn('onSlotChange error', error),
    });
  }
  if (onSlotDeleted) {
    slotDeletedSubscription = API.graphql({
      query: deletedSlotCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) => onSlotDeleted(value.data.deletedSlot),
      error: (error) => console.warn('onSlotDeleted error', error),
    });
  }

  if (onEventSpeakerChanged) {
    eventSpeakerSubscription = API.graphql({
      query: updatedEventSpeakerCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) =>
        onEventSpeakerChanged(value.data.updatedEventSpeaker),
      error: (error) => console.warn(error),
    });
  }

  if (onEventSpeakerDeleted) {
    eventSpeakerDeletedSubscription = API.graphql({
      query: deletedEventSpeakerCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) =>
        onEventSpeakerDeleted(value.data.deletedEventSpeaker),
      error: (error) => console.warn(error),
    });
  }

  if (onClientChanged) {
    clientSubscription = API.graphql({
      query: updatedClientCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) => onClientChanged(value.data.updatedClient),
      error: (error) => console.warn(error),
    });
  }
  if (onClientDeleted) {
    clientDeletedSubscription = API.graphql({
      query: deletedClientCustomSub,
      variables: {
        eventId,
      },
    }).subscribe({
      next: ({ provider, value }) => onClientDeleted(value.data.deletedClient),
      error: (error) => console.warn(error),
    });
  }

  return {
    unsubscribe: () => {
      onEventChange && eventSubscription.unsubscribe();
      onSlotChanged && slotSubscription.unsubscribe();
      onSlotDeleted && slotDeletedSubscription.unsubscribe();
      onEventSpeakerChanged && eventSpeakerSubscription.unsubscribe();
      onEventSpeakerDeleted && eventSpeakerDeletedSubscription.unsubscribe();
      onClientChanged && clientSubscription.unsubscribe();
      onClientDeleted && clientDeletedSubscription.unsubscribe();
    },
  };
};

export const addEvent = async ({ event, speakers }) => {
  
  const {
    data: { createEvent: response },
  } = await API.graphql({
    query: createEventCustomMut,
    variables: {
      input: event,
    },
  });

  if (speakers) {
    const addedSpeakers = speakers.map(async (speaker) => {
      await API.graphql({
        query: createEventSpeakerCustomMut,
        variables: {
          input: {
            id: speaker.id,
            note: speaker.note,
            internetClickerEnabled: speaker.internetClickerEnabled,
            internetClickerId: speaker.internetClickerId,
            showNotes: speaker.showNotes,
            role: speaker.role,
            eventId: event.id,
            speakerId: speaker.speakerId,
          },
        },
      });
    });

    await Promise.all(addedSpeakers);
  }

  // create slots here

  let i = 0;
  let slotsToBeAdded = [];

  const now = new Date();
  while (i < 16) {
    now.setMilliseconds(now.getMilliseconds()+1);
    slotsToBeAdded.push(
      addSlotToEvent({
        id: uuidv4(),
        eventId: event.id,
        createdAt: now.toISOString()
      })
    );
    i++;
  }

  await Promise.all(slotsToBeAdded);

  return response;
};

export const updateEvent = async ({ event, speakers = {} }) => {
  const {
    data: { updateEvent: response },
  } = await API.graphql({
    query: updateEventCustomMut,
    variables: {
      input: event,
    },
  });

  if (speakers.add) {
    const addedSpeakers = speakers.add.map(async (speaker) => {
      await API.graphql({
        query: createEventSpeakerCustomMut,
        variables: {
          input: {
            id: speaker.id,
            note: speaker.note,
            internetClickerEnabled: speaker.internetClickerEnabled,
            internetClickerId: speaker.internetClickerId,
            showNotes: speaker.showNotes,
            role: speaker.role,
            eventId: event.id,
            speakerId: speaker.speakerId,
          },
        },
      });
    });

    await Promise.all(addedSpeakers);
  }

  if (speakers.update) {
    const updatedSpeakers = speakers.update.map(async (speaker) => {
      await API.graphql({
        query: updateEventSpeakerCustomMut,
        variables: {
          input: {
            id: speaker.id,
            note: speaker.note,
            internetClickerEnabled: speaker.internetClickerEnabled,
            internetClickerId: speaker.internetClickerId,
            showNotes: speaker.showNotes,
            role: speaker.role,
            eventId: event.id,
            speakerId: speaker.speakerId,
          },
        },
      });
    });

    await Promise.all(updatedSpeakers);
  }

  if (speakers.remove) {
    const removedSpeakers = speakers.remove.map(async (eventSpeaker) => {
      await API.graphql({
        query: deleteEventSpeakerCustomMut,
        variables: {
          input: {
            id: eventSpeaker.id,
          },
        },
      });
    });

    await Promise.all(removedSpeakers);
  }

  return response;
};

export const removeEvent = async (event) => {
  const now = new Date().toISOString();
  const {
    data: { updateEvent: response },
  } = await API.graphql({
    query: updateEventCustomMut,
    variables: {
      input: { id: event.id, deletedAt: now, updatedAt: now },
    },
  });

  return response;
};

export const getSlotsWithVmixId = async (vMixId) => {
  const {
    data: { slotByVmixId: response },
  } = await API.graphql({
    query: slotByVmixId,
    variables: {
      vMixId,
    },
  });
  return {
    items: response.items.filter((o) => !o.deletedAt && !o.event.deletedAt),
    nextToken: response.nextToken,
  };
};

export const addSlotToEvent = async (slot) => {
  const {
    data: { createSlot: response },
  } = await API.graphql({
    query: createSlotCustomMut,
    variables: {
      input: slot,
    },
  });

  return response;
};


export const updateSlot = async (slot) => {
  const {
    data: { updateSlot: response },
  } = await API.graphql({
    query: updateSlotCustomMut,
    variables: {
      input: slot,
    },
  });

  return response;
};

export const removeSlot = async (id) => {
  const {
    data: { deleteSlot: response },
  } = await API.graphql({
    query: deleteSlotCustomMut,
    variables: {
      input: { id },
    },
  });

  return response;
};

export const getEventByVMixId = async (vMixId) => {
  const response = await API.post('AOTVRest', `/event/getByVMixId`, {
    body: { vMixId },
  });

  return response;
};

export const endEvent = async (eventId) => {
  const {
    data: { updateEvent: response },
  } = await API.graphql({
    query: mutations.updateEvent,
    variables: {
      input: { id: eventId, isFinished: true },
    },
  });

  const speakersFinished = response.speakers.items.map(async (speaker) => {
    await updateEventSpeaker({
      id: speaker.id,
      isFinished: true,
      finishedBy: 'team',
    });
  });

  const slotsFinished = response.slots.items.map(async (slot) => {
    if (slot.eventSpeaker) {
      updateSlot({
        id: slot?.id,
        eventSpeakerId: null,
      });
    }
  });

  await Promise.all(speakersFinished);
  await Promise.all(slotsFinished);
  return response;
};
