import React, { ReactElement, useState, useEffect, useRef } from 'react';

import { useHistory, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Modal,
  message,
  Card,
  Upload,
  Checkbox,
  Space,
  Row,
  Button,
  Col,
  Typography,
  Popconfirm,
  Spin,
} from 'antd';
import { InboxOutlined, HomeOutlined } from '@ant-design/icons';

import { Auth, API } from 'aws-amplify';
import PageHead from '../../components/PageHead';
import { removeAccount, getSpeakerById } from '../../methods/speakers';
import { updateSpeaker, updateTeamMember } from '../../graphql/mutations';
import { getTeamMemberById } from '../../methods/team';
import Bugsnag from '@bugsnag/js';

const { TextArea } = Input;
const { Title, Text, Link: LinkComp } = Typography;

/**
 * Description
 */

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const EditProfileScene: React.FC = (): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [profile, setProfile] = useState(false);

  const [removeLoading, setRemoveLoading] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [isDataModalVisible, setIsDataModalVisible] = useState(false);
  const [form] = Form.useForm();

  const answeredGDPR = useRef(false);

  useEffect(() => {
    const getProfileData = async () => {
      const userDetails = await Auth.currentAuthenticatedUser();
      let item;
      if (
        userDetails.signInUserSession.accessToken.payload[
          'cognito:groups'
        ]?.includes('team')
      ) {
        item = await getTeamMemberById(
          userDetails.signInUserSession.idToken.payload['cognito:username']
        );
      } else {
        item = await getSpeakerById(
          userDetails.signInUserSession.idToken.payload['cognito:username']
        );
      }

      form.setFieldsValue({
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        keepData: item.keepData === 1,
      });
      setProfile(item);
      setLoading(false);
    };
    getProfileData();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showRemoveDataModal = () => {
    setIsDataModalVisible(true);
  };

  const hideRemoveDataModal = () => {
    setIsDataModalVisible(false);
  };

  const handleOk = async () => {
    setRemoveLoading(true);

    await removeAccount();
    await Auth.signOut();
    setRemoveLoading(false);
    history.push('/auth/login');
    message.success('Account Removed');
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDataOk = () => {
    hideRemoveDataModal();
    form.submit();
  };

  const onFinish = async (values) => {
    setSaving(true);
    if (!answeredGDPR.current && !values.keepData) {
      showRemoveDataModal();
      answeredGDPR.current = true;
    } else {
      try {
        const userDetails = await Auth.currentAuthenticatedUser();

        let input = {
          id: userDetails.signInUserSession.idToken.payload['cognito:username'],
          firstName: values.firstName,
          lastName: values.lastName,
          keepData: values.keepData ? 1 : 0,
        };

        if (userDetails.attributes.email !== values.email) {
          // await Auth.updateUserAttributes(userDetails, {
          //   email: values.email,
          // });
          try {
            const response = await API.post(
              'AOTVRest',
              `/profile/change-email`,
              {
                body: {
                  username:
                    userDetails.signInUserSession.idToken.payload[
                      'cognito:username'
                    ],
                  email: values.email,
                  profileType:
                    userDetails.signInUserSession.accessToken.payload[
                      'cognito:groups'
                    ]?.includes('team')
                      ? 'TeamMember'
                      : 'Speaker',
                },
              }
            );

            form.setFieldsValue({ email: profile.email });
            setProfile({
              ...profile,
              emailWaitingVerification: { email: values.email },
            });
          } catch (e) {
            console.log(e);
            message.error(e.message);
            Bugsnag.notify(e);
          }
          // input.email = values.email;
        }

        let updateMethod = updateSpeaker;
        if (
          userDetails.signInUserSession.accessToken.payload[
            'cognito:groups'
          ]?.includes('team')
        ) {
          updateMethod = updateTeamMember;
        }

        await API.graphql({
          query: updateMethod,
          variables: {
            input,
          },
        });
        setSaving(false);

        message.success('Profile Updated');
      } catch (e) {
        console.log(e);
        setSaving(false);
        message.error(e.message);
        Bugsnag.notify(e);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <PageHead title='Edit Profile' />
      <div className='container'>
        <Row gutter={[32, 32]} justify='center'>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 20 }}
            md={{ span: 20 }}
            lg={{ span: 16 }}
            xl={{ span: 12 }}
            xxl={{ span: 10 }}
          >
            <Row gutter={[32, 32]}>
              <Col span={24}>
                <Spin spinning={loading}>
                  <Card>
                    <div style={{ marginBottom: 30 }}>
                      <Title level={3} style={{ marginBottom: 0 }}>
                        Edit Profile
                      </Title>
                      <Text type='secondary'>
                        Please provide accurate information.
                      </Text>
                    </div>
                    <Form
                      {...layout}
                      form={form}
                      name='basic'
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                      <Form.Item
                        label='First Name'
                        name='firstName'
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>

                      <Form.Item
                        label='Last Name'
                        name='lastName'
                        rules={[{ required: true, message: 'Required' }]}
                      >
                        <Input disabled={saving} />
                      </Form.Item>
                      <Form.Item
                        label='Email'
                        name='email'
                        rules={[
                          { required: true, message: 'Required' },
                          {
                            type: 'email',
                            message: 'Must be a valid email',
                            transform: (value) => value.toLowerCase().trim(),
                          },
                        ]}
                        style={
                          profile?.emailWaitingVerification?.email && {
                            marginBottom: 8,
                          }
                        }
                      >
                        <Input
                          inputMode='email'
                          autoCapitalize='off'
                          disabled={saving || profile.emailWaitingVerification}
                        />
                      </Form.Item>
                      {profile?.emailWaitingVerification?.email && (
                        <Form.Item {...tailLayout}>
                          <Text type='secondary' style={{ marginRight: 8 }}>
                            {profile.emailWaitingVerification.email} is awaiting
                            verification
                          </Text>
                          <Space>
                            <LinkComp
                              onClick={async () => {
                                message.loading(
                                  'Resending Email Verification',
                                  0
                                );

                                try {
                                  const userDetails =
                                    await Auth.currentAuthenticatedUser();
                                  const response = await API.post(
                                    'AOTVRest',
                                    `/profile/resend-verification`,
                                    {
                                      body: {
                                        username:
                                          userDetails.signInUserSession.idToken
                                            .payload['cognito:username'],
                                      },
                                    }
                                  );
                                  message.destroy();
                                  message.success('Email sent');
                                } catch (e) {
                                  message.destroy();
                                  message.error(e.message);
                                  Bugsnag.notify(e);
                                }
                              }}
                              title='Resend'
                            >
                              Resend
                            </LinkComp>
                            <Popconfirm
                              title={`Cancel Email Change?`}
                              okText='Yes'
                              onConfirm={async () => {
                                try {
                                  const userDetails =
                                    await Auth.currentAuthenticatedUser();

                                  await API.del(
                                    'AOTVRest',
                                    `/profile/cancel-email-change`,
                                    {
                                      body: {
                                        username:
                                          userDetails.signInUserSession.idToken
                                            .payload['cognito:username'],
                                      },
                                    }
                                  );
                                  setProfile({
                                    ...profile,
                                    emailWaitingVerification: null,
                                  });
                                } catch (e) {
                                  message.error(e.message);
                                  Bugsnag.notify(e);
                                }
                              }}
                              cancelText='No'
                            >
                              <LinkComp>Cancel</LinkComp>
                            </Popconfirm>
                          </Space>
                        </Form.Item>
                        //  <Form.Item label='Email waiting verification: '>
                        //     {profile.emailWaitingVerification.email}{' '}
                        //     <Button
                        //       onClick={async () => {
                        //         message.loading(
                        //           'Resending Email Verification',
                        //           0
                        //         );

                        //         try {
                        //           const response = await API.get(
                        //             'AOTVRest',
                        //             `/profile/resend-verification`
                        //           );
                        //           message.destroy();
                        //           message.success('Email sent');
                        //         } catch (e) {
                        //           message.destroy();
                        //           message.error(e.message);
                        //         }
                        //       }}
                        //       title='Resend'
                        //     >
                        //       Resend
                        //     </Button>
                        //     <Popconfirm
                        //       title={`Cancel Email Change?`}
                        //       okText='Yes'
                        //       onConfirm={async () => {
                        //         try {
                        //           await API.del(
                        //             'AOTVRest',
                        //             `/profile/cancel-email-change`
                        //           );
                        //           setProfile({
                        //             ...profile,
                        //             emailWaitingVerification: null,
                        //           });
                        //         } catch (e) {
                        //           message.error(e.message);
                        //         }
                        //       }}
                        //       cancelText='No'
                        //     >
                        //       <Button>Cancel</Button>
                        //     </Popconfirm>
                        //   </Form.Item>
                      )}
                      <Form.Item
                        {...tailLayout}
                        name='keepData'
                        valuePropName='checked'
                      >
                        <Checkbox disabled={saving}>
                          Keep my account for future events
                        </Checkbox>
                      </Form.Item>

                      <Form.Item {...tailLayout}>
                        <Button
                          type='primary'
                          htmlType='submit'
                          loading={saving}
                          title='Save Changes'
                          disabled={saving}
                        >
                          Save Changes
                        </Button>
                        <Popconfirm
                          title={`Completely delete your account?`}
                          okText='Yes'
                          onConfirm={showModal}
                          cancelText='No'
                        >
                          <Button
                            type='link'
                            danger
                            disabled={saving}
                            title='Delete Account'
                            style={{ float: 'right' }}
                          >
                            Delete Account
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    </Form>
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          title='Delete Account'
          visible={isModalVisible}
          onOk={handleOk}
          confirmLoading={removeLoading}
          onCancel={handleCancel}
          okText='I understand, completely delete my account'
          okButtonProps={{ type: 'primary', danger: true }}
        >
          <Text type='danger'>WARNING: This action cannot be undone.</Text>
          <br />
          <Text>
            In order to use this AOTV service again you will need to create a
            new account.
          </Text>
        </Modal>
        <Modal
          title='Are you sure?'
          visible={isDataModalVisible}
          onOk={handleDataOk}
          onCancel={() => {
            answeredGDPR.current = false;
            hideRemoveDataModal();
            setSaving(false);
          }}
          okText='Yes, remove my data after my event(s)'
          okButtonProps={{ type: 'primary', danger: true }}
        >
          <Text>
            If you choose not to keep your account for future events we will
            automatically remove it 7 days after your latest event.
          </Text>
        </Modal>
      </div>
    </>
  );
};

export default EditProfileScene;
