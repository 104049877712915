import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, notification, Typography, Tooltip } from 'antd';
import './style.css';
import {
  LeftOutlined,
  RightOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { updateEventSpeaker } from '../../methods/eventSpeakers';
const { Text } = Typography;

const InternetClicker: React.FC = ({ code, name, eventSpeakerId }): ReactElement => {
  const internetclicker = useRef();
  const cachedNotes = useRef();
  const [notes, setNotes] = useState(null);
  
  // temp "showNotes" - will read from props
  const [showNotes, setShowNotes] = useState(true);

  const [connecting, setConnecting] = useState(true);

  useEffect(() => {
    if (code && !internetclicker.current) {
      internetclicker.current = new IC.Connection();

      // internetclicker.current.on('userStatusChange', (payload) =>
      //   console.log(payload)
      // );

      internetclicker.current.on('connectError', (payload) => {
        notification.open({
          key: 'connectionStatus',
          type: 'error',
          message: <span>{payload.message}</span>,
        });
        cachedNotes.current = null;
        setNotes(null);
      });

      internetclicker.current.on('connectSuccess', (payload) => {
        setConnecting(false);
      });

      internetclicker.current.on('speakerNotesChange', (payload) => {
        if (payload.notes !== cachedNotes.current) {
          cachedNotes.current = payload.notes;

          setNotes(payload.notes.replace(/\r/g, '\n'));
        }
      });

      // internetclicker.current.on("userRemoved", (payload) => console.log(payload));
      internetclicker.current.on('connectionStateChanged', (payload) => {
        notification.open({
          key: 'connectionStatus',
          type: 'error',
          message: <span>Connection to presentation controls lost</span>,
        });
        cachedNotes.current = null;
        setNotes(null);
      });

      internetclicker.current.on('clientStateChanged', (payload) => {
        if (payload.state === 'closed') {
          notification.open({
            key: 'connectionStatus',
            type: 'error',
            message: <span>Connection to presentation controls lost</span>,
          });
          cachedNotes.current = null;
          setNotes(null);
        }

        if (payload.state === 'connected') {
          notification.open({
            key: 'connectionStatus',
            type: 'success',
            message: <span>Connected</span>,
          });
        }
      });

      internetclicker.current.start(code, name);

      // internetclicker.current.on('userMessageReceived', (payload) =>
      //   console.log(payload)
      // );
    }
  }, [code]);

  useEffect(() => {
    document.body.addEventListener('keydown', function (event) {
      if (event.keyCode === 37) {
        internetclicker.current.previous();
      }
      if (event.keyCode === 39) {
        internetclicker.current.next();
      }
    });

    return () => {
      if (internetclicker.current) {
        internetclicker.current.destroy();
      }
    };
  }, []);



  return (
    <>
      <div className="mobileNotes notes">
        {notes ? (
           <div
           style={{
             color: '#fff',
             fontSize: '1.2em',
             margin: 0,
             whiteSpace: 'pre-wrap',
           }}
           dangerouslySetInnerHTML={{ __html: notes }}
         />
        ) : (
          <p style={{ color: '#fff', fontStyle: 'italic', margin: 0 }}>
            No notes
          </p>
        )}
      </div>
      <div
        className="desktopBarHolder"
        style={{
          width: '100%',
          // pointerEvents: 'auto',
          // borderRadius: 8,
          // padding: '1em',
          // height: 250,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {connecting ? (
          <Text
            type="secondary"
            style={{
              padding: '1em',
              marginTop: '0.55em',
              marginBottom: '0.55em',
            }}
          >
            Connecting to presentation controls...
          </Text>
        ) : (
          <>
            <div
              style={{
                width: '100%',
                maxWidth: 1200,
                // minHeight: 100,
                // backgroundColor: '#222',
                pointerEvents: 'auto',
                paddingLeft: '0.7em',
                paddingRight: '0.7em',
                marginTop: '0.55em',

                marginBottom: '0.55em',
                display: 'flex',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                gap: 8,
              }}
            >
              <div style={{ flex: 1, textAlign: 'right' }}>
                <Button
                  className="slideButtons previousMobile"
                  size="large"
                  shape="round"
                  danger
                  type="primary"
                  style={{
                    background: '#d81b60',
                    border: '#d81b60',
                  }}
                  onClick={() => {
                    internetclicker.current.previous();
                  }}
                >
                  <LeftOutlined />
                  Prev Slide
                </Button>
                {/* <Tooltip title={showNotes ? 'Hide Notes' : 'Show Notes'}>*/}
                 <Button
                  shape="round"
                  size="large"
                  icon={showNotes ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                  onClick={() => {
                   setShowNotes(!showNotes);
                  }}
                >
                  {showNotes ? 'Hide Notes' : 'Show Notes'}
                </Button>
                {/*</Tooltip> */}
              </div>
               {showNotes && (
                <div style={{ flex: 6 }}>
                  <div className={`desktopNotes notes ${/<\/?[a-z][\s\S]*>/i.test(notes) ? 'includesHTML' : ''}`}>
                    {notes ? (
                      <div
                        style={{
                          color: '#fff',
                          fontSize: '1.2em',
                          margin: 0,
                          whiteSpace: 'pre-wrap',
                        }}
                        dangerouslySetInnerHTML={{ __html: notes }}
                      />
                        

                    ) : (
                      <p
                        style={{
                          color: '#fff',
                          fontStyle: 'italic',
                          margin: 0,
                        }}
                      >
                        No notes
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: showNotes ? 'column' : 'row-reverse',
                  justifyContent: showNotes ? 'flex-start' : 'flex-end',
                  alignItems: 'flex-start',
                }}
              >
                <Button
                  className="slideButtons nextDesktop"
                  size="large"
                  shape="round"
                  type="primary"
                  style={{
                    background: '#009e73',
                    border: '#009e73',
                  }}
                  onClick={() => {
                    internetclicker.current.next();
                  }}
                >
                  Next Slide
                  <RightOutlined />
                </Button>
                <Button
                  className="slideButtons previousDesktop"
                  size="large"
                  shape="round"
                  danger
                  type="primary"
                  style={{
                    background: '#d81b60',
                    border: '#d81b60',
                    marginTop: showNotes ? '0.5em' : 0,
                    marginRight: !showNotes ? '0.4em' : 0,
                  }}
                  onClick={() => {
                    internetclicker.current.previous();
                  }}
                >
                  <LeftOutlined />
                  Prev Slide
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default InternetClicker;
