import React, { ReactElement } from 'react';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const { Title, Text } = Typography;

/**
 * Description
 */

type Props = {
  title?: string;
  message?: string;
  isLoading?: boolean;
  shouldShowLogo?: boolean;
};

const defaultProps = {
  title: 'Waiting Room',
  message: 'The admin knows youre here',
  isLoading: true,
  shouldShowLogo: false,
};

const HoldingMessageScene: React.FC<Props> = ({
  title,
  message,
  isLoading,
  shouldShowLogo,
}: Props): ReactElement => (
  <div
    style={{
      display: 'flex',
      height: '80%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {shouldShowLogo && (
      <img
        src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
        alt='AOTV Logo'
        width='200px'
        style={{ marginBottom: 32 }}
      />
    )}
    {isLoading && (
      <LoadingOutlined style={{ fontSize: 72, marginBottom: 72 }} />
    )}

    {!!title && (
      <Title style={{ marginBottom: 12, textAlign: 'center' }}>{title}</Title>
    )}
    {!!message && (
      <Text
        style={{
          fontSize: 20,
          textAlign: 'center',
          maxWidth: 500,
        }}
      >
        {message}
      </Text>
    )}
  </div>
);

HoldingMessageScene.defaultProps = defaultProps;
export default HoldingMessageScene;
