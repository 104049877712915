import React, { ReactElement, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Modal,
  message,
  Card,
  Upload,
  Checkbox,
  Space,
  Row,
  Button,
  Col,
  Typography,
} from 'antd';
import { InboxOutlined, HomeOutlined } from '@ant-design/icons';

import { Auth, Storage } from 'aws-amplify';
import PageHead from '../../components/PageHead';
import passwordStrengthRule from '../../utils/passwordStrengthRule';
import Bugsnag from '@bugsnag/js';

const { TextArea } = Input;
const { Title, Text } = Typography;

/**
 * Description
 */

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const ResetPasswordScene: React.FC = ({
  match: {
    params: { token },
  },
}): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const parts = token.split('-');
  const code = parts.pop();
  const userName = parts.join('-');

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(userName, code, values.newPassword);

      setLoading(false);
      message.success('Password Reset');
      history.push('/auth/login');
    } catch (e) {
      setLoading(false);
      message.error(e.message);
      Bugsnag.notify(e);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onBusinessChange = (checked) => {
    setIsBusiness(checked);
  };

  return (
    <div className='container'>
      <PageHead title={'Reset Password'} />
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20 }}
          md={{ span: 20 }}
          lg={{ span: 16 }}
          xl={{ span: 12 }}
          xxl={{ span: 10 }}
        >
          <Row>
            <Col span={24}>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Set New Password
                  </Title>
                  <Text type='secondary'>
                    Please choose a unique password specifically for AOTV.
                  </Text>
                </div>
                <Form
                  {...layout}
                  form={form}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label='New Password'
                    name='newPassword'
                    rules={[passwordStrengthRule]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    label='Confirm Password'
                    name='confirmPassword'
                    dependencies={['newPassword']}
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            !value ||
                            getFieldValue('newPassword') === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            'The two passwords that you entered do not match!'
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                      title='Create Account'
                    >
                      Save New Password
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPasswordScene;
