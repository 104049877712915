import React, { ReactElement, useState, useRef } from 'react';

import { useHistory, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Modal,
  message,
  Card,
  Upload,
  Checkbox,
  Space,
  Row,
  Button,
  Col,
  Typography,
} from 'antd';
import { InboxOutlined, HomeOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

import { Auth, Storage } from 'aws-amplify';
import PageHead from '../../components/PageHead';
import passwordStrengthRule from '../../utils/passwordStrengthRule';
import Bugsnag from '@bugsnag/js';

const { TextArea } = Input;
const { Title, Text } = Typography;

/**
 * Description
 */

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const RegisterScene: React.FC = (): ReactElement => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fileList, updateFileList] = useState([]);
  const [locationLoading, setLocationLoading] = useState(false);
  const [form] = Form.useForm();

  const [isBusiness, setIsBusiness] = useState(false);

  const [keepDataChecked, setKeepDataChecked] = useState(false);
  const [isDataModalVisible, setIsDataModalVisible] = useState(false);
  const answeredGDPR = useRef(false);

  const onFinish = async (values) => {
    setLoading(true);

    if (!answeredGDPR.current && !values.keepData) {
      showRemoveDataModal();
      answeredGDPR.current = true;
    } else {
      try {
        values.email = values.email.toLowerCase().trim();
        const user = await Auth.signUp({
          username: uuidv4(),
          password: values.password,
          attributes: {
            email: values.email,
          },
          clientMetadata: {
            type: 'Speaker',
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            keepData: values.keepData ? '1' : '0',
          },
        });

        Bugsnag.leaveBreadcrumb('User Registered');

        // const user = await Auth.signIn({
        //   username: values.email,
        //   password: values.password,
        // });
        // storage.setItem('userToken', user.signInUserSession.accessToken.jwtToken);
        // storage.setItem('user', JSON.stringify(user));
        // store.store.dispatch(updateUserData(user));
        setLoading(false);

        Modal.success({
          title: 'Signed Up!',
          content: `Please verify your email`,
          onOk: () => {
            history.push('/auth/login');
          },
        });

        // message.success('Logged In');
        // history.push('/');
      } catch (e) {
        setLoading(false);

        if (e.message.includes('PreSignUp failed with error ')) {
          message.error(e.message.replace('PreSignUp failed with error ', ''));
        } else {
          message.error(e.message);
        }
        Bugsnag.notify(e);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onBusinessChange = (checked) => {
    setIsBusiness(checked);
  };

  const showRemoveDataModal = () => {
    setIsDataModalVisible(true);
  };

  const hideRemoveDataModal = () => {
    setIsDataModalVisible(false);
  };

  const handleDataOk = () => {
    hideRemoveDataModal();
    form.submit();
  };

  return (
    <div className='container'>
      <PageHead title={'Register'} />
      <Row justify='center'>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 20 }}
          lg={{ span: 16 }}
          xl={{ span: 12 }}
          xxl={{ span: 10 }}
        >
          <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}>
            <img
              src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
              alt='AOTV Logo'
              width='200px'
            />
          </div>
          <Row>
            <Col span={24}>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Create Account
                  </Title>
                  <Text type='secondary'>
                    Already have an account with AOTV?{' '}
                    <Link to='/auth/login'>Log in here</Link>
                  </Text>
                </div>
                <Form
                  {...layout}
                  form={form}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label='First Name'
                    name='firstName'
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    label='Last Name'
                    name='lastName'
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    label='Email'
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        required: true,
                        message: 'Required',
                        transform: (value) => value.toLowerCase().trim(),
                      },
                    ]}
                  >
                    <Input
                      inputMode='email'
                      autoCapitalize='off'
                      disabled={loading}
                    />
                  </Form.Item>

                  <Form.Item
                    label='Password'
                    name='password'
                    required
                    rules={[passwordStrengthRule]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    label='Confirm Password'
                    name='confirmPassword'
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            'The two passwords that you entered do not match!'
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>
                  <Form.Item
                    {...tailLayout}
                    name='keepData'
                    valuePropName='checked'
                  >
                    <Checkbox disabled={loading}>
                      Keep my account for future events
                    </Checkbox>
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                      title='Create Account'
                    >
                      Create Account
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        title='Are you sure?'
        visible={isDataModalVisible}
        onOk={handleDataOk}
        onCancel={() => {
          answeredGDPR.current = false;
          hideRemoveDataModal();
          setLoading(false);
        }}
        okText='Yes, remove my data after my event(s)'
        okButtonProps={{ type: 'primary', danger: true }}
      >
        <Text>
          If you choose not to keep your account for future events we will
          automatically remove it 7 days after your latest event.
        </Text>
      </Modal>
    </div>
  );
};

export default RegisterScene;
