/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSpeaker = /* GraphQL */ `
  query GetSpeaker($id: ID!) {
    getSpeaker(id: $id) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const listSpeakers = /* GraphQL */ `
  query ListSpeakers(
    $filter: ModelSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpeakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVerificationToken = /* GraphQL */ `
  query GetVerificationToken($id: ID!) {
    getVerificationToken(id: $id) {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const listVerificationTokens = /* GraphQL */ `
  query ListVerificationTokens(
    $filter: ModelVerificationTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVerificationTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSlot = /* GraphQL */ `
  query GetSlot($id: ID!) {
    getSlot(id: $id) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSlots = /* GraphQL */ `
  query ListSlots(
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSlots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventSpeakerId
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        vMixId
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventSpeaker = /* GraphQL */ `
  query GetEventSpeaker($id: ID!) {
    getEventSpeaker(id: $id) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listEventSpeakers = /* GraphQL */ `
  query ListEventSpeakers(
    $filter: ModelEventSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventSpeakers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      nextToken
    }
  }
`;
export const slotByEventSpeakerId = /* GraphQL */ `
  query SlotByEventSpeakerId(
    $eventSpeakerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotByEventSpeakerId(
      eventSpeakerId: $eventSpeakerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventSpeakerId
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        vMixId
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const slotByVmixId = /* GraphQL */ `
  query SlotByVmixId(
    $vMixId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSlotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotByVmixId(
      vMixId: $vMixId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventSpeakerId
        eventSpeaker {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        vMixId
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventsSpeakerBySpeakerId = /* GraphQL */ `
  query EventsSpeakerBySpeakerId(
    $speakerId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventSpeakerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsSpeakerBySpeakerId(
      speakerId: $speakerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        windowVisible
        location
        eventId
        platform {
          name
          version
          product
          manufacturer
          layout
          os
          description
        }
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        deletedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvite = /* GraphQL */ `
  query GetInvite($id: ID!) {
    getInvite(id: $id) {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const listInvites = /* GraphQL */ `
  query ListInvites(
    $filter: ModelInviteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        eventId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
