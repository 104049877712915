export default ({ getFieldValue }) => ({
  validator(rule, value) {
    const errors = [];

    if (value.length < 6) {
      errors.push('be at least 6 characters long');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('include an uppercase letter');
    }

    if (!/[a-z]/.test(value)) {
      errors.push('include a lowercase letter');
    }

    if (!/[0-9]/.test(value)) {
      errors.push('include a number');
    }

    // if (!/[^A-Za-z0-9 ]/.test(value)) {
    //   errors.push('include a special character (!?#)');
    // }

    if (errors.length > 0) {
      const formattedErrors = [
        'Your password must: \n',
        ...errors.map((errorItem, i) => {
          return `• ${errorItem}${i < errors.length - 1 ? '\n' : ''}`;
        }),
      ];
      return Promise.reject(formattedErrors);
    }
    return Promise.resolve();
  },
});
