import API from '@aws-amplify/api';

import * as mutations from '../graphql/mutations';
import _ from 'lodash';
import { EventSpeaker, UpdateEventSpeakerInput } from '../API';
import { updateEventSpeakerCustomMut } from '../graphql/custom';


export const updateEventSpeaker = async (eventSpeaker:UpdateEventSpeakerInput) => {

    const {
        data: { getEvent: response },
      } = await API.graphql({
      query: updateEventSpeakerCustomMut,
      variables: {
        input: eventSpeaker,
      },
    });

    return response;

}