
import _ from 'lodash'
import * as types from '../actions/actionTypes';

const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_EVENTS:
      return {
        ...state,
        list: _.uniqBy([...action.payload.events,...state.list], (event) => event.id),
      };

    default:
      return state;
  }
};
