/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatedEvent = /* GraphQL */ `
  subscription UpdatedEvent($id: ID!) {
    updatedEvent(id: $id) {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updatedSlot = /* GraphQL */ `
  subscription UpdatedSlot($eventId: ID!) {
    updatedSlot(eventId: $eventId) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletedSlot = /* GraphQL */ `
  subscription DeletedSlot($eventId: ID!) {
    deletedSlot(eventId: $eventId) {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatedEventSpeaker = /* GraphQL */ `
  subscription UpdatedEventSpeaker($eventId: ID!) {
    updatedEventSpeaker(eventId: $eventId) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deletedEventSpeaker = /* GraphQL */ `
  subscription DeletedEventSpeaker($eventId: ID!) {
    deletedEventSpeaker(eventId: $eventId) {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updatedClient = /* GraphQL */ `
  subscription UpdatedClient {
    updatedClient {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletedClient = /* GraphQL */ `
  subscription DeletedClient {
    deletedClient {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSpeaker = /* GraphQL */ `
  subscription OnCreateSpeaker($id: String) {
    onCreateSpeaker(id: $id) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpeaker = /* GraphQL */ `
  subscription OnUpdateSpeaker($id: String) {
    onUpdateSpeaker(id: $id) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpeaker = /* GraphQL */ `
  subscription OnDeleteSpeaker($id: String) {
    onDeleteSpeaker(id: $id) {
      id
      firstName
      lastName
      email
      username
      needsToSetPassword
      speakerEvents {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      keepData
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeamMember = /* GraphQL */ `
  subscription OnCreateTeamMember($id: String) {
    onCreateTeamMember(id: $id) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeamMember = /* GraphQL */ `
  subscription OnUpdateTeamMember($id: String) {
    onUpdateTeamMember(id: $id) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeamMember = /* GraphQL */ `
  subscription OnDeleteTeamMember($id: String) {
    onDeleteTeamMember(id: $id) {
      id
      firstName
      lastName
      email
      emailWaitingVerification {
        id
        token
        profileType
        email
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVerificationToken = /* GraphQL */ `
  subscription OnCreateVerificationToken {
    onCreateVerificationToken {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVerificationToken = /* GraphQL */ `
  subscription OnUpdateVerificationToken {
    onUpdateVerificationToken {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVerificationToken = /* GraphQL */ `
  subscription OnDeleteVerificationToken {
    onDeleteVerificationToken {
      id
      token
      profileType
      email
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSlot = /* GraphQL */ `
  subscription OnCreateSlot {
    onCreateSlot {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSlot = /* GraphQL */ `
  subscription OnUpdateSlot {
    onUpdateSlot {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSlot = /* GraphQL */ `
  subscription OnDeleteSlot {
    onDeleteSlot {
      id
      eventSpeakerId
      eventSpeaker {
        id
        note
        internetClickerEnabled
        internetClickerId
        showNotes
        role
        eventId
        event {
          id
          title
          date
          endDate
          client
          embedCode
          isFinished
          slots {
            items {
              id
              eventSpeakerId
              eventSpeaker {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              vMixId
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          speakers {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          createdAt
          updatedAt
          deletedAt
        }
        speakerId
        speaker {
          id
          firstName
          lastName
          email
          username
          needsToSetPassword
          speakerEvents {
            items {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            nextToken
          }
          emailWaitingVerification {
            id
            token
            profileType
            email
            createdAt
            updatedAt
          }
          keepData
          createdAt
          updatedAt
        }
        isFinished
        finishedBy
        isHandRaised
        handLoweredBy
        createdAt
        updatedAt
        client {
          items {
            id
            owner
            windowVisible
            location
            eventId
            platform {
              name
              version
              product
              manufacturer
              layout
              os
              description
            }
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            deletedAt
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      vMixId
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventSpeaker = /* GraphQL */ `
  subscription OnCreateEventSpeaker {
    onCreateEventSpeaker {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateEventSpeaker = /* GraphQL */ `
  subscription OnUpdateEventSpeaker {
    onUpdateEventSpeaker {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteEventSpeaker = /* GraphQL */ `
  subscription OnDeleteEventSpeaker {
    onDeleteEventSpeaker {
      id
      note
      internetClickerEnabled
      internetClickerId
      showNotes
      role
      eventId
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      speakerId
      speaker {
        id
        firstName
        lastName
        email
        username
        needsToSetPassword
        speakerEvents {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        emailWaitingVerification {
          id
          token
          profileType
          email
          createdAt
          updatedAt
        }
        keepData
        createdAt
        updatedAt
      }
      isFinished
      finishedBy
      isHandRaised
      handLoweredBy
      createdAt
      updatedAt
      client {
        items {
          id
          owner
          windowVisible
          location
          eventId
          platform {
            name
            version
            product
            manufacturer
            layout
            os
            description
          }
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          deletedAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      title
      date
      endDate
      client
      embedCode
      isFinished
      slots {
        items {
          id
          eventSpeakerId
          eventSpeaker {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          vMixId
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      speakers {
        items {
          id
          note
          internetClickerEnabled
          internetClickerId
          showNotes
          role
          eventId
          event {
            id
            title
            date
            endDate
            client
            embedCode
            isFinished
            slots {
              items {
                id
                eventSpeakerId
                eventSpeaker {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                vMixId
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            speakers {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            createdAt
            updatedAt
            deletedAt
          }
          speakerId
          speaker {
            id
            firstName
            lastName
            email
            username
            needsToSetPassword
            speakerEvents {
              items {
                id
                note
                internetClickerEnabled
                internetClickerId
                showNotes
                role
                eventId
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                speakerId
                speaker {
                  id
                  firstName
                  lastName
                  email
                  username
                  needsToSetPassword
                  keepData
                  createdAt
                  updatedAt
                }
                isFinished
                finishedBy
                isHandRaised
                handLoweredBy
                createdAt
                updatedAt
                client {
                  nextToken
                }
              }
              nextToken
            }
            emailWaitingVerification {
              id
              token
              profileType
              email
              createdAt
              updatedAt
            }
            keepData
            createdAt
            updatedAt
          }
          isFinished
          finishedBy
          isHandRaised
          handLoweredBy
          createdAt
          updatedAt
          client {
            items {
              id
              owner
              windowVisible
              location
              eventId
              platform {
                name
                version
                product
                manufacturer
                layout
                os
                description
              }
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              deletedAt
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient {
    onCreateClient {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient {
    onUpdateClient {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient {
    onDeleteClient {
      id
      owner
      windowVisible
      location
      eventId
      platform {
        name
        version
        product
        manufacturer
        layout
        os
        description
      }
      event {
        id
        title
        date
        endDate
        client
        embedCode
        isFinished
        slots {
          items {
            id
            eventSpeakerId
            eventSpeaker {
              id
              note
              internetClickerEnabled
              internetClickerId
              showNotes
              role
              eventId
              event {
                id
                title
                date
                endDate
                client
                embedCode
                isFinished
                slots {
                  nextToken
                }
                speakers {
                  nextToken
                }
                createdAt
                updatedAt
                deletedAt
              }
              speakerId
              speaker {
                id
                firstName
                lastName
                email
                username
                needsToSetPassword
                speakerEvents {
                  nextToken
                }
                emailWaitingVerification {
                  id
                  token
                  profileType
                  email
                  createdAt
                  updatedAt
                }
                keepData
                createdAt
                updatedAt
              }
              isFinished
              finishedBy
              isHandRaised
              handLoweredBy
              createdAt
              updatedAt
              client {
                items {
                  id
                  owner
                  windowVisible
                  location
                  eventId
                  deletedAt
                  createdAt
                  updatedAt
                }
                nextToken
              }
            }
            vMixId
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        speakers {
          items {
            id
            note
            internetClickerEnabled
            internetClickerId
            showNotes
            role
            eventId
            event {
              id
              title
              date
              endDate
              client
              embedCode
              isFinished
              slots {
                items {
                  id
                  eventSpeakerId
                  vMixId
                  eventId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              speakers {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
              deletedAt
            }
            speakerId
            speaker {
              id
              firstName
              lastName
              email
              username
              needsToSetPassword
              speakerEvents {
                items {
                  id
                  note
                  internetClickerEnabled
                  internetClickerId
                  showNotes
                  role
                  eventId
                  speakerId
                  isFinished
                  finishedBy
                  isHandRaised
                  handLoweredBy
                  createdAt
                  updatedAt
                }
                nextToken
              }
              emailWaitingVerification {
                id
                token
                profileType
                email
                createdAt
                updatedAt
              }
              keepData
              createdAt
              updatedAt
            }
            isFinished
            finishedBy
            isHandRaised
            handLoweredBy
            createdAt
            updatedAt
            client {
              items {
                id
                owner
                windowVisible
                location
                eventId
                platform {
                  name
                  version
                  product
                  manufacturer
                  layout
                  os
                  description
                }
                event {
                  id
                  title
                  date
                  endDate
                  client
                  embedCode
                  isFinished
                  createdAt
                  updatedAt
                  deletedAt
                }
                deletedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
        createdAt
        updatedAt
        deletedAt
      }
      deletedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInvite = /* GraphQL */ `
  subscription OnCreateInvite {
    onCreateInvite {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvite = /* GraphQL */ `
  subscription OnUpdateInvite {
    onUpdateInvite {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvite = /* GraphQL */ `
  subscription OnDeleteInvite {
    onDeleteInvite {
      id
      userId
      eventId
      createdAt
      updatedAt
    }
  }
`;
