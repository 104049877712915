import React, { ReactElement, useState, useEffect } from 'react';
import { Form, Input, Row, Col, Button, PageHeader, message, Spin } from 'antd';

import { v4 as uuidv4 } from 'uuid';

import { useHistory } from 'react-router-dom';

import './styles.less';
import PageHead from '../../components/PageHead';

import {
  addTeamMember,
  getTeamMemberById,
  updateTeamMember,
} from '../../methods/team';
import Bugsnag from '@bugsnag/js';

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const TeamFormScene: React.FC<Props> = ({ match }: Props): ReactElement => {
  const isNew = match.params.memberId === 'new';

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(!isNew);
  const [teamMember, setTeamMember] = useState(null);

  const history = useHistory();

  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchTeamMember() {
      let teamMemberItem;
      if (!isNew) {
        teamMemberItem = await getTeamMemberById(match.params.memberId);
        if (teamMemberItem) {
          setTeamMember(teamMemberItem);
          form.setFieldsValue(teamMemberItem);
          
        }
        setLoading(false);
      }
    }

    fetchTeamMember();
  }, []);

  const saveTeamMember = async () => {
    const values = await form.validateFields();

    await updateTeamMember({
      member: {
        id: match.params.memberId,
        ...values,
      },
    });
  };

  return (
    <Spin spinning={loading}>
    <PageHeader
      ghost={false}
      style={{ maxWidth: 1400, margin: 'auto' }}
      title={isNew ? 'Add Team Member' : 'Update Team Member'}
      onBack={() => history.goBack()}
      extra={
        <Button type='primary' htmlType='submit' loading={saving} form='form' disabled={saving}>
          {isNew ? 'Add Team Member' : 'Update Team Member'}
        </Button>
      }
    >
      <PageHead title={isNew ? 'Add Team Mamber' : 'Update Team Member'} />
      <Form
        id='form'
        form={form}
        layout='vertical'
        onFinish={async (values) => {
          setSaving(true);
          if (isNew) {
            try {
              const id = uuidv4();
              await addTeamMember({
                member: {
                  id,
                  ...values,
                },
              });
              setTeamMember({
                id,
                ...values,
              });
              setSaving(false);
              message.success('Team Member Created');
              history.push(`/team`);
            } catch (e) {
              setSaving(false);
              console.log('udpate event erororor', e);
              message.error(e.message || 'Error Creating Team Member');

              Bugsnag.notify(e);
            }
          } else {
            try {
              await saveTeamMember();
              setSaving(false);
              history.push('/team');
              message.success('Team Member Updated');
            } catch (e) {
              setSaving(false);
              message.error(e.message || 'Error Updatiung Team Member');
              console.log('udpate member erororor', e);
              Bugsnag.notify(e);
            }
          }
        }}
      >
        <Row gutter={[16, 16]} style={{ paddingBottom: 16 }}>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name='firstName'
              label='First Name'
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input style={{ width: '100%' }} disabled={saving} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name='lastName'
              label='Last Name'
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input style={{ width: '100%' }} disabled={saving} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <Form.Item
              name='email'
              label='Email'
              style={{ marginBottom: 0 }}
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input style={{ width: '100%' }} disabled={saving} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </PageHeader></Spin>
  );
};

TeamFormScene.defaultProps = defaultProps;
export default TeamFormScene;
