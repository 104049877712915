import React, { ReactElement, useState, useEffect } from 'react';
import {
  Card,
  Popover,
  Avatar,
  Space,
  Switch,
  Button,
  Tag,
  Input,
  Typography,
  Form,
  Dropdown,
  Select,
  Menu,
  Modal,
  Popconfirm,
  Tooltip,
  Divider,
  Checkbox,
} from 'antd';
import {
  NumberOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  ShakeOutlined,
  ReloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import strings from '../../utils/strings';
import RoleSelector from '../RoleSelector';
import Hand from '../Hand';
import moment from 'moment';
import LastSeenAt from '../LastSeenAt';
import { updateSlot } from '../../methods/events';
import _ from 'lodash';
const { confirm } = Modal;
const { Meta } = Card;
const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const colors = {
  online: '#87d068',
  offline: '#f6323a',
  windowHidden: 'orange',
};

type Call = {
  status: string;
  vMixId: number;
  name: string;
  email: string;
  note?: string | null | undefined;
  internetClickerEnabled: boolean | null | undefined;
  internetClickerId: string | null | undefined;
  showNotes?: boolean;
  role: string | null | undefined;
  platform: any;
};

type InternetClickerParams = {
  internetClickerEnabled: boolean;
  internetClickerId: string;
};
interface Props {
  id: string | null | undefined;
  slotId: number;
  slots: any;
  call: Call;
  onVMixIdChanged: (vMixId: string) => void;
  onSlotIdChanged: (slotId: string) => void;
  onMoveSpeakerToWaitingRoom: () => void;
  onRemoveSpeakerFromEvent: () => void;
  onInternetClickerChanged: (params: InternetClickerParams) => void;
  onRoleChanged: () => void;
  onHandLowered: () => void;
  onRefresh: () => void;
}

const statusColor = {
  offline: colors.offline,
  online: colors.online,
  hidden: colors.windowHidden,
};
const vmixStatusColor = {
  offline: 'error',
  online: 'success',
  hidden: colors.windowHidden,
};
const SlotCard: React.FC<Props> = ({
  id,
  slotId,
  call: {
    status,
    vMixId,
    name,
    email,
    note,
    internetClickerEnabled,
    internetClickerId,
    showNotes,
    role,
    platform,
    isHandRaised,
    lastSeenAt,
  },
  slots,
  onVMixIdChanged,
  onSlotIdChanged,
  onMoveSpeakerToWaitingRoom,
  onRemoveSpeakerFromEvent,
  onInternetClickerChanged,
  onRoleChanged,
  onHandLowered,
  onRefresh,
}): ReactElement => {
  const [vmixPopoverVisible, setVmixPopoverVisible] = useState(false);
  const [isAssignedToCallVisible, setIsAssignedToCallVisible] = useState(false);
  const [slotSearchValue, setSlotSearchValue] = useState(null);
  const [vMixIdForm] = Form.useForm();
  const [internetClickerIdForm] = Form.useForm();
  const [slotForm] = Form.useForm();

  const [internetClickerPopoverVisible, setInternetClickerPopoverVisible] =
    useState(false);

  const moveMenu = (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => {
          confirm({
            title: strings.event.slotCard.moveToWaitingPopUp.title,
            onOk() {
              onMoveSpeakerToWaitingRoom();
            },
            onCancel() {},
          });
        }}
      >
        {strings.event.slotCard.moveToWaitingPopUp.option}
      </Menu.Item>

      <Menu.Item
        key="1"
        onClick={() => {
          confirm({
            title: strings.event.slotCard.removePopUp.title,
            onOk() {
              onRemoveSpeakerFromEvent();
            },
            onCancel() {},
          });
        }}
      >
        {strings.event.slotCard.removePopUp.option}
      </Menu.Item>
    </Menu>
  );

  return (
    <Card
      actions={[
        <Popover
          placement="top"
          visible={internetClickerPopoverVisible}
          onVisibleChange={setInternetClickerPopoverVisible}
          content={
            <Form
              form={internetClickerIdForm}
              onFinish={(values) => {
                onInternetClickerChanged(values);
                setInternetClickerPopoverVisible(false);
              }}
              initialValues={{
                internetClickerEnabled,
                internetClickerId,
                showNotes
              }}
            >
              <Space>
                <Form.Item name={'internetClickerEnabled'} noStyle>
                  <Switch defaultChecked={internetClickerEnabled} />
                </Form.Item>
                <Form.Item
                  name={'internetClickerId'}
                  rules={[{ required: false, message: 'Required' }]}
                  noStyle
                >
                  <Input
                    defaultValue={internetClickerId}
                    style={{ width: 100 }}
                    onChange={(e) => {
                      internetClickerIdForm.setFieldsValue({
                        internetClickerId: e.target.value.replace(/\D+/g, ''),
                      });
                    }}
                    prefix={<NumberOutlined style={{ color: '#999' }} />}
                  />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  {strings.event.slotCard.internetClickerPopUp.buttonLabel}
                </Button>
              </Space>
              {/* <Space style={{ display: 'block', marginTop: 10 }}>
                <Form.Item name={'showNotes'} valuePropName="checked" noStyle initialValue={showNotes}>
                  <Checkbox>Show Notes</Checkbox>
                </Form.Item>
              </Space> */}
            </Form>
          }
          title={strings.event.slotCard.internetClickerPopUp.title}
          trigger="click"
          // visible={this.state.visible}
          // onVisibleChange={this.handleVisibleChange}
        >
          <Space
            key="clicker"
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <ShakeOutlined
              style={{
                color:
                  internetClickerEnabled && internetClickerId
                    ? colors.online
                    : colors.offline,
              }}
            />
            {/* <Text type='secondary'>#2222</Text> */}
          </Space>
        </Popover>,
        <Popconfirm
          title={strings.event.slotCard.refreshPopUp.title}
          okText={strings.event.slotCard.refreshPopUp.okText}
          cancelText={strings.event.slotCard.refreshPopUp.cancelText}
          onConfirm={onRefresh}
        >
          <ReloadOutlined key="reload" />
        </Popconfirm>,
        <Popover
          placement="top"
          content={
            <div>
              {platform && (
                <Text>
                  {platform.description}
                  <br />
                </Text>
              )}

              {status !== 'online' && (
                <Text type="secondary">
                  Last Seen: <LastSeenAt date={lastSeenAt} />
                </Text>
              )}
            </div>
          }
          title={strings.event.slotCard.platformPopUp.title}
          trigger="click"
          // visible={this.state.visible}
          // onVisibleChange={this.handleVisibleChange}
        >
          <InfoCircleOutlined key="info" />
        </Popover>,

        <Dropdown overlay={moveMenu} trigger={['click']}>
          <span
            className="ant-dropdown-link"
            style={{ display: 'inline-block', width: '100%' }}
          >
            <LogoutOutlined key="exit" />
          </span>
        </Dropdown>,
      ]}
    >
      <Meta
        avatar={
          <Popover
            visible={isAssignedToCallVisible}
            onVisibleChange={setIsAssignedToCallVisible}
            content={
              <Form
                form={slotForm}
                onFinish={(values) => {
                  setIsAssignedToCallVisible(false);
                  onSlotIdChanged(values.slotId);
                }}
              >
                <Space style={{ width: '100%' }}>
                  <Form.Item name={'slotId'} noStyle>
                    <Select
                      showSearch
                      placeholder={
                        strings.event.waitingRoom.allocatePopUp.selectLabel
                      }
                      searchValue={slotSearchValue}
                      optionFilterProp="children"
                      style={{ width: 120 }}
                      dropdownMatchSelectWidth={false}
                      onSearch={(text) => {
                        setSlotSearchValue(text.replace(/\D+/g, ''));
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div
                            style={{
                              display: 'flex',
                              flexWrap: 'nowrap',
                              padding: 8,
                            }}
                          >
                            <a
                              style={{
                                flex: 'none',
                                padding: '8px',
                                display: 'block',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                slotForm.submit();
                              }}
                            >
                              <PlusOutlined />{' '}
                              {
                                strings.event.waitingRoom.allocatePopUp
                                  .addSlotLink
                              }
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      <OptGroup
                        label={
                          strings.event.waitingRoom.allocatePopUp
                            .sectionEmptyTitle
                        }
                      >
                        {slots.reduce((list, slot, i) => {
                          if (!slot.eventSpeakerId) {
                            list.push(
                              <Option key={slot.id} value={slot.id}>
                                {i + 1}
                              </Option>
                            );
                          }

                          return list;
                        }, [])}
                      </OptGroup>

                      <OptGroup
                        label={
                          strings.event.waitingRoom.allocatePopUp
                            .sectionInUseTitle
                        }
                      >
                        {slots.reduce((list, slot, i) => {
                          if (slot.eventSpeakerId) {
                            list.push(
                              <Option key={slot.id} value={slot.id}>
                                {i + 1}
                              </Option>
                            );
                          }

                          return list;
                        }, [])}
                      </OptGroup>
                    </Select>
                  </Form.Item>
                  <Button htmlType="submit" type="primary">
                    {strings.event.slotCard.movePopUp.buttonLabel}
                  </Button>
                </Space>
              </Form>
            }
            title={strings.event.slotCard.movePopUp.title}
            trigger="click"
          >
            <Avatar
              size={32}
              style={{
                marginTop: 2,
                backgroundColor: statusColor[status],
                cursor: 'pointer',
              }}
            >
              <h5 style={{ color: '#fff' }}>{slotId}</h5>
            </Avatar>
          </Popover>
        }
        title={
          <Popover
            placement="top"
            visible={vmixPopoverVisible}
            onVisibleChange={setVmixPopoverVisible}
            content={
              <Form
                form={vMixIdForm}
                onFinish={(values) => {
                  onVMixIdChanged(values['vMixId']);
                  setVmixPopoverVisible(false);
                }}
              >
                <Space>
                  <Form.Item name={'vMixId'} noStyle>
                    <Input
                      defaultValue={vMixId}
                      onChange={(e) => {
                        if (e.target.value) {
                          vMixIdForm.setFieldsValue({
                            vMixId: e.target.value.replace(/\D+/g, ''),
                          });
                        } else {
                          vMixIdForm.setFieldsValue({
                            vMixId: null,
                          });
                        }
                      }}
                      style={{ width: 120 }}
                      prefix={<NumberOutlined style={{ color: '#999' }} />}
                    />
                  </Form.Item>
                  <Button type="primary" htmlType="submit">
                    {strings.event.slotCard.idPopUp.buttonLabel}
                  </Button>
                </Space>
              </Form>
            }
            title={strings.event.slotCard.idPopUp.title}
            trigger="click"
          >
            <Tag
              icon={vMixId ? <NumberOutlined /> : null}
              color={vmixStatusColor[status]}
              style={{ cursor: 'pointer', marginTop: 6 }}
            >
              {vMixId || 'Set vMix ID'}
            </Tag>
          </Popover>
        }
      />
      <div
        style={{
          height: 70,
        }}
      >
        <Title level={5} style={{ marginTop: 16, marginBottom: 0 }}>
          {name}
        </Title>
        <RoleSelector
          defaultValue={role || 'speaker'}
          onRoleChanged={onRoleChanged}
        />
        {note && note.length > 50 ? (
          <>
            <br />
            <Tooltip title={note} mouseEnterDelay={1}>
              <Text
                type="secondary"
                style={{
                  display: 'block',
                  paddingRight: isHandRaised ? 30 : 0,
                }}
              >
                {note.substr(0, 50) + '\u2026'}
              </Text>
            </Tooltip>
          </>
        ) : (
          <Text
            type="secondary"
            style={{ display: 'block', paddingRight: isHandRaised ? 30 : 0 }}
          >
            {note}
          </Text>
        )}
        <div
          style={{
            position: 'absolute',
            right: 20,
            bottom: 60,
          }}
        >
          {isHandRaised && <Hand onClick={onHandLowered} />}
        </div>
      </div>
    </Card>
  );
};

export default SlotCard;
