import React, { ReactElement, useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Space,
  Select,
  Table,
  Modal,
  Typography,
  Button,
  Popconfirm,
  Tag,
  Spin,
  message,
  Input,
} from 'antd';

import { Link, useHistory } from 'react-router-dom';

import SpeakersFormScene from '../SpeakersFormScene';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import './styles.less';
import { getSpeakers, removeSpeaker } from '../../methods/speakers';

import { getEvents } from '../../methods/events';
import PageHead from '../../components/PageHead';
const { Title } = Typography;
const { Option } = Select;

/**
 * Description
 */

type Props = {};

const defaultProps = {};

const SpeakersScene: React.FC<Props> = ({}: Props): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [speakers, setSpeakers] = useState({ items: [], nextToken: null });
  const [removedSpeakers, setRemovedSpeakers] = useState([]);
  const [filterQuery, setFilterQuery] = useState(null);

  const history = useHistory();

  // listProfiles

  useEffect(() => {
    async function fetchSpeakers() {
      const data = await getSpeakers();

      setSpeakers(data);
      setLoading(false);
    }
    fetchSpeakers();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const dataSource = speakers.items
    .filter(
      (o) =>
        !removedSpeakers.includes(o.id) &&
        (filterQuery
          ? `${o.firstName}${o.lastName}${o.email}${o.username}`
              .toLowerCase()
              .includes(filterQuery.toLowerCase())
          : true)
    )
    .map((speaker) => ({
      key: speaker.id,
      id: speaker.id,
      name: `${speaker.firstName} ${speaker.lastName}`,
      username: speaker.username,
      email: speaker.email,
      events: speaker.speakerEvents.items
        .filter((o) => !o.event?.deletedAt)
        .map(({ event }) => event.title),
    }))
    .sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
      render: (events: []) => (
        <>
          {events.map((event: string) => {
            return <Tag key={event}>{event.toUpperCase()}</Tag>;
          })}
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      width: 235,
      render: (text: string, record: any) => (
        <div style={{ textAlign: 'right' }}>
          <Space size="middle">
            <Link to={`/speakers/${record.id}`}>
              <Space>
                <EditOutlined />
                Edit
              </Space>
            </Link>
            <Popconfirm
              title={`Delete Speaker Account?`}
              okText="Yes"
              onConfirm={() => {
                setRemovedSpeakers([...removedSpeakers, record.id]);
                removeSpeaker(record);
                message.success('Speaker Removed');
              }}
              cancelText="No"
            >
              <Link>
                <Space>
                  <DeleteOutlined />
                  Remove
                </Space>
              </Link>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageHead title={'Speakers'} />

      <Row style={{ marginBottom: 8 }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title level={4}>Speakers</Title>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: 'right' }}
        >
          <Input
            style={{ width: 300, marginRight: 10 }}
            placeholder="Search"
            onChange={(e) => {
              const currValue = e.target.value;

              setFilterQuery(currValue);
            }}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              history.push('/speakers/new');
            }}
          >
            Add Speaker
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            scroll={{ x: 'max-content' }}
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </>
  );
};

SpeakersScene.defaultProps = defaultProps;
export default SpeakersScene;
