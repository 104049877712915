import * as EmailValidator from 'email-validator';

export default ({ getFieldValue }) => ({
  validator(rule, value) {

    if (!getFieldValue('email') && !getFieldValue('username')) {
      return Promise.reject('Email or username required');
    }

    if (rule.field === 'email' && !!value) {
      if (!EmailValidator.validate(value)) {
        return Promise.reject('Must be a valid email');
      }
    }
    return Promise.resolve();
  },
});
