import React, { ReactElement, useState } from 'react';
import {
  Popover,
  Form,
  Space,
  Popconfirm,
  Avatar,
  Button,
  Tag,
  Card,
  Input,
  Select,
} from 'antd';
import { NumberOutlined, DeleteOutlined } from '@ant-design/icons';
import { EventSpeaker, Slot } from '../../API';
import strings from '../../utils/strings';
const { Meta } = Card;

const { Option } = Select;

interface Props {
  id: string;
  slotId: number;
  vMixId: string;
  slots: Slot[];
  speakers: EventSpeaker[];
  onVMixIdChanged: (vMixId: string) => void;
  onSpeakerChanged: (speakerId: string) => void;
  onRemoveCall: () => void;
}

const SlotCard: React.FC<Props> = ({
  id,
  slotId,
  vMixId,
  slots,
  speakers = [],
  onVMixIdChanged = () => {},
  onSpeakerChanged = () => {},
  onRemoveCall = () => {},
}): ReactElement => {
  const [vmixIdPopoverVisible, setVmixIdPopoverVisible] = useState(false);
  const [vMixIdForm] = Form.useForm();

  return (
    <Card
      style={{
        backgroundColor: 'rgba(255,255,255,0)',
        position: 'relative',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#E5EAF0',
        height: '100%',

        minHeight: 174,
      }}
    >
      <Meta
        avatar={
          <Avatar
            size={32}
            style={{
              marginTop: 2,
              backgroundColor: '#E5EAF0',
            }}
          >
            <h5 style={{ color: '#0c3643' }}>{slotId}</h5>
          </Avatar>
        }
        title={
          <Popover
            placement='top'
            content={
              <Form
                form={vMixIdForm}
                onFinish={(values) => {
                  onVMixIdChanged(values[id + 'vMixId']);
                  setVmixIdPopoverVisible(false);
                }}
              >
                <Space>
                  <Form.Item name={id + 'vMixId'} noStyle>
                    <Input
                      defaultValue={vMixId}
                      onChange={(e) => {
                        if (e.target.value) {
                          vMixIdForm.setFieldsValue({
                            [id + 'vMixId']: e.target.value.replace(/\D+/g, ''),
                          });
                        } else {
                          vMixIdForm.setFieldsValue({
                            [id + 'vMixId']: null,
                          });
                        }
                      }}
                      style={{ width: 120 }}
                      prefix={<NumberOutlined style={{ color: '#999' }} />}
                    />
                  </Form.Item>
                  <Button type='primary' htmlType='submit'>
                    {strings.event.slotCard.idPopUp.buttonLabel}
                  </Button>
                </Space>
              </Form>
            }
            title={strings.event.slotCard.idPopUp.title}
            trigger='click'
            visible={vmixIdPopoverVisible}
            onVisibleChange={setVmixIdPopoverVisible}
          >
            <Tag
              icon={vMixId ? <NumberOutlined /> : null}
              style={{ cursor: 'pointer', marginTop: 6 }}
            >
              {vMixId || 'Set vMix ID'}
            </Tag>
          </Popover>
        }
      />

      {/* <div
        style={{
          position: 'relative',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#E5EAF0',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 22,
          minHeight: 174,
        }}
      > */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          padding: 22,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Popconfirm
          title={strings.event.slotCard.removePopUp.title}
          okText={strings.event.slotCard.removePopUp.okText}
          cancelText={strings.event.slotCard.removePopUp.cancelText}
          onConfirm={onRemoveCall}
        >
          <DeleteOutlined style={{ color: '#999' }} />
        </Popconfirm>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          // backgroundColor: 'green',
          paddingTop: 16,
        }}
      >
        <Form
          onFinish={(values) => {
            onSpeakerChanged(values[id + 'speakerId']);
          }}
          style={{
            width: '100%',
          }}
        >
          <div style={{ width: '100%', display: 'flex' }}>
            <Form.Item
              name={id + 'speakerId'}
              rules={[{ required: true, message: 'Required' }]}
              noStyle
              style={{ flex: 1 }}
            >
              <Select
                showSearch
                placeholder={strings.event.emptySlot.selectLabel}
                optionFilterProp='children'
                style={{ width: '100%', marginRight: 8 }}
              >
                {speakers.map((eventSpeaker) => (
                  <Option
                    value={eventSpeaker.id}
                    key={id + '-' + eventSpeaker.id}
                  >
                    {eventSpeaker?.speaker?.firstName}{' '}
                    {eventSpeaker?.speaker?.lastName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Button htmlType='submit'>
              {strings.event.emptySlot.buttonLabel}
            </Button>
          </div>
        </Form>
      </div>
      {/* </div> */}
    </Card>
  );
};

export default SlotCard;
