import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import {
  Button,
  Layout,
  Menu,
  Popconfirm,
  Result,
  Dropdown,
  Typography,
  message,
  Modal,
} from 'antd';
import EventScene from './scenes/EventScene';
import SpeakersScene from './scenes/SpeakersScene';
import EventsScene from './scenes/EventsScene';
import './App.less';
import LoginScene from './scenes/LoginScene';
import ForgotPasswordScene from './scenes/ForgotPasswordScene';
import config from './aws-exports';
import Amplify, { API, Auth, PubSub } from 'aws-amplify';
import RegisterScene from './scenes/RegisterScene';
import SetTeamPasswordScene from './scenes/SetTeamPasswordScene';
import EditProfileScene from './scenes/EditProfileScene';
import ChangePasswordScene from './scenes/ChangePasswordScene';
import ResetPasswordScene from './scenes/ResetPasswordScene';
import WaitingRoomScene from './scenes/WaitingRoomScene';
import InviteScene from './scenes/InviteScene';

import StageScene from './scenes/StageScene';
import SpeakerChooseEventScene from './scenes/SpeakerChooseEventScene';
import SpeakersFormScene from './scenes/SpeakersFormScene';
import HoldingMessageScene from './scenes/HoldingMessageScene';
import EventsFormScene from './scenes/EventsFormScene';

import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import { removeClient, upsertClient } from './methods/clients';
import TeamScene from './scenes/TeamScene';
import TeamFormScene from './scenes/TeamFormScene';
import shared from './config/shared';
import { UserOutlined } from '@ant-design/icons';
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import strings from './utils/strings';
const { Text } = Typography;
const { Header, Content } = Layout;

Amplify.configure(config);

Amplify.configure({
  ...config,
  Auth: {
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

shared.clientId = uuidv4();

// Apply plugin with configuration
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: 'eu-west-2',
    aws_pubsub_endpoint:
      'wss://a1ueb1cq772hva-ats.iot.eu-west-2.amazonaws.com/mqtt',
    clientId: shared.clientId,
  })
);

function PrivateRoute({ component, children, ...rest }) {
  const history = useHistory();

  useEffect(() => {
    const getUser = async () => {
      try {
        const userDetails = await Auth.currentCredentials();
        if (!userDetails.authenticated) {
          throw Error('Not logged in');
        }
        console.log('userDetails', userDetails);
      } catch (e) {
        console.log('ERROR!', e);
        localStorage.removeItem('aotv:userToken');
        localStorage.removeItem('aotv:isTeamMember');
        localStorage.removeItem('aotv:isAdmin');
        history.push('/auth/login');
      }
    };

    getUser();
  }, []);
  let isLoggedIn = null;

  let RouteComponent = component;

  if (!component) {
    RouteComponent = () => children();
  }

  isLoggedIn = localStorage.getItem('aotv:userToken');

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return isLoggedIn ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
}

const Verify = ({ match }) => {
  useEffect(() => {
    const action = async () => {
      message.loading('Verifying Email', 0);
      let response;
      console.log('match', match);
      try {
        if (match.params.newAccountToken) {
          const parts = match.params.newAccountToken.split('-');
          const code = parts.pop();
          const userName = parts.join('-');

          await Auth.confirmSignUp(userName, code);

          response = { email: 'Account' };
        } else {
          response = await API.post('AOTVRest', `/profile/verify-email`, {
            body: {
              token: match.params.token,
            },
          });
        }

        message.destroy();
        message.success(`${response.email} Verified`);
      } catch (e) {
        message.destroy();
        message.error(e.message);
        Bugsnag.notify(e);
      }
    };
    action();
  }, []);
  return <Redirect to='/' />;
};

const NotFound = () => {
  const history = useHistory();
  return (
    <Result
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Button
          type='primary'
          onClick={() => {
            history.push('/');
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

function HeaderWithRouter({ isAdmin }) {
  const location = useLocation();
  const history = useHistory();

  let defaultSelectedKey = 'events';
  const segs = location.pathname.split('/');
  if (segs[1] === 'profile') {
    defaultSelectedKey = segs[2];
  } else {
    defaultSelectedKey = segs[1];
  }

  const menu = (
    <Menu>
      {isAdmin && (
        <Menu.Item key='team'>
          <Link to='/team'>Team</Link>
        </Menu.Item>
      )}
      <Menu.Item key='edit'>
        <Link to='/profile/edit'>Edit Profile</Link>
      </Menu.Item>
      <Menu.Item key='change-password'>
        <Link to='/profile/change-password'>Change Password</Link>
      </Menu.Item>

      <Menu.Item
        key='3'
        onClick={ () => {
          Modal.confirm({
            title: strings.auth.logout.popUp.confirm.title,
            okText: strings.auth.logout.popUp.confirm.okText,
            cancelText: strings.auth.logout.popUp.confirm.cancelText,
            onOk:  () => {

              // use function inside to prevent spinner on "onOk" button
              const actionLogout = async () => {
                try {
                  await removeClient();
                } catch (e) {
                  console.log('remove client er', e);
                }
                localStorage.removeItem('aotv:userToken');
                localStorage.removeItem('aotv:isTeamMember');
                localStorage.removeItem('aotv:isAdmin');
      
                Auth.signOut();
                history.push('/auth/login');
              }
              actionLogout();
             
            },
          });
         
        }}
      >
        <div>
          <Text type='secondary'>Logout</Text>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className='header'>
      <Link to='/events' className='logoHolder'>
        <img
          src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
          alt='AOTV Logo'
          height='100%'
          className='logo'
        />
        <img
          src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-icon-logo.png'
          alt='AOTV Logo'
          height='50%'
          className='mobileLogo'
        />
      </Link>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[defaultSelectedKey]}
        className='mainNav'
      >
        <Menu.Item key='events'>
          <Link to='/events'>Events</Link>
        </Menu.Item>
        <Menu.Item key='speakers'>
          <Link to='/speakers'>Speakers</Link>
        </Menu.Item>
        <Dropdown overlay={menu} placement='bottomLeft' arrow>
          <UserOutlined style={{ fontSize: 20, paddingLeft: 12 }} />
        </Dropdown>
      </Menu>
    </Header>
  );
}
function SpeakerHeaderWithRouter() {
  const history = useHistory();

  let defaultSelectedKey = 'choose';
  const segs = location.pathname.split('/');
  if (segs[1] === 'profile') {
    defaultSelectedKey = segs[2];
  }
  const menu = (
    <Menu>
      <Menu.Item key='edit'>
        <Link to='/profile/edit'>Edit Profile</Link>
      </Menu.Item>
      <Menu.Item key='change-password'>
        <Link to='/profile/change-password'>Change Password</Link>
      </Menu.Item>

      <Menu.Item
        key='3'
        onClick={ () => {
          Modal.confirm({
            title: strings.auth.logout.popUp.confirm.title,
            okText: strings.auth.logout.popUp.confirm.okText,
            cancelText: strings.auth.logout.popUp.confirm.cancelText,
            onOk:  () => {

              // use function inside to prevent spinner on "onOk" button
              const actionLogout = async () => {
                try {
                  await removeClient();
                } catch (e) {
                  console.log('remove client er', e);
                }
                localStorage.removeItem('aotv:userToken');
                localStorage.removeItem('aotv:isTeamMember');
                localStorage.removeItem('aotv:isAdmin');
      
                Auth.signOut();
                history.push('/auth/login');
              }
              actionLogout();
             
            },
          });
         
        }}
      >
        <div>
          <Text type='secondary'>Logout</Text>
        </div>
      </Menu.Item>


      
    </Menu>
  );
  return (
    <Header className='header'>
      <Link to='/choose' className='logoHolder'>
        <img
          src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
          alt='AOTV Logo'
          height='100%'
          className='logo'
        />
        <img
          src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-icon-logo.png'
          alt='AOTV Logo'
          height='50%'
          className='mobileLogo'
        />
      </Link>
      <Menu
        mode='horizontal'
        defaultSelectedKeys={[defaultSelectedKey]}
        className='mainNav'
      >
        <Menu.Item key='choose'>
          <Link to='/choose'>Events</Link>
        </Menu.Item>
        <Dropdown overlay={menu} placement='bottomLeft' arrow>
          <UserOutlined style={{ fontSize: 20, paddingLeft: 12 }} />
        </Dropdown>
        {/* <Menu.Item key='edit'>
          <Link to='/profile/edit'>Edit Profile</Link>
        </Menu.Item>
        <Menu.Item key='change-password'>
          <Link to='/profile/change-password'>Change Password</Link>
        </Menu.Item>
        <Menu.Item key='4'>
          <Popconfirm
            title={`Are you sure?`}
            okText='Yes'
            cancelText='No'
            onConfirm={async () => {
              localStorage.removeItem('aotv:userToken');
              localStorage.removeItem('aotv:isTeamMember');
              localStorage.removeItem('aotv:isAdmin');

              await removeClient();

              Auth.signOut();
              history.push('/auth/login');
            }}
          >
            Logout
          </Popconfirm>
        </Menu.Item> */}
      </Menu>
    </Header>
  );
}

function App() {
  const location = useLocation();
  let clientPrepped = false;

  const prepClient = async () => {
    if (!clientPrepped) {
      const userDetails = await Auth.currentAuthenticatedUser();
      const credentials = await Auth.currentCredentials();

      document.addEventListener('visibilitychange', () => {
        upsertClient();
      });

      // document.removeEventListener('visibilitychange', )

      Bugsnag.setUser(
        userDetails.signInUserSession.idToken.payload['cognito:username'],
        userDetails.attributes.preferred_username ||
          userDetails.attributes.email
      );

      if (
        !userDetails.signInUserSession.accessToken.payload['cognito:groups'] ||
        userDetails.signInUserSession.accessToken.payload[
          'cognito:groups'
        ]?.includes('speakers')
      ) {
        const iot = new AWS.Iot({
          region: 'eu-west-2',
          credentials: Auth.essentialCredentials(credentials),
        });

        const policyName = 'AOTV';
        const target = credentials.identityId;

        const { policies } = await iot
          .listAttachedPolicies({ target })
          .promise();

        if (!policies.find((policy) => policy.policyName === policyName)) {
          await iot.attachPolicy({ policyName, target }).promise();
        }

        try {
          const stuff = await PubSub.publish(
            'connectedWaitingRoom',
            {
              event: 'connected',
              userId:
                userDetails.signInUserSession.idToken.payload[
                  'cognito:username'
                ],
            },
            { provider: 'AWSIoTProvider' }
          );

          clientPrepped = true;
        } catch (e) {
          console.log('PUBLISH ERROR', e);
          Bugsnag.notify(e);
        }
      }
    }
  };

  React.useEffect(() => {
    const clientSetup = async () => {
      if (location.pathname !== '/auth/login') {
        Bugsnag.leaveBreadcrumb('Set up client', {
          MetaData: { clientId: shared.clientId },
        });

        try {
          const userDetails = await Auth.currentAuthenticatedUser();

          if (
            !userDetails.signInUserSession.accessToken.payload[
              'cognito:groups'
            ] ||
            userDetails.signInUserSession.accessToken.payload[
              'cognito:groups'
            ]?.includes('speakers')
          ) {
            await prepClient();
            await upsertClient(location.pathname);
          }
        } catch (e) {
          console.log('client error', e);
          const userDetails = await Auth.currentAuthenticatedUser();
          const payload = {
            blocks: [
              {
                type: 'section',
                text: {
                  type: 'plain_text',
                  text: 'Error upserting client',
                },
              },
              {
                type: 'divider',
              },
              {
                type: 'context',
                elements: [
                  {
                    type: 'mrkdwn',
                    text: `*ClientId:* ${shared.clientId}`,
                  },
                  {
                    type: 'mrkdwn',
                    text: `*UserId:* ${userDetails.signInUserSession.idToken.payload['cognito:username']}`,
                  },
                ],
              },
            ],
          };
          const options = {
            method: 'post',
            baseURL: `https://hooks.slack.com/services/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`,
            headers: {
              'Content-Type':
                'application/x-www-form-urlencoded; charset=UTF-8',
            },
            data: payload,
          };
          // await axios.request(options);

          Bugsnag.notify(e);
        }
      }
    };
    clientSetup();
  }, [location]);

  return (
    <Layout className='layout'>
      <Switch>
        <Route path='/verify-email/:newAccountToken' exact component={Verify} />
        <Route path='/auth'>
          <Route path='/auth/verify/:token' exact component={Verify} />

          <Route path='/auth/login' exact component={LoginScene} />
          <Route
            path='/auth/set-password'
            exact
            component={SetTeamPasswordScene}
          />
          <Route path='/auth/register' exact component={RegisterScene} />
          <Route path='/auth/forgot' exact component={ForgotPasswordScene} />
          <Route
            path='/auth/reset/:token'
            exact
            component={ResetPasswordScene}
          />
        </Route>

        <PrivateRoute path='/event/:eventId' exact component={EventScene} />

        <Route path='/invite/:inviteId' exact component={InviteScene} />
        <PrivateRoute
          path='/waiting/:eventId'
          exact
          component={WaitingRoomScene}
        />
        <PrivateRoute path='/stage/finished' exact>
          {() => (
            <HoldingMessageScene
              title='Finished'
              message='Thank you for speaking. Please close this window.'
              isLoading={false}
            />
          )}
        </PrivateRoute>
        <PrivateRoute path='/stage/:eventId' exact component={StageScene} />
        <Route path='/tester'>{() => testerItem()}</Route>
        <PrivateRoute path='/'>
          {() => {
            const isTeamMember = localStorage.getItem('aotv:isTeamMember');
            const isAdmin = localStorage.getItem('aotv:isAdmin');

            return (
              <>
                {isTeamMember ? (
                  <HeaderWithRouter isAdmin={isAdmin} />
                ) : (
                  <SpeakerHeaderWithRouter />
                )}

                <Content className='contentlayout'>
                  <Switch>
                    {isTeamMember && (
                      <PrivateRoute
                        path='/events'
                        exact
                        component={EventsScene}
                      />
                    )}
                    {isTeamMember && (
                      <PrivateRoute
                        path='/events/:eventId'
                        exact
                        component={EventsFormScene}
                      />
                    )}

                    {isTeamMember && (
                      <PrivateRoute
                        path='/speakers'
                        exact
                        component={SpeakersScene}
                      />
                    )}
                    {isTeamMember && (
                      <PrivateRoute
                        path='/speakers/:speakerId'
                        exact
                        component={SpeakersFormScene}
                      />
                    )}

                    {isAdmin && (
                      <PrivateRoute path='/team' exact component={TeamScene} />
                    )}

                    {isAdmin && (
                      <PrivateRoute
                        path='/team/:memberId'
                        exact
                        component={TeamFormScene}
                      />
                    )}

                    {/* <Route path='/waiting'>
                <HoldingMessageScene
                  title='Waiting Room'
                  message='Please stay on this page. The event Director will let you in to the Green Room shortly.'
                  isLoading
                />
              </Route> */}

                    {!isTeamMember && (
                      <PrivateRoute
                        path='/choose'
                        exact
                        component={SpeakerChooseEventScene}
                      />
                    )}

                    <PrivateRoute
                      path='/profile/change-password'
                      exact
                      component={ChangePasswordScene}
                    />
                    <PrivateRoute
                      path='/profile/edit'
                      exact
                      component={EditProfileScene}
                    />

                    <PrivateRoute
                      path='/'
                      exact
                      component={
                        isTeamMember ? EventsScene : SpeakerChooseEventScene
                      }
                    />

                    <Route path='*'>
                      <NotFound />
                    </Route>
                  </Switch>
                </Content>
              </>
            );
          }}
        </PrivateRoute>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
