import React, { ReactElement, useState } from 'react';
import { Dropdown, Menu } from 'antd';

import { DownOutlined } from '@ant-design/icons';

interface Props {
  // id: string;
  defaultValue: String;
  onRoleChanged: (roleId: string) => void;
}

const RoleSelector: React.FC<Props> = ({
  // id,
  defaultValue = 'speaker',
  onRoleChanged = () => {},
}): ReactElement => {
  const [selectedKey, setSelectedKey] = useState(defaultValue);

  const items = {
    speaker: 'Speaker',
    panelist: 'Panelist',
    moderator: 'Moderator',
  };

  const menu = (
    <Menu
      onClick={({ key }) => {
        setSelectedKey(key);
        onRoleChanged(key);
      }}
      selectedKeys={[selectedKey]}
    >
      <Menu.Item key='speaker'>Speaker</Menu.Item>
      <Menu.Item key='panelist'>Panelist</Menu.Item>
      <Menu.Item key='moderator'>Moderator</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <a className='roleDropDownLabel' onClick={(e) => e.preventDefault()}>
        {items[selectedKey]} <DownOutlined />
      </a>
    </Dropdown>
  );
};

export default RoleSelector;
