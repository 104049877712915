import React, { ReactElement, useState, useEffect } from 'react';

import { useHistory, useLocation, Link } from 'react-router-dom';
import {
  message,
  Form,
  Input,
  Space,
  Row,
  Col,
  Typography,
  Button,
  Card,
} from 'antd';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import PageHead from '../../components/PageHead';
import SetTeamPasswordScene from '../SetTeamPasswordScene';
import Bugsnag from '@bugsnag/js';

const { Title, Text } = Typography;
/**
 * Description
 */

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};
const tailLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16, offset: 6 },
    md: { span: 16, offset: 6 },
  },
};

const LoginScene: React.FC = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [showSetPassword, setShowSetPassword] = useState(false);
  const [user, setUser] = useState(null);

  // useEffect(() => {
  //   const tester = async () => {
  //     try {
  //       const user = await Auth.signIn({
  //         username: 'dave@wearepropeller.com',
  //         password: 'Ou8W7m8a',
  //       });
  //       const loggedUser = await Auth.completeNewPassword(
  //         user, // the Cognito User Object
  //         'Password1!' // the new password
  //       );

  //       console.log('user!', user);
  //     } catch (e) {
  //       console.log('e', e);
  //     }
  //   };
  //   tester();
  // }, []);

  const { from } = location.state || {
    from: {
      pathname: '/',
    },
  };

  const onFinish = async (values) => {
    values.email = values.email.toLowerCase().trim();

    setLoading(true);
    try {
      await Auth.signOut();
      localStorage.removeItem('aotv:userToken');
      localStorage.removeItem('aotv:isTeamMember');
      localStorage.removeItem('aotv:isAdmin');

      const userItem = await Auth.signIn({
        username: values.email,
        password: values.password,
      });

      Bugsnag.setUser(userItem.username, values.email);
      Bugsnag.leaveBreadcrumb('Logged in');
      console.log('userItem', userItem);

      if (userItem.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // const loggedUser = await Auth.completeNewPassword(
        //   user, // the Cognito User Object
        //   'Password1!' // the new password
        // );

        setUser(userItem);
        setShowSetPassword(true);
      } else {
        localStorage.setItem(
          'aotv:userToken',
          userItem.signInUserSession.accessToken.jwtToken
        );

        if (
          userItem.signInUserSession.accessToken.payload[
            'cognito:groups'
          ].includes('team')
        ) {
          localStorage.setItem('aotv:isTeamMember', 'true');
        }

        if (
          userItem.signInUserSession.accessToken.payload[
            'cognito:groups'
          ].includes('admins')
        ) {
          localStorage.setItem('aotv:isAdmin', 'true');
        }

        setLoading(false);
        history.push(from);
      }
    } catch (e) {
      setLoading(false);

      if (
        ['NotAuthorizedException', 'UserNotFoundException'].includes(e.code)
      ) {
        message.error('Incorrect username or password.');
      } else {
        message.error(e.message);
        Bugsnag.notify(e);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (showSetPassword) {
    return <SetTeamPasswordScene user={user} />;
  }
  return (
    <div className='container'>
      <PageHead title={'Login'} />
      <Row justify='center'>
        <Col
          xs={{ span: 22 }}
          sm={{ span: 20 }}
          md={{ span: 14 }}
          lg={{ span: 12 }}
          xl={{ span: 10 }}
          xxl={{ span: 8 }}
        >
          <div style={{ textAlign: 'center', padding: 16, paddingBottom: 32 }}>
            <img
              src='https://d2y18d7bbdaphy.cloudfront.net/img/aotv-logo.png'
              alt='AOTV Logo'
              width='200px'
            />
          </div>
          <Row>
            <Col span={24}>
              <Card>
                <div style={{ marginBottom: 30 }}>
                  <Title level={3} style={{ marginBottom: 0 }}>
                    Log in
                  </Title>
                  <Text type='secondary'>
                    Don't yet have an account with AOTV?{' '}
                    <Link to='/auth/register'>Create one here</Link>
                  </Text>
                </div>
                <Form
                  {...layout}
                  name='basic'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label='Email / Username'
                    name='email'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email / username!',
                        transform: (value) => value.toLowerCase().trim(),
                      }
                    ]}
                  >
                    <Input disabled={loading} />
                  </Form.Item>

                  <Form.Item
                    label='Password'
                    name='password'
                    autoCapitalize='off'
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password disabled={loading} />
                  </Form.Item>

                  <Form.Item {...tailLayout}>
                    <Space size='middle' wrap>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                      >
                        Login
                      </Button>

                      {/* <Button
                        type='secondary'
                        htmlType='button'
                        onClick={() => {
                          history.push('/register');
                        }}
                      >
                        Register
                      </Button> */}
                      <Link to='/auth/forgot'>Forgot your password?</Link>
                    </Space>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default LoginScene;
